import React, { useState, useEffect, useContext, useRef } from "react";
// import EditIcon from '@material-ui/icons/Edit';
// "@mui/material/LaunchIcon";
import { useNavigate, useParams } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import IconButton from "@mui/icons-material/Icon";
// import CloseIcon from "@material-ui/icons/Close";
// import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { ScrollPanel } from "primereact/scrollpanel";
import { OverlayPanel } from "primereact/overlaypanel";
// import SearchIcon from "@material-ui/icons/Search";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Toolbar } from "@mui/material";
import { Container, Stack } from "@mui/system";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CustomerContext from "../../context/customer/CustomerContext";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import _ from "lodash";
import AuthContext from "../../context/auth/AuthContext";
import axios from "axios";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

function ClientLookup(props) {
  const op = useRef(null);
  const customerContext = useContext(CustomerContext);
  const { customers, selectedCustomer, setCurrentCustomer, getCustomers } = customerContext;
  //const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let { clientId } = useParams();
  const [_clientId, setClientId] = useState(clientId);
  const [client, setClient] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerListRef, setCustomerListRef] = useState([]);
  const [selectCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerObj, setSelectCustomerObj] = useState(false);
  const [changedCustomer, setChangedCustomer] = useState(null);
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const authContext = useContext(AuthContext);
  const { roleType, authUser } = authContext;
  const theme = useTheme();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [remainingCategories, setRemainingCategories] = useState([]);
  const [remainingPrograms, setRemainingPrograms] = useState([]);
  const [isStatePopupOpen, setStatePopupOpen] = useState(false);
  const [remainingStateCategories, setRemainingStateCategories] = useState([]);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  function handleNavigatebefore() {
    navigate("/");
  }

  const customerSelected = (val) => {
    setSelectCustomerObj(val);
    setSelectedCustomer(val.customerName);
  };
  function handleEditClick() {
    setOpen(true);
  }

  function handleClose() {
    setSelectCustomerObj("");
    setOpen(false);
  }
  function handleCancelClose() {
    setSelectCustomerObj("");
    setOpen(false);
  }

  function handleBannerClick() {
    setIsBannerVisible(!isBannerVisible);
  }

  function handleCloseClick1() {
    setIsBannerVisible(false);
  }

  // useEffect(() => {
  // fetch("")
  // .then((custListponse) => custListponse.json())
  // .then((data) => setItems(data));
  // }, []);
  useEffect(() => {
    getCustomerList();
    if (selectedCustomer) {
      setClient((cust) => selectedCustomer);
      props.setSelectedClientId(() => selectedCustomer.customerId);
    }
    if (clientId){
      let isSelectedCustomer = localStorage.getItem("selectedCustomer") !== null
        ? JSON.parse(localStorage.getItem("selectedCustomer"))
        : null;
      if(isSelectedCustomer){
        setCurrentCustomer(isSelectedCustomer)
        setClient(isSelectedCustomer);
      }
    }
    return () => {
      // setClient((cust) => null);
      // setCurrentCustomer([]);
    };
  }, []);



  const getCustomerList = async () => {
    try {
      // let custList = await axios.get(`${API_BASE_URL}/Customer/CustomerList`);
      let custList;
      if (customers) {
        custList = customers
      } else {
        custList = await getCustomers();
      }
      let _data;
      if (
        !(roleType.includes("Admin") || roleType.includes("Admin-PostAward"))
      ) {
        _data = custList.filter((ele) =>
          authUser.clients.includes(ele.customerId)
        );
      } else {
        _data = custList;
      }
      if (props.parentRoute === "add-filings") {
        _data = _data.filter(
          (ele) => (ele.trackNowLicenseId && Number(ele.trackNowLicenseId) > 0)
        );
        setCustomerList(_data);
        setCustomerListRef(_data);
      } else if (props.parentRoute === "add-filing-postaward") {

        _data = _data.filter(
          (ele) => (ele.postAwardLicenseId && Number(ele.postAwardLicenseId) > 0)
        );
        setCustomerList(_data);
        setCustomerListRef(_data);
      } else {
        setCustomerList(_data);
        setCustomerListRef(_data);
      }


    } catch (error) {
      console.log(error);
    }
  };

  function handleSearchChange(event) {
    // setSearchTerm(event.target.value);
    setSearchTerm(event.target.value);
    let customerArr = customerListRef;
    if (event.target.value != "") {
      let searchedArr = customerArr.filter((customer) => {
        return customer.customerName
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setCustomerList(searchedArr);
    } else if (event.target.value == "") {
      getCustomerList();
    }
  }

  function handleSearchClick() {
    // const filtered = items.filter(
    // (item) => item.name.includes(searchTerm) || item.id === searchTerm
    // );
    // setFilteredItems(filtered);
  }

  function getCustomer(customerId) {
    if (customers) {
      const _custRecs = customers.filter(
        (cust) => cust.customerId == customerId
      );
      return _custRecs[0];
    } else return null;
  }

  const handleChange = (event) => {


    setChangedCustomer(selectCustomer);
    // const {
    // target: { value },
    // } = event;
    // const _client = getCustomer(selectedCustomerObj.customerId);
    // console.log("$$VAL_clientId:", selectedCustomerObj.customerId);
    props.setSelectedClientId(() => selectedCustomerObj.customerId);
    setCurrentCustomer(selectedCustomerObj);
    setClient((cust) => selectedCustomerObj);

    setOpen(false);
    navigate(`/${props.parentRoute}/${selectedCustomerObj.customerId}`);
  };

  function MouseOver(event) {
    event.target.style.background = "#BDBDBD";
  }
  function MouseOut(event) {
    event.target.style.background = "";
  }

  const isItemSelected = (value) => {

    return selectedCustomerObj?.customerId === value;
  };
  const getBusinessCategories = (categories) => {
    const _federalCat = categories.filter((item) => !item.state);
    const displayedCategories = _federalCat.map(
      (item) => item.businessCategoryName
    );
    const remainingCategories = _federalCat
      .slice(2)
      .map((item) => item.businessCategoryName);


    return { displayedCategories, remainingCategories };
  };

  const getProgramName = (programOptions) => {
    const _programList = programOptions.filter((item) => !item?.state);
    const displayPrograms = _programList.map((item) => item.programName);

    const remainingPrograms = _programList
      .slice(2)
      .map((item) => item.programName);


    return { displayPrograms, remainingPrograms };

  };
  const getStateBusinessCategories = (categories) => {
    const _stateCat = categories.filter((item) => item.state !== null);
    // const updStateCategories = _stateCat.map(
    //   (item) => `${item.state} - ${item.businessCategoryName}`
    // );

    //return updStateCategories;
    const displayedStateCategories = _stateCat
      //.slice(0, 2)
      .map((item) => `${item.state} - ${item.businessCategoryName}`);

    const remainingStateCategories = _stateCat
      .slice(2)
      .map((item) => `${item.state} - ${item.businessCategoryName}`);

    return { displayedStateCategories, remainingStateCategories };
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
    setRemainingCategories([]);
  };
  const handleStatePopupClose = () => {
    setStatePopupOpen(false);
    setRemainingStateCategories([]);
  };
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F1F2F6",
      color: "black",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));
  const overlayRef = useRef(null);
  const overlayProgramNameRef = useRef(null);
  const overlayStateRef = useRef(null);
  const panelStyle = {
    marginTop: "50px", // Adjust this value to move the OverlayPanel down as needed
  };
  return (
    <Container style={{ margin: "0 1rem", padding: "0 " }}>
      <Grid
        style={{
          width: "100%",
        }}
      >
        <Toolbar
          style={{
            maxWidth: "100%",
            padding: "0",
            marginTop: "1rem",
            backgroundColor: "#FAFAFA",
            borderRadius: "2%",
            zIndex: 11,
          }}
        >
          <div
            style={{
              display: isBannerVisible ? "none" : "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "1rem",
            }}
          >
            <div style={{ display: "flex" }}>
              <NavigateBeforeIcon
                style={{ color: "#757575", cursor: "pointer" }}
                onClick={handleNavigatebefore}
              />
              <div style={{ mr: "2rem" }}>
                {client && client.customerName
                  ? client.customerName
                  : "Please Select a Client"}
              </div>

              <LaunchIcon
                onClick={handleEditClick}
                style={{
                  fontSize: "1.3em",
                  cursor: "pointer",
                  color: "#757575",
                }}
              />
            </div>
            {isBannerVisible ? null : (
              <Button
                style={{ display: "flex", color: "#0b3edf" }}
                onClick={handleBannerClick}
                disabled={!selectedCustomerObj && !client?.customerName}
              >
                {/* <div> */}
                {/* <Typography style={{ color: "blue", cursor: "pointer" }}> */}
                View Details
                {/* </Typography> */}
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer" }}
                  disabled={!selectedCustomerObj}
                />
                {/* </div> */}
              </Button>
            )}
          </div>

          {isBannerVisible && (
            <Paper
              style={{
                backgroundColor: "#FAFAFA",
                width: "100%",
                paddingTop: "1rem",
              }}
            >
              <Toolbar style={{ minHeight: "40px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <NavigateBeforeIcon
                      style={{ color: "#757575", cursor: "pointer" }}
                    />

                    {selectCustomer != "" ? (
                      <div style={{ marginLeft: "10px" }}>
                        {changedCustomer}
                      </div>
                    ) : (
                      ""
                    )}
                    <LaunchIcon
                      onClick={handleEditClick}
                      style={{
                        fontSize: "1.3em",
                        color: "#757575",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div>
                    <CloseIcon
                      onClick={handleCloseClick1}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </Toolbar>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer style={{ paddingLeft: "2vw" }}>
                    <TableHead>
                      <TableCell
                        style={{
                          color: "#9E9E9E",
                          height: "5vw",
                          width: "5vw",
                        }}
                      >
                        ID
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#9E9E9E",
                          height: "5vw",
                          width: "10vw",
                        }}
                      >
                        Client Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#9E9E9E",
                          height: "5vw",
                          width: "10vw",
                        }}
                      >
                        Federal Business Category
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#9E9E9E",
                          height: "5vw",
                          width: "10vw",
                        }}
                      >
                        Program Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#9E9E9E",
                          height: "5vw",
                          width: "20vw",
                        }}
                      >
                        State Business Category
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#9E9E9E",
                          height: "5vw",
                          width: "10vw",
                        }}
                      >
                        AddcustLists
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#9E9E9E",
                          height: "5vw",
                          width: "10vw",
                        }}
                      >
                        Tax Number
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#9E9E9E",
                          height: "5vw",
                          width: "10vw",
                        }}
                      >
                        Phone/Mail
                      </TableCell>

                      <TableCell
                        style={{
                          color: "#9E9E9E",
                          height: "5vw",
                          width: "15vw",
                        }}
                      >
                        Notes
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      <TableCell
                        style={{
                          height: "5vw",
                          width: "5vw",
                          verticalAlign: "top",
                        }}
                      >
                        {client?.customerId}
                      </TableCell>
                      <TableCell
                        style={{
                          height: "5vw",
                          width: "10vw",
                          verticalAlign: "top",
                        }}
                      >
                        {client?.customerName &&
                          client?.customerName.length > 25 ? (
                          <Tooltip title={client?.customerName}>
                            <span>{client?.customerName.substr(0, 25)}...</span>
                          </Tooltip>
                        ) : (
                          <span>{client?.customerName}</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          maxH: "5vw",
                          width: "20vw",
                          verticalAlign: "top",
                        }}
                      >
                        {getBusinessCategories(client?.businessCategory)
                          .displayedCategories.slice(0, 2)
                          .map((category, index) => (
                            <React.Fragment key={index}>
                              {category.length > 25 ? (
                                <Tooltip title={category} key={index}>
                                  <span>{category.substr(0, 25)}...</span>
                                </Tooltip>
                              ) : (
                                <span>
                                  {category}
                                  {index !==
                                    getBusinessCategories(
                                      client?.businessCategory
                                    ).displayedCategories.length -
                                    1 && ","}
                                </span>
                              )}
                              <br />
                            </React.Fragment>
                          ))}
                        {getBusinessCategories(client?.businessCategory)
                          .remainingCategories.length > 0 && (
                            <span
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={(event) => {
                                setPopupOpen(true);
                                setRemainingCategories(
                                  getBusinessCategories(client?.businessCategory)
                                    .remainingCategories
                                );
                                overlayRef.current.show(event); // Show the OverlayPanel when the span is clicked
                              }}
                            >
                              {`+${getBusinessCategories(client?.businessCategory)
                                .remainingCategories.length
                                } more`}
                            </span>
                          )}
                        {/* The OverlayPanel */}
                        <OverlayPanel
                          ref={overlayRef}
                          appendTo={document.body}
                          onHide={() => setPopupOpen(false)}
                          showCloseIcon={true}
                          dismissable={false}
                        >
                          <Typography>Federal Business Categories</Typography>
                          <ScrollPanel
                            style={{
                              maxWidth: "25rem",
                              maxHeight: "20rem",
                            }}
                          >
                            <ul>
                              {getBusinessCategories(
                                client?.businessCategory
                              ).displayedCategories.map((category, index) => (
                                <li key={index}>
                                  {category.length > 50 ? (
                                    <Tooltip title={category}>
                                      <span>{`${category.substr(
                                        0,
                                        50
                                      )}...`}</span>
                                    </Tooltip>
                                  ) : (
                                    <span>{category}</span>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </ScrollPanel>
                        </OverlayPanel>
                      </TableCell>


                      <TableCell
                        style={{
                          maxH: "5vw",
                          width: "20vw",
                          verticalAlign: "top",
                        }}
                      >
                        {getProgramName(client?.programinfo)
                          .displayPrograms.slice(0, 2)
                          .map((program, index) => (
                            <React.Fragment key={index}>
                              {program.length > 25 ? (
                                <Tooltip title={program} key={index}>
                                  <span>{program.substr(0, 25)}...</span>
                                </Tooltip>
                              ) : (
                                <span>
                                  {program}
                                  {index !==
                                    getProgramName(
                                      client?.programinfo
                                    ).displayPrograms.length -
                                    1 && ","}
                                </span>
                              )}
                              <br />
                            </React.Fragment>
                          ))}
                        {getProgramName(client?.programinfo)
                          .remainingPrograms.length > 0 && (
                            <span
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={(event) => {
                                setPopupOpen(true);
                                setRemainingPrograms(
                                  getProgramName(client?.programinfo)
                                    .remainingPrograms
                                );
                                overlayProgramNameRef.current.show(event); // Show the OverlayPanel when the span is clicked
                              }}
                            >
                              {`+${getProgramName(client?.programinfo)
                                .remainingPrograms.length
                                } more`}
                            </span>
                          )}
                        {/* The OverlayPanel */}
                        <OverlayPanel
                          ref={overlayProgramNameRef}
                          appendTo={document.body}
                          onHide={() => setPopupOpen(false)}
                          showCloseIcon={true}
                          dismissable={false}
                        >
                          <Typography>Program Names</Typography>
                          <ScrollPanel
                            style={{
                              maxWidth: "25rem",
                              maxHeight: "20rem",
                            }}
                          >
                            <ul>
                              {getProgramName(
                                client?.programinfo
                              ).displayPrograms.map((program, index) => (
                                <li key={index}>
                                  {program.length > 50 ? (
                                    <Tooltip title={program}>
                                      <span>{`${program.substr(
                                        0,
                                        50
                                      )}...`}</span>
                                    </Tooltip>
                                  ) : (
                                    <span>{program}</span>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </ScrollPanel>
                        </OverlayPanel>
                      </TableCell>

                      <TableCell
                        style={{
                          maxH: "5vw",
                          width: "20vw",
                          verticalAlign: "top",
                        }}
                      >
                        {getStateBusinessCategories(client?.businessCategory)
                          .displayedStateCategories.slice(0, 2)
                          .map((category, index) => (
                            <React.Fragment key={index}>
                              {category.length > 25 ? (
                                <Tooltip title={category} key={index}>
                                  <span>{category.substr(0, 25)}...</span>
                                </Tooltip>
                              ) : (
                                <span>
                                  {category}
                                  {index !==
                                    getStateBusinessCategories(
                                      client?.businessCategory
                                    ).displayedStateCategories.length -
                                    1 && ","}
                                </span>
                              )}
                              <br />
                            </React.Fragment>
                          ))}
                        {getStateBusinessCategories(client?.businessCategory)
                          .remainingStateCategories.length > 0 && (
                            <span
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={(event) => {
                                setStatePopupOpen(true);
                                setRemainingStateCategories(
                                  getStateBusinessCategories(
                                    client?.businessCategory
                                  ).remainingStateCategories
                                );
                                overlayStateRef.current.show(event); // Show the OverlayPanel when the span is clicked
                              }}
                            >
                              {`+${getStateBusinessCategories(
                                client?.businessCategory
                              ).remainingStateCategories.length
                                } more`}
                            </span>
                          )}
                        {/* The OverlayPanel */}
                        <OverlayPanel
                          ref={overlayStateRef}
                          appendTo={document.body}
                          onHide={() => setStatePopupOpen(false)}
                          showCloseIcon={true}
                          dismissable={false}
                          panelStyle={panelStyle}
                        >
                          <Typography>State Business Categories</Typography>
                          <ScrollPanel
                            style={{
                              maxWidth: "25rem",
                              maxHeight: "20rem",
                            }}
                          >
                            <ul>
                              {getStateBusinessCategories(
                                client?.businessCategory
                              ).displayedStateCategories.map(
                                (category, index) => (
                                  <li key={index}>
                                    {category.length > 40 ? (
                                      <Tooltip title={category}>
                                        <span>{`${category.substr(
                                          0,
                                          40
                                        )}...`}</span>
                                      </Tooltip>
                                    ) : (
                                      <span>{category}</span>
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          </ScrollPanel>
                        </OverlayPanel>
                      </TableCell>

                      <TableCell
                        style={{
                          height: "5vw",
                          width: "10vw",
                          verticalAlign: "top",
                        }}
                      >
                        {client?.addcustLists}
                      </TableCell>
                      <TableCell
                        style={{
                          height: "5vw",
                          width: "10vw",
                          verticalAlign: "top",
                        }}
                      >
                        {client?.taxNumber}
                      </TableCell>
                      <TableCell
                        style={{
                          height: "5vw",
                          width: "10vw",
                          verticalAlign: "top",
                        }}
                      >
                        {client?.phone}
                      </TableCell>

                      <TableCell
                        style={{
                          height: "5vw",
                          width: "15vw",
                          verticalAlign: "top",
                        }}
                      >
                        {client?.notes && client?.notes.length > 50 ? (
                          <Tooltip title={client?.notes}>
                            <span>{client?.notes.substr(0, 50)}...</span>
                          </Tooltip>
                        ) : (
                          <span>{client?.notes}</span>
                        )}
                      </TableCell>
                    </TableBody>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Toolbar>
      </Grid>

      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="custListponsive-dialog-title"
        sx={{ backdropFilter: "blur(0.5px)" }}
      >
        <Box
          style={{
            backgroundColor: "rgb(224 224 224 / 49%)",
          }}
        >
          <Stack
            display="flex"
            direction="row"
            //  spacing={20}
            justifyContent="space-between"
            alignItems="center"
            sx={{ backgroundColor: "rgb(224 224 224 / 49%" }}
          >
            <DialogTitle
              sx={{
                fontWeight: "normal",
                fontSize: "21px",
                color: "#424242",
              }}
            >
              {" "}
              CHANGE CLIENT
            </DialogTitle>
            <DialogTitle
              sx={{
                fontWeight: "normal",
                fontSize: "21px",
                color: "#424242",
              }}
            >
              {" "}
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </DialogTitle>
          </Stack>
        </Box>
        <DialogContent>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
              height: "10%",
              marginTop: "10px",
            }}
          >
            <TextField
              style={{
                width: "45ch",
                height: "5ch",
                backgroundColor: "rgb(224 224 224/ 49%)",
                borderRadius: "10px",
                padding: "6px 10px 0px",
                textAlign: "center",
                marginTop: "5px",
              }}
              variant="standard"
              placeholder="search clients"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </DialogContent>

        <TableContainer
          sx={{
            maxHeight: 240,

            overflow: "auto",
            width: "93%",
          }}
        >
          <Container>
            <Table>
              <TableBody>
                {customerList && customerList.map((customer, index) => (
                  <TableRow
                    key={customer.customerId}
                    hover
                    role="checkbox"
                    selected={
                      selectedCustomerObj?.customerId === customer.customerId
                    }
                    sx={{ cursor: "pointer" }}
                    onClick={(event) => customerSelected(customer)}
                    tabIndex={-1}
                  >
                    <TableCell
                      onClick={() => customerSelected(customer)}
                      style={{
                        borderBottom: "none",
                        textAlign: "left",
                        fontSize: "12px",
                        color: "#424242",
                        fontWeight: "normal",
                        paddingLeft: "16px",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        opacity: 1,
                      }}
                    >
                      {customer.customerName.length > 45 ? (
                        <Tooltip title={customer.customerName} arrow>
                          <span>
                            {customer.customerName.substring(0, 45)}...
                          </span>
                        </Tooltip>
                      ) : (
                        customer.customerName
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        padding: "11px",
                        textAlign: "right",
                      }}
                    >
                      {customer.customerId}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Container>
        </TableContainer>

        <Box
          style={{
            float: "right",
            marginBottom: "10px",
            marginRight: "29px",
          }}
        >
          <DialogActions>
            <Button
              sx={{ mt: 3, ml: 1 }}
              variant="standard"
              style={{
                justifyContent: "right",
                marginRight: "30px",
                color: "Blue",
                backgroundColor: "white",
                marginTop: "18px",
                fontSize: "13px",
                paddingRight: "13px",
                height: "35px",
                width: "77px",
              }}
              //  onClick={() => setOpen(false)}
              onClick={handleCancelClose}
              color="default"
            >
              Cancel
            </Button>
            <Button
              sx={{ mt: 3, ml: 1 }}
              variant="standard"
              style={{
                justifyContent: "right",
                color: "White",
                backgroundColor: "blue",
                marginTop: "18px",
                fontSize: "12px",
                paddingRight: "13px",
                height: "35px",
                width: "77px",
              }}
              color="primary"
              onClick={handleChange}
            >
              Change
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Container>
  );
}

export default ClientLookup;
