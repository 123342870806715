import React, {
    Fragment,
    useEffect,
    useState,
    useContext,
    useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ShortTextIcon from "@mui/icons-material/ShortText";
// import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Anchor } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AuthContext from "../../../context/auth/AuthContext";
import FilingMasterContext from "../../../context/filing-master/FilingMasterContext";
// import { format } from "date-fns";
import Alert from "@mui/material/Alert";
//import IconButton from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip, IconButton } from "@mui/material";
import { DateTime } from "luxon";
import Collapse from "@mui/material/Collapse";
import Input from "@mui/material/Input";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownload from "js-file-download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useDialog from "../../common/UseDialog";

import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
// import { FilingMaster, FilingMasterComment } from "../../types/FilingMaster";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
type Anchor = "top" | "left" | "bottom" | "right";

export default function DeferedComments(props: {
    dockAt?: "top" | "left" | "bottom" | "right";
    show?: boolean;
    handleClose: () => void;
    draft?: any;
    comments?: any[];
}) {
    const filingMasterContext = useContext(FilingMasterContext);
    const { getFilingMasterComments, addFilingMasterComment } =
        filingMasterContext;
    const formRef = useRef<HTMLFormElement>(null);
    const [data, setData] = React.useState<any[]>([]);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const authContext = useContext(AuthContext);
    const { authUser, userRoles, toLocalDateTime, roleType } = authContext;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const closeAlert = () => {
        props.handleClose();
        //  navigate("/filing-master-list");
    };

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                // console.log(`Drawer Mouse Key Event...`);
                // if (
                //   event.type === "keydown" &&
                //   ((event as React.KeyboardEvent).key === "Tab" ||
                //     (event as React.KeyboardEvent).key === "Shift")
                // ) {
                //   return;
                // }
                // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
                // setState({ ...state, [anchor]: open });
                // if (!open) props.handleClose();
            };

    useEffect(() => {
        if (
            typeof props.dockAt !== "undefined" &&
            typeof props.show !== "undefined"
        ) {
            // console.log(
            //   `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
            // );
            // toggleDrawer(props.dockAt, props.show);
            if (props.show) {
                setState({ ...state, [props.dockAt]: props.show });
            }
        }
        //eslint-disable-next-line
    }, [props.show]);

    useEffect(() => {
        const _data: any[] = props.comments!;
        if (props.comments) {
            setData(() => _data);
        }
        setLoading(false)
        //eslint-disable-next-line
    }, [props.comments]);

    useEffect(() => {
        // if(props.draft && props.draft.createDate!==null)
        //eslint-disable-next-line
    }, [props.draft]);


    useEffect(() => {
        // console.log(`Fetching Commnet for 0:${props.draft}`);
        if (
            typeof props.dockAt !== "undefined" &&
            typeof props.show !== "undefined"
        ) {

            setState({ ...state, [props.dockAt]: props.show });
        }
        //eslint-disable-next-line
    }, []);

    
    const getUserName = (userId: string) => {
        // console.log("USER ROLES LKP:", userRoles);
        // console.log("USER ROLES LKP2 :", userId);

        const user = userRoles
            ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]?.userName
            : "";
        return user;
    };

    const list = (anchor: Anchor) => (
        <Fragment>
            <Grid container>
                <Stack
                    sx={{
                        width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
                        minWidth: "35vw",
                        display: "flex",
                        alignContent: "center",
                    }}
                    role="presentation"
                    onClick={toggleDrawer(anchor, false)}
                    onKeyDown={toggleDrawer(anchor, false)}
                >
                    <Card sx={{ border: "none", boxShadow: "none" }}>
                        <CardContent sx={{ mx: "10px", my: "10px" }}>
                            <Stack direction="column" spacing={2}>
                                <div>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            sx={{
                                                fontWeight: "medium",
                                                fontSize: "16px",
                                                color: "#424242",
                                            }}
                                        >
                                            COMMENTS
                                        </Typography>
                                        <IconButton>
                                            <CloseIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleCancel(anchor)}
                                            />
                                        </IconButton>
                                    </Stack>
                                </div>

                            </Stack>

                            <Divider />

                            <List>
                                {loading ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            minHeight: "100px",
                                        }}
                                    >
                                        <CircularProgress size={40} />
                                    </div>
                                ) : data && data.length === 0 ? (
                                    <Typography variant="body1" color="textSecondary">
                                        No comments
                                    </Typography>
                                ) : (
                                    data &&
                                    data
                                        .slice()
                                        //.reverse()
                                        .map((item, index: number) => (
                                            <Fragment key={index}>
                                                <Stack direction="row" justifyContent="space-between">
                                                    <ListItem
                                                        disablePadding
                                                        style={{ fontWeight: "bold", fontSize: "16px" }}
                                                    >
                                                        {item.updateUser
                                                            ? getUserName(item.updateUser)
                                                            : item.createUser && getUserName(item.createUser)}
                                                    </ListItem>
                                                    <ListItem sx={{ textAlign: "right" }}>
                                                        <ListItemText
                                                            secondary={
                                                                item &&
                                                                    item.updateDate
                                                                    ? toLocalDateTime && `${toLocalDateTime(item.updateDate)}`
                                                                    : toLocalDateTime && `${toLocalDateTime(item.createDate)}`
                                                            }
                                                        />
                                                    </ListItem>
                                                </Stack>

                                                <ListItem
                                                    disablePadding
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "start",
                                                    }}
                                                >
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                        <ListItemText
                                                            sx={{
                                                                '&:hover': {
                                                                    backgroundColor: '#f0f0f0',
                                                                    cursor: "text"
                                                                },
                                                                width: "100%",
                                                            }}
                                                            primary={
                                                                item &&
                                                                    item.commentsText &&
                                                                    item.commentsText !== null
                                                                    ? item.commentsText
                                                                    : ""
                                                            }
                                                        />

                                                    </div>
                                                </ListItem>
                                            </Fragment>
                                        ))
                                )}
                            </List>
                        </CardContent>
                    </Card>
                </Stack>
            </Grid>
            <style>
                {`.css-1x51dt5-MuiInputBase-input-MuiInput-input{
          cursor : pointer;
          {
  
        `}
            </style>
        </Fragment >
    );

    const handleCancel = async (anchor: Anchor) => {
        setState({ ...state, [anchor]: false });
        props.handleClose();
    };

    return (
        <div>
            {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
          // <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
            {typeof props.dockAt !== "undefined" && (
                <React.Fragment>
                    <Drawer
                        anchor={props.dockAt}
                        open={state[props.dockAt]}
                        onClose={toggleDrawer(props.dockAt, false)}
                        sx={{ zIndex: "11" }}
                    >
                        {list(props.dockAt)}
                    </Drawer>
                </React.Fragment>
            )}
        </div>
    );
}
