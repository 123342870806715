import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import ClientLookup from "../../customer/ClientLookup";
import AddFilingTable from "./AddFilingtable";
import ReactLoading from "react-loading";
import DeferredToolbox from "./DeferredToolbox";
import DeferredHistory from "./DeferredHistory";
import { Dialog } from "primereact/dialog";
import { Button as PrimeButton } from "primereact/button";
import axios from "axios";
import DeferedComments from "./DeferedComments";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function AddFilingList(props) {
  const navigate = useNavigate();
  const [customerId, setCustomerId] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [selectedMasters, setSelectedMasters] = useState(null);
  const [selectedPeriods, setSelectedPeriods] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [activityHistory, setActivityHistory] = React.useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [comments, setComments] = useState();
  const [showComments, setShowComments] = useState(false);

  let { clientId } = useParams();

  const getCustomerId = (id) => {
    setCustomerId(id);
    setSelectedMasters(null);
    setSelectedPeriods([]);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };
  const closeComments = () => {
    // console.log("Toggling Show Filter", showComments);
    setShowComments(() => false);
  };

  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const fetchClientTrackingHistory = async (filingId) => {
    try {
      const history = await axios.get(
        `${API_BASE_URL}/Customer/DeferredFilingAudit?CustomerId=${customerId}&FilingId=${filingId}`
      );
      setActivityHistory(() => history.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };
  const filterWorkflowAndFileTrackingId = () => {
    console.log("filterWorkflowAndFileTrackingId", selectedMasters);

    let fileTrackingIds = [];
    let workflowIds = [];

    selectedMasters.filingPeriods.forEach((period) => {
      if (Array.isArray(period.fileTrackingId)) {
        // Extract valid fileTrackingIds
        if (period.fileTrackingId.length > 0) {
          fileTrackingIds.push(period.fileTrackingId[0].fileTrackingId);
        }
      }

      if (period.workflowId) {
        // Extract valid workflowIds
        workflowIds.push(period.workflowId.workflowId);
      }
    });

    console.log(
      "filterWorkflowAndFileTrackingId fileTrackingIds",
      fileTrackingIds,
      "workflowIds",
      workflowIds
    );

    fileTrackingIds = [...new Set(fileTrackingIds)];
    workflowIds = [...new Set(workflowIds)];

    return { fileTrackingIds, workflowIds };
  };

  const fetchFilingMasterComments = async () => {
    let { fileTrackingIds, workflowIds } =
      await filterWorkflowAndFileTrackingId();

    try {

      let resByFileTrackingId;
      let resByWorkflowId;

      let resByFileTrackingId_Of_CommentsAttachments

      if (workflowIds.length > 0) {
        //If it has only workflowId
        resByWorkflowId = await axios.get(
          `${API_BASE_URL}/Customer/CustomerFilingWorkflowComments${workflowIds[0]}`
        );

        // resList = resByWorkflowId.data
      }

      if (fileTrackingIds.length > 0) {
        //If it has only fileTrackingId
        const queryString = fileTrackingIds
          .map((id) => `fileTrackingIds=${id}`)
          .join("&");
        resByFileTrackingId = await axios.get(
          `${API_BASE_URL}/Customer/ViewChildCustomerFilingTrackingComments?${queryString}`
        );
        resByFileTrackingId_Of_CommentsAttachments = await axios.get(
          `${API_BASE_URL}/Customer/ViewParentandchildCustomerCommentsandAttachments?customerIds=${selectedMasters.customerId}&${queryString}`
        );

        // resList = resByFileTrackingId.data
      }

      const combinedResponse = [

        ...(resByFileTrackingId?.data?.length > 0 ? resByFileTrackingId.data : []),
        ...(resByFileTrackingId_Of_CommentsAttachments?.data?.data?.length > 0 ? resByFileTrackingId_Of_CommentsAttachments.data.data : []),
        ...(resByWorkflowId?.data?.length > 0 ? resByWorkflowId.data : [])
      ];   
      // Remove duplicates based on commentsId
      const uniqueResponse = combinedResponse.filter((item, index, self) =>
        index === self.findIndex((t) => t.commentsId === item.commentsId)
      );

      // Sort by date
      uniqueResponse.sort((a, b) => {
        const dateA = new Date(a.updateDate || a.createDate);
        const dateB = new Date(b.updateDate || b.createDate);
        return dateB.getTime() - dateA.getTime(); // Descending order
      });

      // Set comments state
      setComments(() => uniqueResponse);

      // console.log("@@Fetch FilingMasters2 comments:", commentList);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const addComment = () => {
    if (selectedMasters) {
      fetchFilingMasterComments();
      setShowComments(() => true);
    } else {
      setAlertMessage("Please Select a Filing Tracker Record");
      setShowAlert(true);
    }
  };

  const displayClientTracker = () => {
    if (clientId) {
      navigate(`/client-filing-tracker-list/${clientId}`);
    } else {
      navigate("/client-filing-tracker-list");
    }
  };

  const displayHistory = async () => {
    console.log("displayHistory", selectedMasters);
    // if (selectedMasters && selectedMasters.length > 1) {
    //   setAlertMessage("Please Select single Filing Tracker Record");
    //   setShowAlert(true);
    // } else
    if (selectedMasters?.filingId) {
      fetchClientTrackingHistory(selectedMasters.filingId);
      setShowHistory(() => true);
    } else {
      setAlertMessage("Please Select a Filing Tracker Record");
      setShowAlert(true);
    }
  };

  const closeHistory = () => {
    setShowHistory(() => false);
  };

  React.useEffect(() => {
    console.log("props Test", props);
    setIsLoading(false);
  }, [props]);
  return (
    <Fragment>
      {isLoading ? (
        <ReactLoading
          style={{
            position: "absolute",
            width: "3rem",
            marginLeft: "36rem",
            marginTop: "10rem",
            color: "#0000FF",
          }}
          type="spin"
          color="blue"
          height={80}
        />
      ) : (
        <Fragment>
          <Grid style={{ height: "100%", width: "93%" }}>
            <Grid sm={24}>
              {/* <div className='card' style={{ height: "10vh", maxWidth: "100vw" }}> */}
              {!loadingFlag && (
                <ClientLookup
                  setSelectedClientId={getCustomerId}
                  parentRoute="add-filings"
                />
              )}
              {/* </div> */}
            </Grid>
            <Grid sm={24}>
              <div
                className="card"
                style={{
                  width: "89vw",
                  padding: "1rem",
                  margin: "0 1rem",
                  background: "#FFF",
                  // borderRadius: "2%",
                }}
              >
                <AddFilingTable
                  selectedClientId={customerId}
                  setLoadingFlag={setLoadingFlag}
                  changeSelectedMasters={(val) => setSelectedMasters(val)}
                  changeSelectedPeriods={(val) => setSelectedPeriods(val)}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            sx={{
              height: "100%",
              width: "5%",
            }}
          >
            <div
              style={{
                height: "100%",
                background: "#F1F2F6",
                marginTop: "1rem",
                borderRadius: "2%",
              }}
            >
              <Item
                sx={{
                  height: "100%",
                }}
              >
                <DeferredToolbox
                  isLoading={isLoading}
                  // draft={selectedmasters?.customer[0]?.customerId}
                  displayHistory={displayHistory}
                  addComment={addComment}
                  displayClientTracker={displayClientTracker}
                />
              </Item>
            </div>
          </Grid>

          <Dialog
            header="Info"
            visible={showAlert}
            style={{ width: "30vw" }}
            onHide={() => closeAlert()}
            footer={footerContent}
          >
            <p className="m-0">{alertMessage}</p>
          </Dialog>
        </Fragment>
      )}
      {
        <DeferredHistory
          show={showHistory}
          dockAt="right"
          tracker={selectedMasters?.filingId && selectedMasters}
          activityHistory={activityHistory}
          handleClose={closeHistory}
        />
      }
      {
        <DeferedComments
          show={showComments}
          dockAt="right"
          draft={selectedMasters}
          comments={comments}
          handleClose={closeComments}
        />
      }
    </Fragment>
  );
}

export default AddFilingList;
