import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import Typography from "@mui/material/Typography";
import useDialog from "../common/UseDialog";
import Loader from "../common/Loader";
import { Dialog } from "primereact/dialog";
import { Button as PrimeButton } from "primereact/button";
import { CircularProgress } from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles({
//   customTableContainer: {
//     overflowX: "initial",
//   },
// });

const BusinessCategoryList = (props) => {
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert1, setShowAlert] = useState(false);
  const [apiResponse, setApiResponse] = useState(null)
  const [value, setValue] = React.useState("1");

  const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
  // const classes = useStyles();
  const filingMasterContext = useContext(FilingMasterContext);
  const {
    getBusinessCategories,
    deleteBusinessCategory,
    filingMasterLoading,
    businessCategories,
  } = filingMasterContext;
  useEffect(() => {
    if (props.isTableLoading) {
      console.log("props-loading", props.isTableLoading);
    }
  }, [props.isTableLoading]);
  const handleSubmit = () => {
    console.log("Submitting Delete....", selectedRowForDelete);
    deleteCategory();
  };

  const closeConfirm = () => {
    console.log("Cancelling....");
    setSelectedRowForDelete(null);
    // navigate("/");
  };

  const {
    AlertDialog: ConfirmDialog,
    showDialog,
    closeDialog,
  } = useDialog({
    onSubmit: handleSubmit,
    onClose: closeConfirm,
  });

  const { AlertDialog, showDialog: showAlert } = useDialog({});

  const fetchBusinessCategories = async () => {
    setIsLoading(true);
    console.log("@@Fetch BusinessCategories:", getBusinessCategories);
    try {
      if (getBusinessCategories) {
        console.log("@@Fetch BusinessCategories:");
        try {
          let catList = await getBusinessCategories();
          catList.sort((a, b) => (a.businessCategoryName ?? '').localeCompare(b.businessCategoryName ?? ''));

          console.log("@@BusinessCategories:", catList);
          // setRows(() => catList);
        } catch (error) {
          console.log("@@Error:", error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // fetch(`${API_BASE_URL}/Customer/BusinessCategoryList`)
    //   .then((response) => response.json())
    //   .then((data) => setRows(data));
    fetchBusinessCategories();
  }, []);

  useEffect(() => {
    if (businessCategories) {
      setRows(businessCategories);
    }
  }, [businessCategories]);

  const handleMenuClick = (event, row) => {
    // props.setName(row);
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    // TODO: Implement edit functionality
    handleMenuClose();
    props.selectRowForEdit(selectedRow);
  };

  const handleDelete = async () => {
    console.log("SHOW DIALOG", selectedRow);
    setSelectedRowForDelete(selectedRow);
    showDialog(
      "Confirmation",
      <Typography>
        Are you sure to delete the Business Category: {" "}
        <Typography fontWeight="bold" component="span">
          {selectedRow.businessCategoryName}?
        </Typography>
      </Typography>,
      "confirm"
    );
    handleMenuClose();
  };

  const deleteCategory = async () => {
    console.log("SHOW DIALOG2", selectedRow);
    console.log("selectedRowForDelete3", selectedRowForDelete);
    setIsLoading(true);
    props.formReset();
    try {
      const res = await deleteBusinessCategory(selectedRowForDelete);
      console.log("res", res);
      if (res.status === "Success") {
        // setAlertMessage(
        //   <Typography>
        //     Successfully deleted the Business Category {" "}
        //     <Typography fontWeight="bold" component="span">
        //       {selectedRowForDelete.businessCategoryName}
        //     </Typography>
        //   </Typography>
        // );
        setApiResponse(res)
        setShowAlert(true);
      } else {
        setApiResponse(res)
        setShowAlert(true);
      }

      closeDialog();
      // setSelectedRow(null);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
    setSelectedRowForDelete(null);
    // try {
    //   await fetch(
    //     `${API_BASE_URL}/Customer/DeleteBusinessCategory/${selectedRow.businessCategoryId}`,
    //     {
    //       method: "DELETE",
    //     }
    //   );
    //   setRows((prevRows) => prevRows.filter((r) => r !== selectedRow));
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );
  const closeAlert = () => {
    setShowAlert(false);
  };

  const filteredRows = rows.filter((row) => {
    return (
      row.businessCategoryName
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      row.businessCategoryId.toString().includes(searchText)
    );
  });

  return (
    <>
      <Dialog
        header="Info"
        visible={showAlert1}
        style={{ minWidth: "30vw", maxWidth: "60vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          {apiResponse?.status === "Success" ? (
            <Typography>
              Successfully deleted the Business Category{" "}
              {selectedRowForDelete && (<Typography fontWeight="bold" component="span">
                {selectedRowForDelete.businessCategoryName}
              </Typography>)}

            </Typography>
          ) : (
            <Box>
              <div>
                <Typography>
                  {apiResponse?.errorMessage}
                </Typography>
              </div>
              <div>
                {apiResponse?.data && (
                  <TabContext value={value}>
                    <Box>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Client List" value="1" />
                        <Tab label="Filing List" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel style={{ padding: "0px" }} value="1">
                      <ul>
                        {apiResponse?.data?.CustomerNames.map((item, index) => (

                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </TabPanel>
                    <TabPanel style={{ padding: "0px" }} value="2">
                      <ul>
                        {apiResponse?.data?.FilingNames.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </TabPanel>
                  </TabContext>
                )}
              </div>
            </Box>
          )}
        </Box>
      </Dialog>
      <TextField
        label="Search"
        value={searchText}
        onChange={handleSearch}
        fullWidth
        margin="normal"
        sx={{
          width: "60vw",
          marginLeft: "1.2rem",
          background: "rgba(0, 0, 0, 0.06)",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Paper
        sx={{
          width: "60vw",
          marginLeft: "1.2rem",
          height: "60vh",
          overflowY: "auto",
        }}
      >
        <TableContainer
          // classes={{ root: classes.customTableContainer }}
          sx={{
            m: "1.2rem",
            width: "90%",
          }}
        >
          <Table stickyHeader style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: "large", fontWeight: "550" }}
                >
                  Business Category
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontSize: "large", fontWeight: "550" }}
                >
                  Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading || props.isTableLoading ? (
                <TableRow style={{ borderBottom: "none" }}>
                  <TableCell
                    colSpan={2}
                    style={{ textAlign: "center", borderBottom: "none" }}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                filteredRows.map((row) => (
                  <TableRow
                    key={row.businessCategoryId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.businessCategoryName}
                    </TableCell>
                    <TableCell
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {row.businessCategoryDescription}
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuClick(event, row)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
      <ConfirmDialog />
      <AlertDialog />
    </>
  );
};

export default BusinessCategoryList;
