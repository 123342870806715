import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Checkbox } from "primereact/checkbox";
import { Button, Stack, FormGroup, FormControlLabel, FormControl, Checkbox as MUICheckbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomerContext from "../../../context/customer/CustomerContext";
import AuthContext from "../../../context/auth/AuthContext";
import ClientFilingMasterContext from "../../../context/client-filing-master/ClientFilingMasterContext";
import FilingMasterContext from "../../../context/filing-master/FilingMasterContext";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Loader from "../../common/Loader";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ScrollPanel } from "primereact/scrollpanel";
import { OverlayPanel } from "primereact/overlaypanel";
import { DateTime } from "luxon";
import { Dropdown } from "primereact/dropdown";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import states from "../../../data/StateLookup.json"
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const AddFilingTable = (props) => {
  let { clientId } = useParams();
  // const [checked, setChecked] = useState(false);
  // const [FilingMasterList, setMasterFilingList] = useState([]);
  const [_clientId, setClientId] = useState(clientId);
  const [updatedFilingMasterList, setUpdatedFilingMasterList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedMasters, setSelectedMasters] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [masterTypeVal, setMasterTypeVal] = useState(false);
  const [clientTypeVal, setClientTypeVal] = useState(false);
  const [isFilingTypeChanged, setIsFilingTypeChanged] = useState(false);
  const [isFilingTypeDisabled, setIsFilingTypeDisabled] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [masterCatFilter, setMasterCatFilter] = useState([]);
  const [stateArray, setStateArray] = useState([]);
  const [selectRows, setSelectRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortMaster, setSortMaster] = useState([]);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [sortRecord, setSortRecord] = useState("Upcoming Due Dates");
  const [rowClick, setRowClick] = useState(true);
  const [categoryList, setCategoryList] = useState([])
  const [isClearIcon, setIsClearIcon] = useState(false);

  const [lazyLoading, setLazyLoading] = useState(false);
  const [refreshRecordList, setRefreshRecordList] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(null);
  const [recordsInView, setRecordsInView] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilterChange, setIsFilterChange] = useState([]);
  const [sortingData, setSortingData] = useState({
    sortBy: "",
    sortOrder: "",
  });

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    customerName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    filingName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // filingId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // filingDescription: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingFrequency: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // dueDayofFrequency: { value: null, matchMode: FilterMatchMode.IN },
    stateList: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ruleInfo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    required: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    federalCategories: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    stateCategories: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    // jsidept: { value: null, matchMode: FilterMatchMode.CONTAINS },
    jsicontactName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // jsiContactEmail: { value: null, matchMode: FilterMatchMode.CONTAINS },
    juristiction: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
  });
  const navigate = useNavigate();
  const customerContext = useContext(CustomerContext);
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const { selectedCustomer } = customerContext;
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [showAlertInfo, setShowAlertInfo] = React.useState(false);
  const [selectedPeriods, setSelectedPeriods] = useState(null);
  // const [showAlertMessage, setShowAlertMessage] = React.useState(false);
  const {
    getClientFilingMastersForAdd,
    ClientFilingMastersAdd,
    ClientFilingMasterLoaded,
    addClientFilingMaster,
  } = clientFilingMasterContext;

  const filingMasterContext = useContext(FilingMasterContext);
  const { getBusinessCategories } = filingMasterContext;

  const authContext = useContext(AuthContext);
  const {
    authUser,
    isAuthenticated,
    getCustomerNotifications,
    getFilingNotifications,
    getCustomerNotificationsWorkFlow,
    setNotificationCount,
  } = authContext;
  const [customerNotificationCount, setCustomerNotificationCount] =
    React.useState(0);
  const [filingNotificationCount, setFilingNotificationCount] =
    React.useState(0);
  const [customerNotificationsWorkFlow, setCustomerNotificationsWorkFlow] =
    React.useState(0);

  const submitDialog = () => {
    // closeConfirm();
    //Storing only the selected row of slected filing periods 
    const filtPeriods = selectedPeriods.filter(period => selectedMasters.filingPeriods.some(item => item.periodId === period.periodId))
    console.log("submitDialog", "filtPeriods", filtPeriods);
    if (filtPeriods.length === 0) {
      setAlertMessage("Please select periods for submit");
      setShowAlertInfo(true);
      return;
    } else {
      setSelectedPeriods(filtPeriods)
      console.log("submitDialog", "selectedMaster", selectedMasters, "selectedPeriods", selectedPeriods);
      if (selectedMasters.isRejected) {
        setAlertMessage("Do you want to submit this filing for approval?");
      } else {
        setAlertMessage("Do you want to submit the filing with selected periods?")
      };
      setShowAlert(true);
    }
  };

  const fetchNotificationCount = async () => {
    console.log("CustomerNotification", getCustomerNotifications);
    console.log("filingNotifications", getFilingNotifications);

    if (
      getCustomerNotifications &&
      getFilingNotifications &&
      getCustomerNotificationsWorkFlow
    ) {
      console.log("@@Fetch Customers2:");
      try {
        let custList = await getCustomerNotifications();
        custList = custList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        console.log("@@customerNotifications:", custList);
        // setTotalNotificationCount((old) => old + custList.length);

        setCustomerNotificationCount(custList.length);
        let filingList = await getFilingNotifications();
        filingList = filingList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        console.log("@@filingNotifications:", filingList);
        // setTotalNotificationCount((old) => old + filingList.length);
        setFilingNotificationCount(filingList.length);
        let custListWorkflow = await getCustomerNotificationsWorkFlow();
        custListWorkflow = custListWorkflow
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");
        console.log("@@filingNotifications:", custListWorkflow);

        setCustomerNotificationsWorkFlow(custListWorkflow.length);
        // setTotalNotificationCount(
        //   (old) =>
        //     custList?.length + filingList?.length + custListWorkflow?.length
        // );
        const count =
          custList?.length + filingList?.length + custListWorkflow?.length;
        if (setNotificationCount) {
          setNotificationCount(count);
        }
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const submitHandler = async () => {
    // console.log("submitHandler", "selectedPeriods", selectedPeriods);
    // [
    //   {

    //     "customerId": 117,
    //     "filingId": 1311,
    //     "notes": "API TEST 2",
    //     "createDate": "2023-04-02T15:36:33.916Z",
    //     "createUser": "eswarans@infinite.com",
    //     "updateDate": "2023-04-02T15:36:33.916Z",
    //     "updateUser": "eswarans@infinite.com",
    //     "businessOperation": "add"
    //   }
    // ]
    console.log("AuthUSer....", authUser);
    setShowAlert(false);
    setShowAlertInfo(false)
    setIsLoading(true);
    const payLoad = selectedPeriods.map((period) => ({
      periodId: period?.periodId,
      customerId: clientId,
      filingId: selectedMasters.filingId,
      createDate: new Date(),
      createUser: authUser.userId + "",
      updateDate: new Date(),
      updateUser: authUser.userId + "",
      businessOperation: "add",
      status: "Pending",
    }))


    // console.log("Adding Records", payLoad);
    try {
      if (addClientFilingMaster) {
        const res = await addClientFilingMaster(payLoad);
        console.log("Result....", res);
        // if (res) {

        setRecordsInView(0);
        setIsLoading(true);
        setUpdatedFilingMasterList([]);
        setIsFilterChange([]);
        setSortingData({ sortBy: "", sortOrder: "" });
        setMasterCatFilter([]);
        setRefreshRecordList(true);

        if (selectedMasters.isRejected) {
          setDialogMessage("Successfully submitted filings for approval");
        } else {
          setDialogMessage("Successfully submitted and approved the Client Filing. Please check the filing in Tracker page.");
        }
        setDialogTitle("Success");
        setOpenDialog(true);

        setSelectedMasters(null);
        setSelectedPeriods([])
        await fetchNotificationCount();
        setIsLoading(false);
        // }
      }
    } catch (error) {
      setIsLoading(false);
      setDialogTitle("Error");
      setDialogMessage("Unexpected Error Occurred");
      setOpenDialog(true);
      // showDialog("Error", "Unexpected Error Occured", "error");

      console.log("Setting Alert to =>", `Unexpected Error Occured`, error);
    }
  };

  const fetchClientFilingMasters = async (clientId) => {
    console.log("@@Fetch Cust Filing Master:", clientId);
    // if (clientId) {
    // setIsLoading(true);
    // console.log("fetchClientFilingMasters ", masterTypeVal, clientTypeVal);
    let filingType
    if (clientTypeVal !== masterTypeVal && !isFilingTypeDisabled) {
      filingType = clientTypeVal ? "ClientFiling" : "MasterFiling"
    }

    let payLoad = { customerId: clientId };
    if (getClientFilingMastersForAdd) {
      console.log("@@Fetch Cust Filing Master:");
      try {
        const custList = await getClientFilingMastersForAdd(payLoad, filingType ? filingType : null);
        console.log("@@Cust Filing Master:", custList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
    // }
  };

  const onRowSelect = (e) => {
    setSelectRows(e);
    console.log("@@RowSelect:", e);
    console.log("@@", selectRows);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleChange = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  useEffect(() => {
    console.log("state", selectedRows);
    props.setLoadingFlag(isLoading);
  }, [isLoading]);

  useEffect(() => {
    console.log("state", selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    console.log("####ClientID param", _clientId, clientId);
    if (_clientId !== undefined && _clientId !== null && clientId !== "" && clientId !== _clientId) {
      // fetchClientFilingMasters(_clientId);
      setSelectedMasters(null);
      setRecordsInView(0);
      setIsLoading(true);
      setUpdatedFilingMasterList([]);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setMasterCatFilter([]);
      setRefreshRecordList(true);
      setGlobalFilterValue('');
      setIsClearIcon(false)
      setFilters({
        ...filters,
        global: {
          ...filters.global,
          value: null,
        },
      });
      search.current.value = ""
    } else {
      setUpdatedFilingMasterList([])
      setSelectedMasters(null);
      setRecordsInView(0);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setGlobalFilterValue('');
      setIsClearIcon(false)
      setFilters({
        ...filters,
        global: {
          ...filters.global,
          value: null,
        },
      });
      search.current.value = ""
    }
  }, [_clientId]);

  useEffect(() => {
    console.log("####ClientID param", _clientId, isFilingTypeChanged, clientId);
    if (isFilingTypeChanged && _clientId !== undefined && clientId !== "") {
      if (masterTypeVal === false && clientTypeVal === false) {
        setUpdatedFilingMasterList([])
        setIsFilingTypeChanged(false)
      } else {
        console.log("#### CHECK 2", _clientId, clientId);
        // fetchClientFilingMasters(_clientId);
        setRecordsInView(0);
        setIsLoading(true);
        setUpdatedFilingMasterList([]);
        setIsFilterChange([]);
        setSortingData({ sortBy: "", sortOrder: "" });
        setMasterCatFilter([]);
        setRefreshRecordList(true);
      }
      setSelectedMasters(null);
    }
  }, [isFilingTypeChanged]);

  useEffect(() => {
    if (selectedCustomer) {
      // fetchClientFilingMasters(selectedCustomer.customerId);
      // console.log("####Setting ClientID state", selectedCustomer.customerId);
      // setClientId(() => selectedCustomer.customerId);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    // if (props.selectedClientId) {
    console.log("####Setting ClientID param props", props.selectedClientId);
    setClientId(() => props.selectedClientId);
    setClientTypeVal(false)
    setMasterTypeVal(false)
    // }
  }, [props.selectedClientId]);

  // useEffect(() => {
  //   // if (clientId) setSelectedClient((old) => clientId);
  //   if (!clientId && !selectedClient) {
  //     fetchClientFilingMasters();
  //   }
  //   //eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (ClientFilingMastersAdd) {
      // console.log("Orig Filing List...", ClientFilingMastersAdd);
      // const updList =
      //   ClientFilingMastersAdd.deferredList ?
      //     ClientFilingMastersAdd.deferredList.map((item) => {
      //       console.log(`Customer filing M...`, item);

      //       if (item.juristiction?.includes("Federal")) {
      //         const categories = _.map(
      //           item.businessCategory,
      //           "businessCategoryName"
      //         ).join(", ");
      //         console.log(`Customers ${item.filingId} cat...`, categories);
      //         return { ...item, federalCategories: categories };
      //       } else {
      //         console.log(
      //           "stateCategories: props",
      //           item.stateInfo,
      //           item.businessCategory
      //         );
      //         const categoriesState = item.businessCategory.filter((ele) =>
      //           ele.state ? ele.state === item.stateInfo : ele
      //         );
      //         console.log(
      //           "stateCategories: filter",
      //           item.filingName,
      //           categoriesState
      //         );
      //         const categories = categoriesState
      //           .map((ele) => ele.businessCategoryName)
      //           .join(", ");

      //         console.log(`filingMasters ${item.filingMasters} cat...`, categories);

      //         return { ...item, stateCategories: categories };
      //       }
      //     })
      //     : [];
      // // console.log("Updated Customers filing M...", updList);
      // if (clientTypeVal === masterTypeVal) {
      //   setClientTypeVal(ClientFilingMastersAdd.isClientFile)
      //   setMasterTypeVal(ClientFilingMastersAdd.isMasterFile)
      //   if (ClientFilingMastersAdd.isClientFile === true && ClientFilingMastersAdd.isMasterFile === true) {
      //     setIsFilingTypeDisabled(false)
      //   } else {
      //     setIsFilingTypeDisabled(true)
      //   }
      // }
      // setIsFilingTypeChanged(false)
      // // setData(updCustomers);
      // setUpdatedFilingMasterList(updList);
      // setIsLoading(false);
    }

    //eslint-disable-next-line
  }, [ClientFilingMastersAdd]);

  const fetchBusinessCategories = async () => {
    console.log("@@Fetch BusinessCategories:", getBusinessCategories);
    if (getBusinessCategories) {
      console.log("@@Fetch BusinessCategories:");
      try {
        let list = []
        const resList = await getBusinessCategories();
        console.log("@@BusinessCategories:", resList);
        list = resList.map((item) => item.businessCategoryName).sort()
        console.log("@@BusinessCategories: list", list);
        setCategoryList(() => list);
        return
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };


  useEffect(() => {
    // getClientFilingMastersForAdd({ clientId: 0 });
    fetchBusinessCategories()
    let list = states.map((ele) => ele.state);
    setStateArray(list);
  }, []);

  useEffect(() => {
    if (authUser && authUser?.userId) fetchNotificationCount();
  }, [authUser]);

  const footerContent = (
    <div>
      <PrimeButton
        label='No'
        icon='pi pi-times'
        className='p-button-text'
        onClick={() => closeAlert()}
      />
      <PrimeButton
        label='Yes'
        icon='pi pi-check'
        onClick={() => submitHandler()}
        autoFocus
      />
    </div>
  );
  const footerContentInfo = (
    <div>

      <PrimeButton
        label='OK'
        icon='pi pi-check'
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const dialogFooter = (
    <div>
      <PrimeButton
        label='OK'
        icon='pi pi-check'
        onClick={() => setOpenDialog(false)}
        autoFocus
      />
    </div>
  );

  const closeAlert = () => {
    setShowAlert(() => false);
    setShowAlertInfo(() => false)
    setAlertMessage("")
  };

  const handleFilingTypeChange = (type) => {
    console.log("handleFilingTypeChange", type);
    setIsFilingTypeChanged(true)
    if (type === "master") {
      setMasterTypeVal(!masterTypeVal)
    } else {
      setClientTypeVal(!clientTypeVal)
    }
  };

  const search = useRef()

  const applyGlobalFilter = (e) => {
    // console.log("applyGlobalFilter",e,search);
    let _filters = { ...filters };
    if (filters.global.value === null) {
      _filters["global"].value = search.current.value;
      setIsClearIcon(true);
    } else {
      _filters["global"].value = null;
      search.current.value = ""
      setIsClearIcon(false);
    }
    setFilters(_filters);
    onFilterChange({ filters });
  };

  const renderHeader = () => {
    return (
      <Grid container md={"auto"} sx={{ flexDirection: "row" }}>
        <Grid md={3} sx={{ margin: 1 }}>
          <Typography
            variant='h6'
            noWrap
            component='a'
            sx={{
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
            style={{
              fontSize: "16px",
            }}
          >
            <b>DEFERRED FILINGS ({totalRecords})</b>
          </Typography>
        </Grid>
        <Grid md={3}>
          <FormControl component='fieldset'
            style={{
              fontSize: "16px",
            }}
          >
            <FormGroup aria-label='position' Column>
              <FormControlLabel
                value='top'
                control={
                  <MUICheckbox
                    name='trackNow'
                    checked={masterTypeVal}
                    disabled={isFilingTypeDisabled}
                    onClick={() => handleFilingTypeChange("master")}
                  />
                }
                label='Master Filing'
                labelPlacement='end'
              />
              <FormControlLabel
                value='top'
                control={
                  <MUICheckbox
                    name='postAward'
                    checked={clientTypeVal}
                    disabled={isFilingTypeDisabled}
                    onClick={() => handleFilingTypeChange("client")}
                  />
                }
                label='Client Filing'
                labelPlacement='end'
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid md={3}>
          <span style={{ display: "flex" }}>
            <InputText
              ref={search}
              inputRef={search}
              placeholder='Search by Filing Name...'
            />
            <PrimeButton
              icon={isClearIcon ? "pi pi-times" : "pi pi-search"}
              onClick={applyGlobalFilter} />
          </span>
        </Grid>
        <Grid md={2} style={{ textAlign: "center" }}>

          <Button
            color='primary'
            variant='contained'
            onClick={submitDialog}
            disabled={!selectedMasters?.filingId}

          >
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    );
  };

  //const header = renderHeader();
  const cellListDisplayName = (rowData) => {
    return (
      <LightTooltip title={rowData.filingName}>
        <div
          style={{
            width: "inherit",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.filingName}
        </div>
      </LightTooltip>
    );
  };

  const cellListDisplayRuleInfo = (rowData) => {
    return (
      <LightTooltip title={rowData.ruleInfo}>
        <div
          style={{
            width: "12rem",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.ruleInfo}
        </div>
      </LightTooltip>
    );
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F1F2F6",
      color: "black",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  const CellListDisplayFederal = (rowData, field) => {
    const op = useRef(null);

    let displayFederal = Array.isArray(rowData[field])
      ? rowData[field].join(", ")
      : rowData[field];

    console.log("CellListDisplayFederal", field, rowData.filingName, rowData);

    const categories = rowData[field]
      ? field === "federalCategories"
        ? rowData.businessCategory.map((ele) => ele.businessCategoryName)
        : rowData.businessCategory
          .filter((ele) => ele.state === rowData.stateInfo)
          .map((ele) => ele.businessCategoryName)
      : [];
    console.log("CellListDisplayFederal categories--", categories);

    let name =
      field === "federalCategories"
        ? "Who Must File Federal"
        : "Who Must File in State";

    return (
      <div className='tableBtn' id={`${field}+${rowData.fileTrackingId}`}>
        <PrimeButton onClick={(e) => op.current.toggle(e)} text>
          <div
            style={{
              width: "12rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "black",
            }}
          >
            {displayFederal}
          </div>
        </PrimeButton>
        <OverlayPanel ref={op} showCloseIcon style={{ padding: "0rem 1rem" }}>
          {name}
          <ScrollPanel style={{ maxWidth: "25rem", maxHeight: "20rem" }}>
            <ul>
              {categories.map((ele) => (
                <li>{ele}</li>
              ))}
            </ul>
          </ScrollPanel>
        </OverlayPanel>
      </div>
    );
  };

  const toLocalTime = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yy");
      //  console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };
  const toLocalTime2 = (date) => {
    console.log("toLocalTime", date);
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-LL-yyyy hh:mm:ss"),
        "dd-LL-yyyy hh:mm:ss",
        { zone: "UTC" }
      ).toFormat("yyyy-LL-dd");
      // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };
  const handlePeriodChange = (e) => {
    console.log("handlePeriodChange", "e", e);
    if (e && e.value.length > 0) {
      const lastSelectedFilingPeriods = e.value[e.value.length - 1] // filtering the last selected filing periods
      // console.log("handlePeriodChange lastSelectedFilingPeriods", lastSelectedFilingPeriods, "updatedFilingMasterList", updatedFilingMasterList);
      const matchingFiling = updatedFilingMasterList.find(data => data.filingId === lastSelectedFilingPeriods.filingId)//selectimg a row based on selecting filing period

      const matchingFilingPeriod = e.value.filter(val => val.filingId === matchingFiling.filingId)// storing only selected row filing periods
      // console.log("handlePeriodChange matchingFilingPeriod", matchingFiling, "matchingFilingPeriod", matchingFilingPeriod);
      //Automatically selecting the row when respective periods is checked
      if (matchingFiling.isRejected) {
        //If filing is rejected then display all the filing periods 
        setSelectedPeriods(matchingFiling.filingPeriods)
        setSelectedMasters(matchingFiling)
      } else {
        setSelectedPeriods(matchingFilingPeriod)
        setSelectedMasters(matchingFiling)
      }
    } else {
      setSelectedPeriods(e.value)
      setSelectedMasters(null)
    }
  }
  const handleRowChange = (e) => {
    console.log("handleRowChange", "e", e);
    setSelectedPeriods(null)
    setSelectedMasters(e.value);
    setSelectedPeriods(e?.value?.filingPeriods)
  }

  useEffect(() => {
    if (selectedPeriods?.length > 0 || selectedMasters?.length > 0) {
      props.changeSelectedPeriods(selectedPeriods)
      props.changeSelectedMasters(selectedMasters)

    } else {
      props.changeSelectedPeriods([])
      props.changeSelectedMasters(null)


    }
  }, [selectedPeriods, selectedMasters])
  console.log("selectedPeriods", selectedPeriods);

  const rowExpansionTemplate = (data) => {
    // console.log("rowExpansionTemplate", data.filingPeriods, "data", data);
    if (data.filingPeriods.length > 0) {
      let tableData = data.filingPeriods.map((ele) => {
        let startDate = new Date(ele.startDate);
        let endDate = new Date(ele.endDate);
        const toLocalDateString = date => {
          const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
          return date.toLocaleDateString('en-US', options).replace(/(\d+)\/(\d+)\/(\d+)/, (_, month, day, year) => `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`);
        };

        let fp = `${toLocalDateString(startDate)} - ${toLocalDateString(endDate)}`;
        let dueDate = ele.dueDate !== null ? toLocalDateString(new Date(ele.dueDate)) : null;
        return {
          periodId: ele.periodId,
          startDate: ele.startDate,
          endDate: ele.endDate,
          filingPeriod: fp,
          dueDate: dueDate,
          filingId: ele.filingId,
          fileTrackingId: ele.fileTrackingId !== null ? ele.fileTrackingId : [],
          workflowId: ele.workflowId !== null ? ele.workflowId : [],
        };
      });
      // console.log("rowExpansionTemplate tableData", tableData);

      return (
        <div className="filingPeriodTable p-3" style={{ width: "50vw" }}>
          <h5>Due Dates for {data.filingName}</h5>
          <DataTable value={tableData} dataKey="periodId" selection={selectedPeriods} onSelectionChange={(e) => handlePeriodChange(e)}>
            <Column selectionMode="multiple" headerStyle={{ width: '3rem', right: "7px" }}></Column>
            <Column field="filingPeriod" header="Filing Period"></Column>
            <Column field="dueDate" header="DueDate"></Column>
          </DataTable>
        </div>
      );
    } else {
      return (
        <div className="filingPeriodTable p-3">
          <h5>No Due Dates for {data.filingName}</h5>
        </div>
      );
    }
  };
  const rowExpand = (event) => {
    console.log("rowExpand", event, expandedRows);
    setExpandedRows(event.data);
  };

  const jusrisdictionItemTemplate = (option) => {
    // console.log("##TOptions:", option);
    return (
      <div className='flex align-items-center'>
        <span>{option}</span>
      </div>
    );
  };

  const jusrisdictionArray = ["Federal", "State", "Both"];

  const jurisdictionRowFilterTemplate = (options) => {
    // console.log("jurisdictionRowFilterTemplate props", options,masterCatFilter);

    if (!masterCatFilter.jurisdiction && options.filterModel.value) {
      options.filterModel.value = null;
      options.value = null;
    }

    return (
      <Dropdown
        value={masterCatFilter.jurisdiction || []}
        options={jusrisdictionArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##EOptions:", e);
          setMasterCatFilter((prev) => {
            return { ...prev, jurisdiction: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const requiredArray = ["True", "False"];

  const requiredRowFilterTemplate = (options) => {
    // console.log("requiredRowFilterTemplate:", options,masterCatFilter);
    if (!masterCatFilter.required && options.filterModel.value) {
      options.filterModel.value = null;
      options.value = null;
    }
    return (
      <Dropdown
        value={masterCatFilter.required || []}
        options={requiredArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setMasterCatFilter((prev) => {
            return { ...prev, required: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const masterCategoryFilter = (options, field) => {
    // console.log("OPTIONCHECK", options, "field", field);
    return options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={masterCatFilter[field] || []}
        options={categoryList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setMasterCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={masterCatFilter[field] || []}
        options={categoryList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setMasterCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          let values = [];
          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          options.filterCallback(values, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const stateRowFilterTemplate = (options) => {
    if (!masterCatFilter.stateList && options.filterModel.value) {
      options.filterModel.value = [];
      options.value = [];
    }
    return options.filterModel.matchMode === "contains" ? (
      <MultiSelect
        value={masterCatFilter.stateList || []}
        options={stateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setMasterCatFilter((prev) => {
            return { ...prev, stateList: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "12rem" }}
      />
    ) : (
      <Dropdown
        value={masterCatFilter.stateList || []}
        options={stateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setMasterCatFilter((prev) => {
            return { ...prev, stateList: e.value };
          });
          let values = [];
          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          options.filterCallback(values, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "12rem" }}
      />
    );
  };

  const dt = useRef(null);
  const pageSize = 100;

  // lazy load function
  const loadDataLazy = async (event) => {
    console.log("loadDataLazy props", recordsInView, event);

    //Virtual scroll callback
    const vScroll = dt.current.getVirtualScroller()

    let range = vScroll.getRenderedRange();
    let { first, last } = range.viewport;
    console.log("loadDataLazy vScroll", vScroll, range.viewport);

    let firstVal = recordsInView * pageSize;
    let tableList = updatedFilingMasterList;
    console.log(
      "loadDataLazy totalRecords",
      firstVal,
      totalRecords,
      lazyLoading,
      tableList.length
    );

    console.log(
      "loadDataLazy",
      firstVal < totalRecords,
      first > tableList.length - 10,
      !lazyLoading
    );

    if (
      firstVal < totalRecords &&
      first > tableList.length - 10 &&
      !lazyLoading
    ) {
      //scroll down
      // setIsLoading(true);
      setLazyLoading(true);
      let lastVal = firstVal + pageSize;
      console.log("loadDataLazy d firstVal", firstVal, lastVal);

      //get next data
      let records = await loadRecordsList(firstVal, lastVal, "down");
      console.log("loadDataLazy d record", records);
      if (records) {
        console.log("loadDataLazy d scrollInView", recordsInView * 100);

        //scroll to next records
        vScroll.scrollInView(recordsInView * 100);
        setRecordsInView((old) => old + 1);
        setLazyLoading(false);
        setIsLoading(false);
      }
    } else if (last < 10 && recordsInView > 1 && !lazyLoading) {
      // scroll up
      let firstVal = (recordsInView - 3) * pageSize;
      let lastVal = firstVal + pageSize;
      if (firstVal >= 0) {
        // setIsLoading(true);
        setLazyLoading(true);
        // console.log("loadDataLazy u firstVal", firstVal, lastVal);

        //get prev data
        let records = await loadRecordsList(firstVal, lastVal, "up");
        // console.log("loadDataLazy u record", records);
        if (records) {
          // console.log("loadDataLazy u scrollInView", (recordsInView - 3) * 100);

          //scroll to prev records
          vScroll.scrollToIndex(15);
          setRecordsInView((old) => old - 1);
          setLazyLoading(false);
          setIsLoading(false);
        }
      }
    }
  };

  const loadRecordsList = async (first, last, direction) => {
    // console.log("loadRecordsList props", first, last, direction);
    setIsLoading(true);

    let url = `${API_BASE_URL}/Customer/BusinessBasedFilingMasterListbyPagination?StartRow=${first + 1}&EndRow=${last}&CustomerId=${clientId}`

    let filingType
    if (clientTypeVal !== masterTypeVal) {
      filingType = clientTypeVal ? "ClientFiling" : "MasterFiling"
    }
    if (filingType) {
      url = `${url}&FilingType=${filingType}`
    }

    // console.log("loadRecordsList fliter/sort", isFilterChange, sortingData);
    if (isFilterChange !== undefined && isFilterChange.length > 0) {
      // url filter parameters
      isFilterChange.forEach((ele) => {
        url = `${url}&${ele.name}=${ele.val}${ele.searchType ? `&${ele.searchType}=${ele.searchTypeVal}` : ``
          }`;
      });
    }

    if (
      sortingData &&
      typeof sortingData === "object" &&
      sortingData.sortBy !== "" &&
      sortingData.sortOrder !== ""
    ) {
      // url sort parameters
      let sortParameter = getSortData(sortingData.sortBy);
      console.log("getSortData", sortParameter);
      let order = sortingData.sortOrder === 1 ? "Ascending" : "Descending";

      url = `${url}&${sortParameter.SortByParameter}=${sortParameter.SortByVal}&${sortParameter.SortOrderParameter}=${order}`;
    }

    // console.log("loadRecordsList url", url);
    try {
      //get data
      const res = await axios.get(url);
      console.log("loadRecordsList res", res, res.data.items);

      if (res.data) {
        setIsLoading(true);
        let ogList = updatedFilingMasterList.map((ele) => ele);
        await modifyList(res.data.items, direction, ogList);
        setTotalRecords(res.data.totalItems);

        if (isFilterChange && isFilterChange.length <= 0 &&
          sortingData && sortingData.sortBy === "") {

          if (clientTypeVal === masterTypeVal) {
            setClientTypeVal(res.data.isClientFile)
            setMasterTypeVal(res.data.isMasterFile)
            if (res.data.isClientFile === true && res.data.isMasterFile === true) {
              setIsFilingTypeDisabled(false)
            } else {
              setIsFilingTypeDisabled(true)
            }
          }
          setIsFilingTypeChanged(false)
        }

        return res.data.items;
      } else {
        return false;
      }
    } catch (err) {
      // console.log("loadRecordsList err", err);
    }
  };

  // //maping records for lazy load
  const modifyList = (list, direction, ogList) => {
    console.log("modifyList", list, direction);
    if (list.length > 0) {
      //list has data
      const updList = list.map((item) => {
        // console.log(`Customer filing M...`, item);
        const _stateList = getStateName(item.stateInfo);

        if (item.juristiction?.includes("Federal")) {
          const categories = _.map(
            item.businessCategory,
            "businessCategoryName"
          ).join(", ");
          // console.log(`Customers ${item.filingId} cat...`, categories);
          return { ...item, federalCategories: categories, stateList: _stateList, };
        } else {
          const categoriesState = item.businessCategory.filter((ele) =>
            ele.state ? ele.state === item.stateInfo : ele
          );
          const categories = categoriesState
            .map((ele) => ele.businessCategoryName)
            .join(", ");

          // console.log(`filingMasters ${item.filingMasters} cat...`, categories);

          return { ...item, stateCategories: categories, stateList: _stateList, };
        }
      })


      let tableList = updatedFilingMasterList;
      console.log("modifyList updCustomers", updList, tableList);

      let updFilingMastersList;
      if (direction === "down") {
        //scroll down
        if (tableList.length >= 200) {
          //list > 200

          //slice 2 half of list
          let unloadList = ogList.slice(
            ogList.length - pageSize,
            ogList.length
          );
          // console.log("modifyList dw unloadList", unloadList);

          //add new data
          updFilingMastersList = [...unloadList, ...updList];
        } else {
          //list < 200

          // add new data to list
          updFilingMastersList = [...ogList, ...updList];
        }
      } else if (direction === "up") {
        //scroll up
        if (tableList.length >= 200) {
          //list > 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updList, ...unloadList];
        } else {
          //list < 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updList, ...unloadList];
        }
      }

      setUpdatedFilingMasterList(() => updFilingMastersList);
      setRefreshRecordList(false);
      setLazyLoading(false);
      setIsLoading(false);
      return updFilingMastersList;
    } else {
      //list empty
      // console.log("modifyList empty",list)
      setRefreshRecordList(false);
      setLazyLoading(false);
      setIsLoading(false);
      setUpdatedFilingMasterList([]);
      return [];
    }
  };

  const getStateName = (stateCode) => {
    if (stateCode !== null) {
      const state = states.find((s) => s.code === stateCode);
      const stateName = state ? state.state : stateCode;
      return stateName;
    }
    return null
  };

  //table filter change
  const onFilterChange = async (e) => {
    console.log("onFilterChange props", e.filters);
    let filterChange = [];
    if (e.filters.global.value !== "" && e.filters.global.value !== null) {
      //global filter
      filterChange.push({
        name: `SearchByFilingName`,
        val: e.filters.global.value,
        searchType: `FilingNameSearchType`,
        searchTypeVal: getSearchType("contains"),
      });
    } else {
      //map filter event
      Object.keys(e.filters).forEach((col) => {
        console.log("onFilterChange col", col, e.filters[col]);
        if (
          e.filters[col].constraints &&
          e.filters[col].constraints[0].value !== null
        ) {
          if (col === "stateList") {
            Array.isArray(e.filters[col].constraints[0].value)
              ? e.filters[col].constraints[0].value.map((ele, i) => {
                let changeItem = {
                  name: `SearchByStateInfo`,
                  val: getStateCode(ele),
                };
                if (i + 1 === e.filters[col].constraints[0].value.length) {
                  changeItem.searchType = `StateInfoSearchType`;
                  changeItem.searchTypeVal = getSearchType(
                    e.filters[col].constraints[0].matchMode
                  );
                }
                filterChange.push(changeItem);
              })
              : filterChange.push({
                name: `SearchByState`,
                val: getStateCode(e.filters[col].constraints[0].value),
                searchType: `StateSearchType`,
                searchTypeVal: getSearchType(
                  e.filters[col].constraints[0].matchMode
                ),
              });
          } else if (col === "juristiction") {
            filterChange.push({
              name: `SearchByJuristiction`,
              val: e.filters[col].constraints[0].value,
            });
          } else if (col === "required") {
            filterChange.push({
              name: `SearchByRequired`,
              val: e.filters[col].constraints[0].value,
            });
          } else if (col === "jsicontactName") {
            filterChange.push({
              name: `SearchByJSIStaff`,
              val: e.filters[col].constraints[0].value,
              searchType: `JSIStaffSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "dueDate") {
            filterChange.push({
              name: `SearchByDueDate`,
              val: formatFilterDate(e.filters[col].constraints[0].value),
              searchType: `DueDateSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "federalCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              // console.log(
              //   "onFilterChange i",
              //   i + 1,
              //   e.filters[col].constraints[0].value.length
              // );
              let changeItem = {
                name: `WhoMustFileFederal`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileFederalSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else if (col === "stateCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              let changeItem = {
                name: `WhoMustFileInState`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileInStateSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else {
            filterChange.push({
              name: `SearchBy${col}`,
              val: e.filters[col].constraints[0].value,
              searchType: `${col}SearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          }
        }
      });
    }

    //set filter
    console.log("onFilterChange filterChange", filterChange);
    handleSetFilterVariable(filterChange);
  };

  const formatFilterDate = (date) => {
    // console.log("formatFilterDate", date);
    let getDate = new Date(date);
    let formatDate = `${getDate.getFullYear()}-${getDate.getMonth() + 1
      }-${getDate.getDate()}`;
    // console.log("formatFilterDate formatDate", getDate.getFullYear(), formatDate);
    return formatDate;
  };

  const getStateCode = (stateName) => {
    // console.log("getStateCode", stateName, states);
    const state = states.find((s) => s.state === stateName);
    return state ? state.code : stateName;
  };

  const matchModeVal = [
    { name: "contains", val: "Contains" },
    { name: "in", val: "Contains" },
    { name: "notContains", val: "NotContains" },
    { name: "equals", val: "EqualTo" },
    { name: "dateIs", val: "DateIs" },
    { name: "dateIsNot", val: "DateIsNot" },
    { name: "dateBefore", val: "DateIsBefore" },
    { name: "dateAfter", val: "DateIsAfter" },
  ];

  const getSearchType = (matchMode) => {
    // console.log("getSearchType props", matchMode);
    return matchModeVal.find((ele) => ele.name === matchMode).val;
  };

  const getSortData = (val) => {
    console.log("getSortData props", val);
    if (val === "dueDate") {
      return {
        SortByParameter: "SortByDuedate",
        SortByVal: "DueDate",
        SortOrderParameter: "SortOrderDuedate"
      }
    } else if (val === "stateList") {
      return {
        SortByParameter: "SortBy",
        SortByVal: "StateInfo",
        SortOrderParameter: "SortOrder"
      }
    } else if (val === "federalCategories" || val === "stateCategories") {
      return {
        SortByParameter: "SortByBusinessCategory",
        SortByVal: "BusinessCategory",
        SortOrderParameter: "SortOrderBusinessCategory"
      }
    } else {
      return {
        SortByParameter: `SortBy`,
        SortByVal: val.charAt(0).toUpperCase() + val.slice(1),
        SortOrderParameter: `SortOrder`
      }
    }
  }

  //set filter
  const handleSetFilterVariable = (filterChange) => {
    console.log("handleSetFilterVariable props", filterChange);
    setIsFilterChange(() => filterChange);
    setRecordsInView(0);
    setIsLoading(true);
    setUpdatedFilingMasterList([]);
  };

  //table sort change
  const handleSort = async (e) => {
    // console.log("handleSort props",e ,e.sortField)
    if (e.sortField !== "") {
      setSortingData(() => ({
        sortBy: e.sortField,
        sortOrder: e.sortOrder,
      }));
      setRecordsInView(0);
      setUpdatedFilingMasterList([]);
      setIsLoading(true)
    }
  }

  useEffect(() => {
    console.log("filter/sort change", isFilterChange, sortingData, !lazyLoading, !refreshRecordList);
    //on filter/sort change
    if (selectedCustomer?.customerId && !lazyLoading && !refreshRecordList) {
      const sortAndFilterApiCall = async () => {
        console.log("LOAD Check 4");
        let records = await loadRecordsList(0, 100, "down");
        // console.log("recordsCheck", records);
        setIsLoading(false);
        setRecordsInView(1)
      };
      sortAndFilterApiCall();
    }
  }, [isFilterChange, sortingData]);

  const handleRefreshList = () => {
    // fetchFilingMasters();
    setRecordsInView(0);
    setIsLoading(true);
    setUpdatedFilingMasterList([]);
    setIsFilterChange([]);
    setSortingData({ sortBy: "", sortOrder: "" });
    setMasterCatFilter([]);
    setRefreshRecordList(true);
  }

  const refreshRecords = async () => {
    console.log("refreshRecords");

    const vScroll = dt.current.getVirtualScroller()

    let range = vScroll.getRenderedRange();
    console.log("refreshRecords vScroll", vScroll, range);

    let records = await loadRecordsList(0, pageSize, "down");
    if (records) {
      console.log("refreshRecords refreshDetails", refreshDetails);
      if (refreshDetails?.viewport) {
        console.log(
          "refreshRecords scrollInView",
          refreshDetails.viewport.first
        );
        // vScroll.scrollToIndex(refreshDetails.viewport.first);
      }
      setRecordsInView(1);
    }
  };

  useEffect(() => {
    console.log("refreshRecordList,", refreshRecordList, !lazyLoading);
    if (refreshRecordList && !lazyLoading) {
      setLazyLoading(true);
      refreshRecords();
    }
  }, [refreshRecordList]);

  useEffect(() => {
    console.log("Total Records updated:", totalRecords);
  }, [totalRecords]);

  useEffect(() => {
    console.log("recordsInView,", recordsInView);
  }, [recordsInView]);

  useEffect(() => {
    console.log("pendingTableData,", updatedFilingMasterList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updatedFilingMasterList,
    updatedFilingMasterList.length,
    JSON.stringify(updatedFilingMasterList),
  ]);

  useEffect(() => {
    console.log("isLoading", isLoading);
  }, [isLoading]);

  useEffect(() => {
    console.log("lazyLoading", lazyLoading);
  }, [lazyLoading]);


  return (
    <Fragment>
      <Dialog
        header='Add Filing'
        visible={showAlert}
        style={{ width: "auto" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className='m-0'>{alertMessage}</p>
      </Dialog>
      <Dialog
        header='Info'
        visible={showAlertInfo}
        style={{ width: "28vw" }}
        onHide={() => closeAlert()}
        footer={footerContentInfo}
      >
        <p className='m-0'>{alertMessage}</p>
      </Dialog>
      {isLoading && (
        <Loader />
      )}

      <DataTable
        ref={dt}
        key="list"
        className="frozen-deffered"
        value={updatedFilingMasterList}
        emptyMessage='No records found'
        scrollable
        columnResizeMode="expand"
        resizableColumns
        showGridlines
        selectionMode="radiobutton"
        selection={selectedMasters}
        onSelectionChange={(e) => {
          handleRowChange(e)
        }}
        onRowToggle={(e) => rowExpand(e)}
        expandedRows={expandedRows}
        style={{ minHeight: "50vh" }}
        dataKey='filingId'
        onRowSelect={onRowSelect}
        rowExpansionTemplate={rowExpansionTemplate}
        // rows={10}
        // rowsPerPageOptions={[5, 10, 25, 50]}
        filters={filters}
        loading={isLoading}
        filterMode="multiple"
        globalFilterFields={["filingName"]}
        header={renderHeader()}
        virtualScrollerOptions={{
          onScroll: loadDataLazy,
          itemSize: 70,
          delay: 0,
          scrollHeight: "550px",
          appendOnly: true,
        }}
        scrollHeight="550px"
        onFilter={(e) => onFilterChange(e)}
        sortField={sortingData.sortBy}
        sortOrder={sortingData.sortOrder}
        onSort={(e) => handleSort(e)}
      >
        <Column expander={true} style={{ width: "5rem" }} />
        <Column selectionMode="single" headerStyle={{ width: "3rem" }}>
          Select
        </Column>

        {/* <Column
          field='filingId'
          header='Filing ID'
          style={{ minWidth: "7rem" }}
        ></Column> */}
        <Column
          field='filingName'
          header='Filing Name'
          frozen
          className="filingNameColumn"
          style={{ minWidth: "12rem", maxWidth: "25rem" }}
          body={(rowData) => cellListDisplayName(rowData)}
          sortable
          filter
          filterField="filingName"
          showAddButton={false}
          showFilterOperator={false}
          filterPlaceholder="Search by Filing Name"
          filterMatchModeOptions={[
            { value: FilterMatchMode.CONTAINS, label: "Contains" },
            { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
            { value: FilterMatchMode.EQUALS, label: "Equal" },
          ]}
        ></Column>
        {/* <Column
          field='filingDescription'
          header='Filing Description'
          style={{ minWidth: "15rem" }}
        ></Column> */}
        <Column
          field='filingFrequency'
          header='Filing Frequency'
          frozen
          style={{ minWidth: "12rem" }}
          filter
          filterField="filingFrequency"
          showAddButton={false}
          showFilterOperator={false}
          filterPlaceholder="Search by Filing Frequency"
          filterMatchModeOptions={[
            { value: FilterMatchMode.CONTAINS, label: "Contains" },
            { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
            { value: FilterMatchMode.EQUALS, label: "Equal" },
          ]}
        ></Column>
        {/* <Column
          field='dueDayofFrequency'
          header='Due Date Offset'
          style={{ minWidth: "15rem" }}
        ></Column> */}
        <Column
          field='stateList'
          header='State'
          frozen
          style={{ minWidth: "11rem" }}
          className='column-small'
          sortable
          filter
          filterField="stateList"
          showAddButton={false}
          showFilterOperator={false}
          filterPlaceholder="Search by State"
          filterMatchModeOptions={[
            { value: FilterMatchMode.CONTAINS, label: "Contains" },
            { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
            { value: FilterMatchMode.EQUALS, label: "Equal" },
          ]}
          filterElement={stateRowFilterTemplate}
          onFilterMatchModeChange={() => setMasterCatFilter([])}
          onFilterClear={() => setMasterCatFilter([])}
        ></Column>
        <Column
          field='ruleInfo'
          header='Rule Info'
          style={{ minWidth: "15rem", maxWidth: "15rem" }}
          body={(rowData) => cellListDisplayRuleInfo(rowData)}
          filter
          filterField="ruleInfo"
          showAddButton={false}
          showFilterOperator={false}
          filterPlaceholder="Search by Rule Info"
          filterMatchModeOptions={[
            { value: FilterMatchMode.CONTAINS, label: "Contains" },
            { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
            { value: FilterMatchMode.EQUALS, label: "Equal" },
          ]}
        ></Column>
        <Column
          field='federalCategories'
          header='Who Must File Federal'
          body={(rowData) =>
            CellListDisplayFederal(rowData, "federalCategories")
          }
          style={{ minWidth: "15rem", maxWidth: "15rem" }}
          filter
          filterField="federalCategories"
          showAddButton={false}
          showFilterOperator={false}
          filterPlaceholder="Search by Cateogry"
          filterMatchModeOptions={[
            { value: FilterMatchMode.IN, label: "Contains" },
            { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
            { value: FilterMatchMode.EQUALS, label: "Equal" },
          ]}
          filterElement={(options) =>
            masterCategoryFilter(options, "federalCategories")
          }
          onFilterClear={() => setMasterCatFilter([])}
          onFilterMatchModeChange={() => setMasterCatFilter([])}
        ></Column>
        <Column
          field='stateCategories'
          header='Who Must File in State'
          body={(rowData) =>
            CellListDisplayFederal(rowData, "stateCategories")
          }
          style={{ minWidth: "15rem", maxWidth: "15rem" }}
          filter
          filterField="stateCategories"
          showAddButton={false}
          showFilterOperator={false}
          filterPlaceholder="Search by Cateogry"
          filterMatchModeOptions={[
            { value: FilterMatchMode.IN, label: "Contains" },
            { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
            { value: FilterMatchMode.EQUALS, label: "Equal" },
          ]}
          filterElement={(options) =>
            masterCategoryFilter(options, "stateCategories")
          }
          onFilterClear={() => setMasterCatFilter([])}
          onFilterMatchModeChange={() => setMasterCatFilter([])}
        ></Column>
        <Column
          field='juristiction'
          header='Jurisdiction'
          style={{ minWidth: "14rem" }}
          sortable
          filter
          filterField="juristiction"
          showAddButton={false}
          showFilterOperator={false}
          filterPlaceholder="Search by Juristiction"
          showFilterMatchModes={false}
          filterElement={jurisdictionRowFilterTemplate}
          onFilterClear={() => setMasterCatFilter([])}
          onFilterMatchModeChange={() => setMasterCatFilter([])}
        ></Column>
        <Column
          field='required'
          header='Required'
          style={{ minWidth: "13rem" }}
          className='column-small'
          filter
          filterField="required"
          showAddButton={false}
          showFilterOperator={false}
          filterPlaceholder="Search by Required"
          showFilterMatchModes={false}
          filterElement={requiredRowFilterTemplate}
          onFilterClear={() => setMasterCatFilter([])}
          onFilterMatchModeChange={() => setMasterCatFilter([])}
        >
          Select
        </Column>
        {/* <Column
          field='jsidept'
          header='JSI Dept'
          style={{ minWidth: "14rem" }}
        ></Column> */}
        <Column
          field='jsicontactName'
          header='JSI Staff'
          style={{ minWidth: "14rem" }}
          filter
          filterField="jsicontactName"
          showAddButton={false}
          showFilterOperator={false}
          filterPlaceholder="Search by JSI Staff"
          filterMatchModeOptions={[
            { value: FilterMatchMode.CONTAINS, label: "Contains" },
            { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
            { value: FilterMatchMode.EQUALS, label: "Equal" },
          ]}
        ></Column>
        {/* <Column
          field='jsiContactEmail'
          header='JSI Contact Email'
          style={{ minWidth: "14rem" }}
        ></Column> */}
      </DataTable>
      <Dialog
        header={dialogTitle}
        visible={openDialog}
        style={{ width: "30vw" }}
        onHide={() => setOpenDialog(false)}
        footer={dialogFooter}
      >
        <p className='m-0'>{dialogMessage}</p>
      </Dialog>
    </Fragment>
  );
};

export default AddFilingTable;
