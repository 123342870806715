import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Toolbox from "./Toolbox";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ClientFilingTrackerTable from "./ClientFilingTrackerTable";
import ClientLookup from "../customer/ClientLookup";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import CustomerContext from "../../context/customer/CustomerContext";
import ClientFilingTrackerComments from "./ClientFilingTrackerComments";
import axios from "axios";
import ClientFilingTrackerAttachment from "./ClientFilingTrackerAttachments";
import ClientFilingTrackerHistory from "./ClientFilingTrackerHistory";
import ClientFilingTrackerDocuments from "./ClientFilingTrackerDocuments";
import useDialog from "../common/UseDialog";
import AuthContext from "../../context/auth/AuthContext";
import { Typography } from "@mui/material";
import ClientFilingBulkStatusUpdate from "./ClientFilingBulkStatusUpdate";
import ClientFilingConsolidatedUpdate from "./ClientFilingConsolidatedUpdate";
import states from "../../data/StateLookup.json";
import { MultiSelect } from 'primereact/multiselect';
import Stack from '@mui/material/Stack';

// import ClientSpecificFilingConsolidateView from "./ClientSpecificFilingConsolidateView";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function ClientFilingTrackerList({ onAddClientSpecificFilingClick }) {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [showBulkStatusDrawer, setShowBulkStatusDrawer] = useState(false)
  const [bulkUpdateRecords, setBulkUpdateRecords] = useState([])
  const [commonStaffs, setCommonStaffs] = useState([])
  let { clientId } = useParams();
  const [selectedClientId, setSelectedClientId] = useState(clientId);
  // const [selectedClientFiling, setSelectedClientFiling] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [radioMessage, setRadioMessage] = useState("Which Defered Filing you want to open");
  const [radioVal, setRadioVal] = React.useState('tracknow');
  const [showAlert, setShowAlert] = useState(false);
  const [selectedFiling, setSelectedFiling] = useState([]);
  const [underDetailedViewProps, setUnderDetailedViewProps] = useState(false)
  const [tablValue, setTablValue] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [clientFilingData, setClientFilingData] = useState(null);
  const [showRadio, setShowRadio] = useState(false);
  const [showRadio1, setShowRadio1] = useState(false);
  const [showRadioEdit, setShowRadioEdit] = useState(false);
  const [radioMessage1, setRadioMessage1] = useState("Which record type do you want to create?");
  const [radioVal1, setRadioVal1] = React.useState('tracknow');
  const [showComments, setShowComments] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showConsolidated, setShowConsolidated] = useState(false);
  const [showClientSpecificConsolidated, setShowClientSpecificConsolidated] = useState(false);
  const [documents, setDocuments] = React.useState([]);
  const [consolidatedRecords, setConsolidatedRecords] = React.useState([]);
  const [affectedCompanyLists, setAffectedCompanyLists] = React.useState([]);
  const [affectedCompanyListsDropdown, setAffectedCompanyListsDropdown] = React.useState([]);

  const [selectedAffectedCompanies, setSelectedAffectedCompanies] = React.useState([]);
  const [customerNames, setCustomerNames] = React.useState([])
  const [selectedAffectedCompaniesEdit, setSelectedAffectedCompaniesEdit] = React.useState([]);
  const [selectedAffectedCompaniesEditInitial, setSelectedAffectedCompaniesEditInitial] = React.useState([]);
  const [selectedAffectedCompaniesEditInitialNames, setSelectedAffectedCompaniesEditInitialNames] = React.useState([]);
  const [consolidatedClientFilingList, setConsolidatedClientFilingList] = React.useState([])
  const [consolidatedStatus, setConsolidatedRecordsStatus] = React.useState([]);
  const [staffList, setStaffList] = React.useState([])
  const [detailedViewVal, setDetailedViewVal] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [activityHistory, setActivityHistory] = React.useState([]);
  const [clientActivityHistory, setClientActivityHistory] = React.useState([]);
  const [commentActivityHistory, setCommentActivityHistory] = React.useState([]);
  const [isTnistoryLoading, setIsTnHistoryLoading] = useState(true)
  const [isCfHistoryLoading, setIsCfHistoryLoading] = useState(true)
  const [isCommentsHistoryLoading, setIsCommentsHistoryLoading] = useState(true)
  const [downloadData, setDownloadData] = React.useState([]);
  const [downloadDataHistory, setDownloadDataHistory] = React.useState([]);
  const [comments, setComments] = React.useState([]);
  const [attachments, setAttachments] = React.useState([]);
  const [refreshList, setRefreshList] = React.useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [isTrackNowAccess, setIsTrackNowAccess] = useState(false)
  const [isPostAwardAccess, setIsPostAwardAccess] = useState(false)
  const [sortRecord, setSortRecord] = useState("Upcoming Due Dates");
  const [keepSelectedRecords, setKeepSelectedRecords] = useState([]);
  const [keepPageDetails, setKeepPageDetails] = useState(false);
  const [isDeleteClientFiling, setIsDeleteClientFiling] = useState(false)
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const { getClientFilingTrackerComments, getClientFilingTrackerAttachments } =
    clientFilingMasterContext;


  const customerContext = useContext(CustomerContext);
  const { getCustomers, customers, selectedCustomer } = customerContext;

  const filingMasterContext = useContext(FilingMasterContext);
  const {
    setCurrentFilingMaster,
    deleteDraftFilingMaster,
    createFilingMasterWorkflow,
  } = filingMasterContext;

  const authContext = useContext(AuthContext);
  const { roleType, authUser } = authContext;


  const handleRefreshTable = () => {
    // console.log("handleRefreshTable")
    setRefreshTable(true);
  };
  const handleRefreshTableReturn = () => {
    setRefreshTable(false);
  };

  const refreshSelectedRecord = () => {
    setKeepSelectedRecords(selectedFiling)
    setSelectedFiling([])
    handleRefreshTable()
  }

  const displayFilter = () => {
    setShowFilter(() => true);
  };

  const closeStatusBulkUpdateDrawer = () => {
    setBulkUpdateRecords([])
    setShowBulkStatusDrawer(() => false);
  };

  const displayAddClientFiling = () => {
    // console.log("displayAddClientFiling selectedCustomer", selectedCustomer);
    if (selectedClientId) {
      if ((Number(selectedCustomer?.postAwardLicenseId) === 0 || selectedCustomer?.postAwardLicenseId === null || selectedCustomer?.postAwardLicenseId === "") && (selectedCustomer?.trackNowLicenseId && Number(selectedCustomer?.trackNowLicenseId) > 0)) {
        //If client has only Tracknow subscription
        navigate(`/add-filings/${selectedClientId}`);

      } else if ((Number(selectedCustomer?.trackNowLicenseId) === 0 || selectedCustomer?.trackNowLicenseId === null || selectedCustomer?.trackNowLicenseId === "") && (selectedCustomer?.postAwardLicenseId && Number(selectedCustomer?.postAwardLicenseId) > 0)) {
        //If client has only Postaward subscription
        navigate(`/add-filing-postaward/${selectedClientId}`);
      } else if ((selectedCustomer?.trackNowLicenseId && Number(selectedCustomer?.trackNowLicenseId) > 0) && (selectedCustomer?.postAwardLicenseId && Number(selectedCustomer?.postAwardLicenseId) > 0)) {
        //If client has both Tracknow and  Postaward subscription
        setShowRadio(true);
      } else {
        setAlertMessage("Client doesn't have either tracknow or postaward subscriptions.");
        setShowAlert(true);
      }
    } else {
      setAlertMessage("Please Select a Client Record");
      setShowAlert(true);
    }
  };

  const fetchCustomerList = async () => {
    //  if (getCustomers) {
    let custList;
    if (customers) {
      custList = customers

    } else {
      custList = await getCustomers();
    }
    //let custList = await getCustomers();
    let client = custList.filter(
      (ele) => ele.customerId === parseInt(selectedClientId)
    )[0];
    setCurrentFilingMaster({
      ...selectedFiling[0],
      customerId: selectedClientId,
      client: client.customerName,
      from: "Add client filing",
    })

    // };
  }

  const editFiling = async () => {
    try {
      if (selectedClientId && selectedFiling && selectedFiling.length !== 0) {
        if (selectedClientId && selectedFiling && selectedFiling.length > 1) {
          setAlertMessage("Please Select single Client specific Filing ");
          setShowAlert(true);
        } else {
          if (!selectedFiling[0].isMasterFiling) {
            let detalis = await trackerTable.current.refreshPage()
            console.log("editFiling detalis", detalis);

            // if (getCustomers) {
            try {
              let custList;
              if (customers) {
                custList = customers
              } else {
                custList = await getCustomers();
              }
              //  let custList = await getCustomers();
              let client = custList.filter(
                (ele) => ele.customerId === parseInt(selectedClientId)
              )[0];
              setCurrentFilingMaster({
                ...selectedFiling[0],
                juristiction: selectedFiling[0]?.filingJuristiction,
                stateInfo: selectedFiling[0]?.filingStateInfo,
                customerId: selectedClientId,
                client: client.customerName,
                from: "Edit client filing",

              });
              console.log("editFiling", "selectedAffectedCompanies", selectedAffectedCompanies, selectedAffectedCompaniesEdit);
              if (selectedFiling[0]?.recordType === "TrackNow") {
                onAddClientSpecificFilingClick(true)

                navigate("/filing-master", { state: { selectedAffectedCompanies, selectedAffectedCompaniesEdit, customerNames } });
              }
              else {

                onAddClientSpecificFilingClick(true)

                navigate("/postaward-master-form", { state: { selectedAffectedCompanies, selectedAffectedCompaniesEdit, customerNames } });
              }
            } catch (error) {
            }
          } else {
            setAlertMessage("Please Select a Client specific Filing to edit ");
            setShowAlert(true);
          }
        }
        //}
      } else {
        setAlertMessage("Please Select a Client specific Filing ");
        setShowAlert(true);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setSelectedAffectedCompanies([])
      setSelectedAffectedCompaniesEdit([])
      setCustomerNames([])
      setSelectedAffectedCompaniesEditInitial([])
      setSelectedAffectedCompaniesEditInitialNames([])
    }
  };

  const addMasterFilling = async () => {
    console.log("addMasterFilling", "selectedCustomer", selectedCustomer, "isTrackNowAccess", isTrackNowAccess, "isPostAwardAccess", isPostAwardAccess, "roleType", roleType, affectedCompanyLists);
    if (selectedClientId) {

      // if (getCustomers) {
      try {
        // let custList = await getCustomers();
        // let client = custList.filter(
        //   (ele) => ele.customerId === parseInt(selectedClientId)
        // )[0];
        // setCurrentFilingMaster({
        //   customerId: selectedClientId,
        //   client: client.customerName,
        //   from: "Add client filing",
        // });
        const list = await fetchAffectedCompanies()
        if (selectedCustomer && ((selectedCustomer.parentCustomer !== 0 && selectedCustomer.parentCustomer !== null) || selectedCustomer?.isOnlyParentFile)) {
          //if customer is child 
          if (selectedCustomer.trackNowLicenseId > 0 && selectedCustomer.postAwardLicenseId > 0) {
            //if child customer has both subscription
            setShowRadio1(true)
          }
          else if (selectedCustomer.trackNowLicenseId > 0 && (selectedCustomer.postAwardLicenseId === 0 || selectedCustomer.postAwardLicenseId === null)) {
            //if child customer has only TN subscription
            navigate("/filing-master", { state: { selectedAffectedCompanies, customerNames } });
            fetchCustomerList()
            onAddClientSpecificFilingClick(true)
          } else {
            //If child customer has PA subscription
            navigate("/postaward-master-form", { state: { selectedAffectedCompanies, customerNames } });
            fetchCustomerList()
            onAddClientSpecificFilingClick(true)
          }
        } else {
          //If customer is parent 
          setShowRadio1(true)
        }
      } catch (error) {
      }
      //}
    } else {
      setAlertMessage("Please Select a Client Record");
      setShowAlert(true);
    }
  };
  const editMasterFilling = async () => {
    console.log("editMasterFilling", "selectedCustomer", selectedCustomer, "selectedFiling", selectedFiling);
    if (selectedClientId && selectedFiling && selectedFiling.length !== 0) {
      if (selectedClientId && selectedFiling && selectedFiling.length > 1) {
        setAlertMessage("Please Select single Client specific Filing ");
        setShowAlert(true);
      } else {
        if (!selectedFiling[0].isMasterFiling) {
          console.log("editMasterFilling", "consRecords", selectedFiling[0]);

          const list = await fetchAffectedCompanies()
          if (selectedCustomer.parentCustomer === null && !selectedCustomer.isOnlyParentFile) {
            const listConst = await axios.get(

              `${API_BASE_URL}/Customer/CustomerFileTrackingConsolidate?CustomerId=${selectedFiling[0].customerId}&FilingId=${selectedFiling[0].filingId}&Startdate=${selectedFiling[0].startdate}`
            );
            console.log("editMasterFilling list", list, "consRecords", listConst.data.data);
            const constRecords = listConst.data.data
            const exsistingChildCompany = list.map(item => {
              const matchingRecords = constRecords.find(cust => cust.customerId === item.customerId)
              console.log(",matchingRecords", matchingRecords, "item", item);
              if (matchingRecords) {
                selectedAffectedCompanies.push(matchingRecords.customerId)
                selectedAffectedCompaniesEdit.push(matchingRecords.customerId)
                selectedAffectedCompaniesEditInitial.push(matchingRecords.customerId)//prev customerId
                customerNames.push({ customerId: matchingRecords.customerId, customerName: matchingRecords.customerName })//prev customername 
                selectedAffectedCompaniesEditInitialNames.push({ customerId: matchingRecords.customerId, customerName: matchingRecords.customerName })//prev customername 
              }
              return {
                ...item,
                isDisabled: matchingRecords ? true : false,
              }

            })
            constRecords.forEach(record => {
              if (record.customerId === selectedCustomer.customerId) {
                if (!customerNames.some(cust => cust.customerName === record.customerName)) {
                  console.log("record ", record, "customerNames", customerNames, "constRecords", constRecords);
                  customerNames.push({ customerId: record.customerId, customerName: record.customerName });
                }
              }
            });

            setAffectedCompanyLists(exsistingChildCompany)
            setShowRadioEdit(true)
            // editFiling()
          } else {
            editFiling()
          }
        } else {
          setAlertMessage("Please Select a Client specific Filing to edit ");
          setShowAlert(true);
        }
      }
      //}
    } else {
      setAlertMessage("Please Select a Client specific Filing ");
      setShowAlert(true);
    }

  };
  console.log("affectedCompanyLists", affectedCompanyLists);
  const displayClientFiling = () => {
    navigate("/");
  };

  // const getAttachment = async (fileTrackingId, commentsId) => {
  //   console.log(
  //     "getAttachment",
  //     selectedFiling.customerId,
  //     fileTrackingId,
  //     commentsId
  //   );
  //   const res = await axios.get(
  //     `${API_BASE_URL}/Customer/CustomerFilingTrackingCommentsAttachments?customerId=${selectedFiling.customerId}&fileTrackingId=${fileTrackingId}&commentsId=${commentsId}`
  //   );
  //   console.log(
  //     "updateFilePath res",
  //     res.data.data,
  //     res.data.data[0].attachmentPath
  //   );
  //   if (res.data) {
  //     return res.data.data[0].attachmentPath;
  //   }
  // };
  const getAttachment = async (customerId, fileTrackingId, commentsId) => {
    const res = await axios.get(
      `${API_BASE_URL}/Customer/CustomerFilingTrackingCommentsAttachments?customerId=${customerId}&fileTrackingId=${fileTrackingId}&commentsId=${commentsId}`
    );

    if (res.data) {
      return {
        attachmentPath: res.data.data[0].attachmentPath,
        attachmentId: res.data.data[0].attachmentId,
      };
    }
  };

  // const fetchClientFilingTrackerComments = async (fileTrackingId) => {
  //   console.log("@@Fetch FilingMasters1:", getClientFilingTrackerComments);
  //   if (getClientFilingTrackerComments) {
  //     console.log("@@Fetch FilingMasters2:");
  //     try {
  //       let commentList = await getClientFilingTrackerComments(fileTrackingId);
  //       commentList = await Promise.all(
  //         commentList.map(async (ele) => {
  //           console.log("commentList", ele);
  //           ele.attachmentPath = await getAttachment(
  //             ele.fileTrackingId,
  //             ele.commentsId
  //           );
  //           return ele;
  //         })
  //       );
  //       setComments(() => commentList);
  //       console.log("@@Fetch FilingMasters2 comments:", commentList);
  //     } catch (error) {
  //       console.log("@@Error:", error);
  //     }
  //   }
  // };

  const fetchClientFilingTrackerComments = async (
    fileTrackingId,
    customerId
  ) => {
    if (getClientFilingTrackerComments) {
      try {
        let commentList = await getClientFilingTrackerComments(fileTrackingId);
        commentList = await Promise.all(
          commentList.map(async (ele) => {
            const { attachmentPath, attachmentId } = await getAttachment(
              customerId || selectedFiling.customerId,
              ele.fileTrackingId,
              ele.commentsId
            );
            ele.attachmentPath = attachmentPath;
            ele.attachmentId = attachmentId;
            return ele;
          })
        );
        setComments(() => commentList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };
  // const addComment = (rowData) => {
  //   console.log("Display Comments", selectedFiling);
  //   console.log("Fetching Comments", selectedFiling);
  //   if (rowData && rowData.fileTrackingId) {
  //     console.log(`Fetching Commnet for :${rowData.fileTrackingId}`);
  //     fetchClientFilingTrackerComments(rowData.fileTrackingId);
  //     setShowComments(() => true);
  //     //setSelectedFiling(rowData);
  //   } else if (selectedFiling && selectedFiling.fileTrackingId) {
  //     console.log(`Fetching Commnet for :${selectedFiling.fileTrackingId}`);
  //     fetchClientFilingTrackerComments(selectedFiling.fileTrackingId);

  //     setShowComments(() => true);
  //   } else {
  //     setAlertMessage("Please Select a Filing Tracker Record");
  //     setShowAlert(true);
  //   }
  // };

  const addComment = (rowData) => {
    console.log("Indicator Func addComment rowwdata", rowData);
    if (rowData && rowData.fileTrackingId) {
      fetchClientFilingTrackerComments(
        rowData.fileTrackingId,
        rowData.customerId
      );
      setShowComments(() => true);
      // setSelectedFiling(rowData);
    } else if (selectedFiling && selectedFiling[0]?.fileTrackingId) {
      if (selectedFiling.length > 1) {
        setAlertMessage("Please Select single Filing Tracker Record");
        setShowAlert(true);
      } else {

        fetchClientFilingTrackerComments(
          selectedFiling[0]?.fileTrackingId,
          selectedFiling[0]?.customerId
        );
        setShowComments(() => true);
      }

    } else {
      setAlertMessage("Please Select a Filing Tracker Record");
      setShowAlert(true);
    }
  };

  const fetchClientFilingTrackerAttachments = async (fileTrackingId) => {
    if (getClientFilingTrackerAttachments) {
      try {
        const attachmentList = await getClientFilingTrackerAttachments(
          fileTrackingId
        );
        setAttachments(() => attachmentList);
      } catch (error) {
      }
    }
  };

  const displayAttachments = async (rowData) => {
    console.log("Indicator Func displayAttachments rowwdata", rowData);
    if (rowData && rowData.fileTrackingId) {
      fetchClientFilingTrackerAttachments(rowData.fileTrackingId);
      setShowAttachments(() => true);
    } else if (selectedFiling && selectedFiling[0]?.fileTrackingId) {
      if (selectedFiling.length > 1) {
        setAlertMessage("Please Select single Filing Tracker Record");
        setShowAlert(true);
      } else {
        fetchClientFilingTrackerAttachments(selectedFiling[0]?.fileTrackingId);
        setShowAttachments(() => true);
      };
    } else {
      setAlertMessage("Please Select a Filing Tracker Record");
      setShowAlert(true);
    }
  };

  const fetchTrackerActive = async () => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}/Customer/CustomerFileTrackingbyCustomerId?CustomerId=${selectedClientId}`
      );
      console.log("fetchTrackerActive res", res);
      return res.data.data; // Assuming the response contains the data you need
    } catch (error) {
      console.error("Error fetching tracker data:", error);
      throw error; // Propagate the error
    }
  };
  const fetchTrackerHistory = async () => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}/Customer/CustomerFileTrackingbyCustomerId?CustomerId=${selectedClientId}&Year=${selectedYear}`
      );
      // console.log("fetchTrackerHistory res", res);
      return res.data.data; // Assuming the response contains the data you need
    } catch (error) {
      console.error("Error fetching tracker data:", error);
      throw error; // Propagate the error
    }
  };
  const getStateName = (stateCode) => {
    const state = states.find((s) => s.code === stateCode);
    return state ? state.state : stateCode;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  const exportExcel = async (data) => {
    const resList = await fetchTrackerActive()

    if (resList.length === 0) {
      setAlertMessage("No data to download");
      setShowAlert(true);
      return;
    }
    try {
      // console.log("exportExcel", "resList", resList, "downloadData", downloadData, "selectedCustomer", selectedCustomer);

      const filteredData = resList.map(


        ({
          fileTrackingId,
          filingId,
          updateUser,
          updateDate,
          createUser,
          filingDescription,
          jsidept,
          jsicontactName,
          jsicontactEmail,
          hasComments,
          hasAttachments,
          createDate,
          dueDateFormatted,
          dueDateUTC,
          stateList,
          staffAssignedArray,
          isMasterFiling,
          startdate,
          enddate,
          hasReference,

          ...rest
        }) => rest
      );


      import("xlsx").then((xlsx) => {
        // console.log("exportExcel", "filteredData ", filteredData, "downloadData".downloadData);

        const renamedData = filteredData.map((item) => {
          // const formattedAffectedCompany = item.formattedAffectedCompany.map((company) => {
          //   if (item.parentAffectedCompany && item.parentAffectedCompany.customerName === company) {
          //     return `${company} (Parent)`;
          //   }
          //   return company;
          // });

          // const formattedAffectedCompany = item.affectedCompanies && item.affectedCompanies.map((company) => {
          //   if (company.type === "Parent") {
          //     return `${company.customerName} - (${company.type})`;
          //   }
          //   return company.customerName;
          // });
          // console.log("exportExcel", "formattedAffectedCompany ", formattedAffectedCompany);

          //Grant Term Complete
          const grantTermComplete =
            item.recordType === "TrackNow" ? "" :
              item.grantTermComplete === "Yes" || item.grantTermComplete === true ? "Yes" : "No";
          //State 
          const stateMod = getStateName(item.filingStateInfo)

          //Filing Period
          const formattedFilingPeriod = `${formatDate(item.filingPeriods.startdate)} - ${formatDate(item.filingPeriods.enddate)}`;

          //Filing Due Date
          const filingDueDateMod = item.filingDueDate ? formatDate(item.filingDueDate) : "";

          //Internal Due Date
          // const internalDueDate = item.dueDate ? formatDate(item.dueDate) : "";

          //Staff Assigned
          const staffAssigned = item.staffAssigned ? item.staffAssigned.map(staff => {
            const capitalizedUserName = staff.userName.charAt(0).toUpperCase() + staff.userName.slice(1);
            // if (staff.company === "Parent") {
            //   return `${capitalizedUserName} - ${staff.company}`;
            // }
            return capitalizedUserName;
          }).join(', ') : '';


          const awardTypeName =
            item.awardType && item.awardType.length > 0
              ? item.awardType[0].awardTypeName
              : '';

          const awardingAgencyName =
            item.awardingAgency && item.awardingAgency.length > 0
              ? item.awardingAgency[0].awardingAgencyName
              : '';
          const programName =
            item.programInfo && item.programInfo.length > 0
              ? item.programInfo[0].programName
              : '';

          let whoMustFileFederalMod = '';
          let whoMustFileinStateMod = '';

          if (item.filingJuristiction === "Federal") {
            if (item.recordType === "TrackNow") {
              whoMustFileFederalMod = item.businessCategory.map(obj => obj.businessCategoryName).join(', ');
            } else if (item.recordType === "PostAward") {
              whoMustFileFederalMod = programName;
            }
          } else if (item.filingJuristiction === "State") {
            if (item.recordType === "TrackNow") {
              const filteredCategories = item.businessCategory.filter(category => category.state === item.filingStateInfo);
              whoMustFileinStateMod = filteredCategories.map(obj => obj.businessCategoryName).join(', ');
            } else if (item.recordType === "PostAward") {
              whoMustFileinStateMod = programName;
            }
          }

          return {
            "Filing Name": item.filingName,
            "Client Name": item.customerName,
            // ...(selectedCustomer.postAwardLicenseId > 0 && { "Internal Due Date": internalDueDate }),
            "Filing Due Date": filingDueDateMod,
            ...(selectedCustomer.postAwardLicenseId > 0 && { "Filing Period": formattedFilingPeriod, }),
            "Status": item.status,
            ...(selectedCustomer.postAwardLicenseId > 0 && { "Grant Term Complete": grantTermComplete, }),
            "Staff Assigned": staffAssigned,
            "Filing Frequency": item.filingFrequency,
            "State": stateMod,
            // "Affected Companies": formattedAffectedCompany.join(", "),
            ...(selectedCustomer.trackNowLicenseId > 0 && { "Rule Info": item.ruleInfo, }),
            "Jurisdiction": item.filingJuristiction,
            ...(selectedCustomer.trackNowLicenseId > 0 && { "Required": item.filingRequired ? "True" : "False", }),
            ...(selectedCustomer.postAwardLicenseId > 0 && { "Award Type": awardTypeName, }),
            ...(selectedCustomer.postAwardLicenseId > 0 && { "Award Agency": awardingAgencyName, }),
            ...(selectedCustomer.trackNowLicenseId > 0 && { "Who Must File Federal": whoMustFileFederalMod, }),
            ...(selectedCustomer.trackNowLicenseId > 0 && { "Who Must File in State": whoMustFileinStateMod, }),
            "Record Type": item.recordType,
            "Where to file": item.whereToFile,
            "Notes": item.notes,
          };
        });

        // console.log("exportExcel", "renamedData ", renamedData);

        const worksheet = xlsx.utils.json_to_sheet(renamedData);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "tracknowsheet");
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };
  const exportExcelHistory = async (data) => {
    const resList = await fetchTrackerHistory()
    if (resList.length === 0) {
      setAlertMessage("No data to download");
      setShowAlert(true);
      return;
    }



    const filteredData = resList.map(

      ({
        fileTrackingId,
        filingId,
        updateUser,
        updateDate,
        createUser,
        filingDescription,
        jsidept,
        jsicontactName,
        jsicontactEmail,
        hasComments,
        hasAttachments,
        createDate,
        dueDateFormatted,
        dueDateUTC,
        stateList,
        staffAssignedArray,
        isMasterFiling,
        startdate,
        enddate,
        hasReference,
        ...rest
      }) => rest
    );


    import("xlsx").then((xlsx) => {
      const renamedData = filteredData.map((item) => {
        // const formattedAffectedCompany = item.affectedCompanies && item.affectedCompanies.map((company) => {
        //   if (company.type === "Parent") {
        //     return `${company.customerName} - (${company.type})`;
        //   }
        //   return company.customerName;
        // });
        // console.log("exportExcelHistory", "resList", resList, "formattedAffectedCompany", formattedAffectedCompany);
        //Grant Term Complete
        const grantTermComplete =
          item.recordType === "TrackNow" ? "" :
            item.grantTermComplete === "Yes" || item.grantTermComplete === true ? "Yes" : "No";
        //State 
        const stateMod = getStateName(item.filingStateInfo)

        //Filing Period
        const formattedFilingPeriod = `${formatDate(item.filingPeriods.startdate)} - ${formatDate(item.filingPeriods.enddate)}`;

        //Filing Due Date
        const filingDueDateMod = item.filingDueDate ? formatDate(item.filingDueDate) : "";

        //Internal Due Date
        // const internalDueDate = item.dueDate ? formatDate(item.dueDate) : "";

        //Staff Assigned
        const staffAssigned = item.staffAssigned ? item.staffAssigned.map(staff => {
          const capitalizedUserName = staff.userName.charAt(0).toUpperCase() + staff.userName.slice(1);
          // if (staff.company === "Parent") {
          //   return `${capitalizedUserName} - ${staff.company}`;
          // }
          return capitalizedUserName;
        }).join(', ') : '';


        const awardTypeName =
          item.awardType && item.awardType.length > 0
            ? item.awardType[0].awardTypeName
            : '';

        const awardingAgencyName =
          item.awardingAgency && item.awardingAgency.length > 0
            ? item.awardingAgency[0].awardingAgencyName
            : '';
        const programName =
          item.programInfo && item.programInfo.length > 0
            ? item.programInfo[0].programName
            : '';

        let whoMustFileFederalMod = '';
        let whoMustFileinStateMod = '';

        if (item.filingJuristiction === "Federal") {
          if (item.recordType === "TrackNow") {
            whoMustFileFederalMod = item.businessCategory.map(obj => obj.businessCategoryName).join(', ');
          } else if (item.recordType === "PostAward") {
            whoMustFileFederalMod = programName;
          }
        } else if (item.filingJuristiction === "State") {
          if (item.recordType === "TrackNow") {
            const filteredCategories = item.businessCategory.filter(category => category.state === item.filingStateInfo);
            whoMustFileinStateMod = filteredCategories.map(obj => obj.businessCategoryName).join(', ');
          } else if (item.recordType === "PostAward") {
            whoMustFileinStateMod = programName;
          }
        }
        return {
          "Filing Name": item.filingName,
          "Client Name": item.customerName,
          // ...(selectedCustomer.postAwardLicenseId > 0 && { "Internal Due Date": internalDueDate }),
          "Filing Due Date": filingDueDateMod,
          ...(selectedCustomer.postAwardLicenseId > 0 && { "Filing Period": formattedFilingPeriod, }),
          "Status": item.status,
          ...(selectedCustomer.postAwardLicenseId > 0 && { "Grant Term Complete": grantTermComplete, }),
          "Staff Assigned": staffAssigned,
          "Filing Frequency": item.filingFrequency,
          "State": stateMod,
          // "Affected Companies": formattedAffectedCompany.join(", "),
          ...(selectedCustomer.trackNowLicenseId > 0 && { "Rule Info": item.ruleInfo, }),
          "Jurisdiction": item.filingJuristiction,
          ...(selectedCustomer.trackNowLicenseId > 0 && { "Required": item.filingRequired ? "True" : "False", }),
          ...(selectedCustomer.postAwardLicenseId > 0 && { "Award Type": awardTypeName, }),
          ...(selectedCustomer.postAwardLicenseId > 0 && { "Award Agency": awardingAgencyName, }),
          ...(selectedCustomer.trackNowLicenseId > 0 && { "Who Must File Federal": whoMustFileFederalMod, }),
          ...(selectedCustomer.trackNowLicenseId > 0 && { "Who Must File in State": whoMustFileinStateMod, }),
          "Record Type": item.recordType,
          "Where to file": item.whereToFile,
          "Notes": item.notes,
        }
      });
      console.log("Download HISTORY AFT FILT ", renamedData);

      const worksheet = xlsx.utils.json_to_sheet(renamedData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFileHistory(excelBuffer, "tracknowsheet");
    });
  };

  const saveAsExcelFileHistory = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const displayDocuments = async (rowData) => {
    console.log("Indicator Func displayDocuments rowwdata", rowData);
    if (rowData && rowData.filingId) {
      fetchFilingMasterDocuments(rowData.filingId);
      setShowDocuments(() => true);
    } else if (selectedFiling && selectedFiling[0]?.customerId) {
      if (selectedFiling.length > 1) {
        setAlertMessage("Please Select single Filing Tracker Record");
        setShowAlert(true);
      } else {
        fetchFilingMasterDocuments(selectedFiling[0]?.filingId);
        setShowDocuments(() => true);
      };
    } else {
      setAlertMessage("Please Select a Filing Tracker Record");
      setShowAlert(true);
    }
  };
  const displayConsolidatedView = async (rowData) => {

    console.log("displayConsolidatedView rowData", rowData);
    console.log("displayConsolidatedView selectedFiling", selectedFiling);

    if (selectedFiling && selectedFiling[0]?.customerId) {
      if (selectedFiling.length > 1) {
        setAlertMessage("Please Select single Filing Tracker Record");
        setShowAlert(true);
      } else {

        console.log("selectedFiling[0]");
        if (selectedFiling[0].isUnderParent) {
          // under parent view

          // if (selectedFiling[0].isOnlyParentFile && !selectedFiling[0].isOnlyChildFile) {
          //   //Filing got affected only in parent company not any child 
          //   setAlertMessage("There is no affected child company for the selected filing");
          //   setShowAlert(true);
          // } else {

          // Filing got affected only in child company and  both parent and child company 
          fetchConsolidatedRecords(selectedFiling[0]);
          setShowConsolidated(() => true);

          // }
        } else {
          // under Single company or child view
          setAlertMessage("Under this client, there is no affected child company.");
          setShowAlert(true);
        }
      };
    } else {
      setAlertMessage("Please Select a Filing Tracker Record");
      setShowAlert(true);
    }
  };
  // const displayClientSpecificConsolidatedView = async (rowData) => {

  //   console.log("displayClientSpecificConsolidatedView rowData", rowData);
  //   console.log("displayClientSpecificConsolidatedView selectedFiling", selectedFiling);

  //   if (selectedFiling && selectedFiling[0]?.customerId) {
  //     if (selectedFiling.length > 1) {
  //       setAlertMessage("Please Select single Filing Tracker Record");
  //       setShowAlert(true);
  //     } else {
  //       if (!selectedFiling[0].isMasterFiling) {

  //         if (selectedFiling[0].isUnderParent) {
  //           // under parent view
  //           fetchAffectedCompanies(selectedFiling[0]);
  //           setShowClientSpecificConsolidated(() => true);
  //         } else {
  //           // under Single company or child view
  //           setAlertMessage("Under this client, there is no affected child company.");
  //           setShowAlert(true);
  //         }
  //         //master filing record check

  //       } else {
  //         setAlertMessage("Please select a client specific filing");
  //         setShowAlert(true);
  //       }
  //     };
  //   } else {
  //     setAlertMessage("Please Select a Filing Tracker Record");
  //     setShowAlert(true);
  //   }
  // };

  const fetchFilingMasterDocuments = async (filingId) => {
    try {
      const attachmentList = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAttachments/${filingId}`
      );
      setDocuments(() => attachmentList.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };
  const fetchConsolidatedRecords = async (selectedFiling) => {
    console.log("fetchConsolidatedRecords filingId", selectedFiling);
    try {
      const consRecords = await axios.get(

        `${API_BASE_URL}/Customer/CustomerFileTrackingConsolidate?CustomerId=${selectedFiling.customerId}&FilingId=${selectedFiling.filingId}&Startdate=${selectedFiling.startdate}`
      );
      const consStatus = await axios.get(

        `${API_BASE_URL}/Customer/ConsolidateStatusForTracker?CustomerId=${selectedFiling.customerId}&FilingId=${selectedFiling.filingId}&Startdate=${selectedFiling.startdate}`
      );
      console.log("fetchConsolidatedRecords consRecords", consRecords, "consStatus", consStatus);
      setConsolidatedRecords(() => consRecords.data.data);
      setConsolidatedRecordsStatus(() => consStatus.data.data)
    } catch (error) {
      console.log("@@Error:", error);
    }
  };
  const fetchAffectedCompanies = async () => {
    console.log("fetchAffectedCompanies filingId", selectedFiling);
    try {
      const list = await axios.get(
        `${API_BASE_URL}/Customer/CustomerListBasedOnParentId?CustomerId=${selectedClientId}`
      );
      console.log("fetchAffectedCompanies list", list);
      //const filteredList = list.data.data.filter(item => item.parentCustomerId !== 0);

      setAffectedCompanyLists(list.data.data); // Assuming setAffectedCompanyLists is a state setter
      return list.data.data; // Returning the fetched data
    } catch (error) {
      console.log("@@Error:", error);
      throw error; // Re-throwing the error for potential handling elsewhere
    }
  };
  const fetchClientTrackingHistory = async (fileTrackingId) => {

    try {
      const history = await axios.get(
        `${API_BASE_URL}/Customer/customerfilingtrackingaudit?FileTrackingID=${fileTrackingId}`
      );
      setActivityHistory(() => history.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    } finally {
      setIsTnHistoryLoading(false)
    }

    try {
      const history = await axios.get(
        `${API_BASE_URL}/Customer/CustomerFilingMasterAudit${selectedFiling[0].customerId}?FilingId=${selectedFiling[0].filingId}`
      );
      setClientActivityHistory(() => history.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    } finally {
      setIsCfHistoryLoading(false)
    }

    try {
      let payload = [{
        customerId: selectedFiling[0].customerId,
        filingId: selectedFiling[0].filingId,
        startDate: selectedFiling[0].filingPeriods.startdate,

      }]
      // console.log("fetchClientTrackingHistory ", "payload", payload);
      const res = await axios.post(
        `${API_BASE_URL}/Customer/CustomerandFilingListBasedOnParentdetails`,
        payload
      )
      const fileTrackingIds = res.data.data.map(item => item.fileTrackingId).join(",")
      // console.log("fetchClientTrackingHistory ", "res", res, "fileTrackingIds", fileTrackingIds);

      const history = await axios.get(
        `${API_BASE_URL}/Customer/customerfilingtrackingcommentaudit?FileTrackingId=${fileTrackingIds}`
      );
      setCommentActivityHistory(() => history.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    } finally {
      setIsCommentsHistoryLoading(false)
    }
  };

  const displayHistory = async () => {
    if (selectedFiling && selectedFiling[0]?.fileTrackingId) {
      if (selectedFiling && selectedFiling.length > 1) {
        setAlertMessage("Please Select single Filing Tracker Record");
        setShowAlert(true);
      } else {
        fetchClientTrackingHistory(selectedFiling[0]?.fileTrackingId);
        setShowHistory(() => true);
      }
    } else {
      setAlertMessage("Please Select a Filing Tracker Record");
      setShowAlert(true);
    }
  };
  const updateBulkStatus = async () => {
    if (selectedFiling && selectedFiling[0]?.fileTrackingId) {


      if (selectedFiling && selectedFiling.length > 1) {

        if (!detailedViewVal) {
          let payload1 = selectedFiling.map((ele) => {
            return {
              customerId: ele.customerId,
              filingId: ele.filingId,
              startDate: ele.filingPeriods.startdate,
            }
          })
          console.log("updateBulkStatus payload1", payload1, selectedFiling);
          const res = await axios.post(
            `${API_BASE_URL}/Customer/CustomerandFilingListBasedOnParentdetailsandCommonstaffs`,
            payload1
          )
          console.log("updateBulkStatus res", res.data);
          setBulkUpdateRecords(res.data.data.trackingList)
          setCommonStaffs(res.data.data.multipleFilingCommonStaff)
          // fetchClientTrackingHistory(selectedFiling.fileTrackingId);
          // setShowHistory(() => true);
        }

        setShowBulkStatusDrawer(() => true)
      }
      else {
        setAlertMessage("Please Select more than one Filing Tracker Record for bulk status update");
        setShowAlert(true);
      }
    } else {
      setAlertMessage("Please Select a Filing Tracker Record");
      setShowAlert(true);
    }
  };

  const closeComments = () => {
    setShowComments(() => false);
  };

  const closeAttachments = () => {
    setShowAttachments(() => false);
  };

  const closeDocuments = () => {
    setShowDocuments(() => false);
  };
  const closeConsolidated = () => {
    setShowConsolidated(() => false);
  };
  // const closeClentSpecificConsolidated = () => {
  //   setShowClientSpecificConsolidated(() => false);
  // };

  const closeHistory = () => {
    setShowHistory(() => false);
  };

  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );
  const radioFooterContent = (
    <div>
      <PrimeButton
        label='SUBMIT'
        icon='pi pi-check'
        onClick={() => radioOkButton()}
        autoFocus
      />
    </div>
  );
  const radioFooterContent1 = (
    <div>
      <PrimeButton
        label='SUBMIT'
        icon='pi pi-check'
        onClick={() => radioOkButton1()}
        autoFocus
        disabled={(!selectedCustomer?.parentCustomer || selectedCustomer?.parentCustomer === null) && !selectedCustomer?.isOnlyParentFile && selectedAffectedCompanies.length === 0}
      />
    </div>
  );
  const radioFooterContentEdit = (
    <div>
      <PrimeButton
        label='SUBMIT'
        icon='pi pi-check'
        onClick={() => radioOkButtonEdit()}
        autoFocus
      />
    </div>
  );

  const closeAlert = () => {
    setShowAlert(false);
  };
  const radioCloseAlert = () => {
    setShowRadio(false);
  };
  const radioCloseAlert1 = () => {
    setSelectedAffectedCompanies([])
    setSelectedAffectedCompaniesEdit([])
    setShowRadio1(false);
    setCustomerNames([])
    setSelectedAffectedCompaniesEditInitial([])
    setSelectedAffectedCompaniesEditInitialNames([])
  };
  const radioCloseAlertEdit = () => {
    setSelectedAffectedCompanies([])
    setSelectedAffectedCompaniesEdit([])
    setSelectedAffectedCompaniesEditInitial([])
    setSelectedAffectedCompaniesEditInitialNames([])
    setShowRadioEdit(false);
    setCustomerNames([])
  };

  const refreshAttachments = () => {
    setRefreshList(true);
  };

  const resetRefreshList = () => {
    setRefreshList(false);
  };

  const handleRadioChange = (event) => {
    setRadioVal((event.target).value);
  };
  const handleRadioChange1 = (event) => {
    setSelectedAffectedCompanies([])
    setSelectedAffectedCompaniesEdit([])
    setRadioVal1((event.target).value);
  };
  const radioOkButton = () => {
    if (radioVal === "tracknow") {
      navigate(`/add-filings/${selectedClientId}`);
    }
    else {
      navigate(`/add-filing-postaward/${selectedClientId}`);
    }
  }

  const radioOkButton1 = () => {
    console.log("radioOkButton1 selectedAffectedCompanies", selectedAffectedCompanies);
    try {
      if (roleType.length > 1) {
        //incase user has two roles
        if (isTrackNowAccess && isPostAwardAccess) {
          //if user has both TN and PA access
          if (radioVal1 === "tracknow") {
            navigate("/filing-master", { state: { selectedAffectedCompanies, customerNames } });
            fetchCustomerList()
            onAddClientSpecificFilingClick(true)
          }
          else {
            navigate("/postaward-master-form", { state: { selectedAffectedCompanies, customerNames } });
            fetchCustomerList()
            onAddClientSpecificFilingClick(true)
          }
        } else if (isTrackNowAccess) {
          //TN roles
          navigate("/filing-master", { state: { selectedAffectedCompanies, customerNames } });
          fetchCustomerList()
          onAddClientSpecificFilingClick(true)
        } else {
          //PA roles
          navigate("/postaward-master-form", { state: { selectedAffectedCompanies, customerNames } });
          fetchCustomerList()
          onAddClientSpecificFilingClick(true)
        }
      } else if (isTrackNowAccess) {
        //TN roles
        navigate("/filing-master", { state: { selectedAffectedCompanies, customerNames } });
        fetchCustomerList()
        onAddClientSpecificFilingClick(true)
      } else if (isPostAwardAccess) {
        //PA roles
        navigate("/postaward-master-form", { state: { selectedAffectedCompanies, customerNames } });
        fetchCustomerList()

      }
    } catch (error) {
      console.error("radioOkButton1", error);
    } finally {
      setSelectedAffectedCompanies([])
      setSelectedAffectedCompaniesEdit([])
      setCustomerNames([])
      setSelectedAffectedCompaniesEditInitial([])
      setSelectedAffectedCompaniesEditInitialNames([])
    }
  }
  const radioOkButtonEdit = () => {
    editFiling()
  }

  const deleteMasterRecord = async () => {
    setRefreshList(false);
    if (selectedFiling) {
      if (selectedFiling[0].status === "Completed" || selectedFiling[0].status === "Approved") {
        setAlertMessage(
          <Typography>
            Unable to delete this{" "}
            <Typography fontWeight="bold" component="span">
              {selectedFiling[0].filingName}
            </Typography>{" "}
            as Status has been Approved or completed.
          </Typography>
        );
        setShowAlert(true);
      } else {
        // try {
        //   let payLoad = { ...selectedFiling };
        //   //payLoad.changesInprogress = undefined;
        //   payLoad.createUser = authUser.userId + "";
        //   payLoad.createDate = new Date();
        //   const res = await deleteDraftFilingMaster(payLoad);
        //   console.log("Draft Res:", res);
        //   if (res) {
        //     const wfPayload = {
        //       draftId: res.draftId,
        //       // currentApproverId: 1,
        //       WorkflowInitiatorId: authUser?.userId + "",
        //       createUser: authUser?.userId + "",
        //       createDate: new Date(),
        //     };
        //     let res3 = null;
        //     if (createFilingMasterWorkflow) {
        //       res3 = await createFilingMasterWorkflow(wfPayload);
        //     }
        //     console.log("handleDelete res3", res3);
        //     if (res3 !== null) {
        //       setCurrentFilingMaster(null);

        //       setAlertMessage(
        //         <Typography>
        //           This{" "}
        //           <Typography fontWeight="bold" component="span">
        //             {selectedFiling.filingName}
        //           </Typography>{" "}
        //           has been successfully deleted from Client Filings List.
        //         </Typography>
        //       );
        //       setShowAlert(true);
        //       setRefreshList(true);
        //       closeDialog();
        //     }
        //   } else {
        //     setAlertMessage(
        //       <Typography>
        //         Unexpected error occured while deleting{" "}
        //         <Typography fontWeight="bold" component="span">
        //           {selectedFiling.filingName}
        //         </Typography>{" "}
        //       </Typography>
        //     );
        //     setShowAlert(true);
        //     closeDialog();
        //   }
        // } 
        try {
          let payload = [
            {
              fileTrackingId: selectedFiling[0].fileTrackingId,
              customerId: selectedFiling[0].customerId,
              filingId: selectedFiling[0].filingId,
              year: new Date(selectedFiling[0].startdate).getFullYear() + "",
              UserId: authUser.userId + "",
            },
          ];
          // console.log("CustomerFilingDelete payload", payload, "selectedFiling", selectedFiling[0]);
          try {
            const res = await axios.post(
              `${API_BASE_URL}/Customer/CustomerFilingDelete`,
              payload
            );
            if (res) {
              setCurrentFilingMaster(null);
              setAlertMessage(
                <Typography>
                  This{" "}
                  <Typography fontWeight="bold" component="span">
                    {selectedFiling[0].filingName}
                  </Typography>{" "}
                  has been successfully deferred.
                </Typography>
              );
              setShowAlert(true);
              setRefreshList(true);
              closeDialog();
            }
          } catch (error) {
            console.log("deleteRecord @@Error:", error);
            setAlertMessage(
              <Typography>
                Unexpected error occured while deleting{" "}
                <Typography fontWeight="bold" component="span">
                  {selectedFiling[0].filingName}
                </Typography>{" "}
              </Typography>
            );
            setShowAlert(true);
            closeDialog();
          }
        }
        catch (error) {
          console.log("@@Error:", error);
        }
      }
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };

  const deleteRecord = async () => {
    setRefreshList(false);
    let payload = [
      {
        fileTrackingId: selectedFiling[0].fileTrackingId,
        customerId: selectedFiling[0].customerId,
        filingId: selectedFiling[0].filingId,
        year: new Date(selectedFiling[0].startdate).getFullYear() + "",
        UserId: authUser.userId + "",
      },
    ];
    try {
      // console.log("CustomerFilingDelete payload", payload, "selectedFiling", selectedFiling[0]);
      const res = await axios.post(
        `${API_BASE_URL}/Customer/CustomerFilingDelete`,
        payload
      );
      if (res) {
        setCurrentFilingMaster(null);
        setAlertMessage(
          <Typography>
            Successfully deferred filing :{" "}
            <Typography fontWeight="bold" component="span">
              {selectedFiling[0].filingName}
            </Typography>{" "}
          </Typography>
        );
        setShowAlert(true);
        setRefreshList(true);
        closeDialog();
      }
    } catch (error) {
      setAlertMessage(
        <Typography>
          Unexpected Error occured while deleting filing :{" "}
          <Typography fontWeight="bold" component="span">
            {selectedFiling[0].filingName}
          </Typography>{" "}
        </Typography>
      );
      setShowAlert(true);
      closeDialog();
    }
  };
  const deleteClientFiling = async () => {
    setRefreshList(false);
    let payload = [
      {
        FileTrackingID: selectedFiling[0].fileTrackingId,
        customerId: selectedFiling[0].customerId,
        filingId: selectedFiling[0].filingId,
        year: new Date(selectedFiling[0].startdate).getFullYear() + "",
        UserId: authUser.userId + "",
      },
    ];
    try {
      console.log("CustomerFileTrackerSpecificFilingDelete payload", payload, "selectedFiling", selectedFiling[0]);
      const res = await axios.post(
        `${API_BASE_URL}/Customer/CustomerFileTrackerSpecificFilingDelete`,
        payload
      );
      if (res) {
        setAlertMessage(
          <Typography>
            Successfully deleted:{" "}
            <Typography fontWeight="bold" component="span">
              {selectedFiling[0].filingName}
            </Typography>{" "}
          </Typography>
        );
        setShowAlert(true);
        setRefreshList(true);
        closeDialog();
      }
    } catch (error) {
      setAlertMessage(
        <Typography>
          Unexpected Error occured while deleting :{" "}
          <Typography fontWeight="bold" component="span">
            {selectedFiling[0].filingName}
          </Typography>{" "}
        </Typography>
      );
      setShowAlert(true);
      closeDialog();
    }
  };

  const handleSubmit = () => {
    // deleteCategory();

    if (isDeleteClientFiling) {
      deleteClientFiling()
      setIsDeleteClientFiling(false)
    }
    else if (selectedFiling[0].isMasterFiling) {
      deleteRecord();
    } else {
      deleteMasterRecord();
    }
  };

  const closeConfirm = () => {
  };

  const {
    AlertDialog: ConfirmDialog,
    showDialog,
    closeDialog,
  } = useDialog({
    onSubmit: () => handleSubmit(),
    onClose: closeConfirm,
  });


  const handleDelete = () => {
    console.log("handleDelete selectedFiling", selectedFiling, selectedClientId);

    if (selectedFiling.length === 0) {
      setAlertMessage("Please Select a Client specific Filing");
      setShowAlert(true);
    } else if (selectedFiling.length > 1) {
      setAlertMessage("Please Select a single Client specific Filing");
      setShowAlert(true);
    } else {
      if (!selectedFiling[0].isMasterFiling) {
        if (selectedFiling[0].status === "Approved" || selectedFiling[0].status === "Completed") {
          setAlertMessage(
            <Typography>
              Unable to delete this{" "}
              <Typography fontWeight="bold" component="span">
                {selectedFiling[0].filingName}
              </Typography>{" "}
              as Status has been Approved or completed.
            </Typography>
          );
          setShowAlert(true);
        } else {
          setIsDeleteClientFiling(true)
          showDialog(
            "Confirmation",
            <Typography>
              Are you sure you want to delete this{" "}
              <Typography fontWeight="bold" component="span">
                {selectedFiling[0].filingName}
              </Typography>{" "}
              record?{" "}
            </Typography>,
            "confirm",
          );
        }
      } else {
        setAlertMessage("Please Select a Client specific Filing to delete");
        setShowAlert(true);
      }
    }
  };


  const handleDeleteRecord = async () => {
    console.log(
      "handleDeleteRecord selectedClientId",
      selectedClientId,
      "selectedFiling",
      selectedFiling, "clientFilingData", clientFilingData
    );

    // const filteredFilingName = clientFilingData.filter(
    //   (item) => item.filingName === selectedFiling[0]?.filingName
    // );
    // const isStatusPending = filteredFilingName.every(
    //   (item) => item.status === "Pending"
    // );
    // const isStatusCompleted = filteredFilingName.some(
    //   (item) => item.status === "Completed" || item.status === "Approved"
    // );
    // console.log("handleDeleteRecord filteredFilingName", filteredFilingName, "isStatusPending", isStatusPending, "isStatusCompleted", isStatusCompleted);

    if (selectedClientId && selectedFiling && selectedFiling.length === 1) {
      if (
        selectedFiling[0].isOnlyChildFile &&
        selectedFiling[0].isUnderParent &&
        !underDetailedViewProps
      ) {
        setAlertMessage(
          "Only Parent filing is allowed to defer. Please try to defer from Consolidated view or Detailed view or Child company (child view)"
        );
        setShowAlert(true);
      } else {
        if (
          selectedClientId &&
          selectedFiling &&
          (selectedFiling[0].status === "Completed" ||
            selectedFiling[0].status === "Approved")
        ) {
          setAlertMessage(
            <Typography>
              Unable to defer this{" "}
              <Typography fontWeight="bold" component="span">
                {selectedFiling[0].filingName}
              </Typography>{" "}
              as Status has been Approved or completed.
            </Typography>
          );
          setShowAlert(true);
        } else {
          const currentYear = await axios.get(
            `${API_BASE_URL}/Customer/GetConfigValue?ConfigItem=CurrentYear`
          );
          const filingDueDateYear = selectedFiling[0].filingDueDate
            ? new Date(selectedFiling[0].filingPeriods.enddate).getFullYear()
            : 0;
          const isPreviousYear = filingDueDateYear === currentYear.data - 1
          if (isPreviousYear) {
            showDialog(
              "Confirmation",
              <Typography>
                If you defer a previous year's due date filing, it will be deleted from the tracker list and not moved to the deferred list.
                Are you certain you want to proceed?
              </Typography>,
              "confirm"
            );
          } else {
            showDialog(
              "Confirmation",
              <Typography>
                Are you sure you want to move this{" "}
                <Typography fontWeight="bold" component="span">
                  {selectedFiling[0].filingName}
                </Typography>{" "}
                to deferred list?{" "}
              </Typography>,
              "confirm"
            );
          }
        }
      }
    } else {
      if (selectedFiling.length > 1) {
        setAlertMessage("Please select a single filing ");
        setShowAlert(true);
      } else {
        setAlertMessage("Please select a filing ");
        setShowAlert(true);
      }
    }
  };


  useEffect(() => {
    if (clientId) setSelectedClientId(clientId);
    //eslint-disable-next-line
    const hasTrackNowAccess = roleType.includes("ClientAdmin") || roleType.includes("Admin") || roleType.includes("JSIPOC")
    const hasPostAwaradAccess = roleType.includes("ClientAdmin-PostAward") || roleType.includes("Admin-PostAward") || roleType.includes("JSIPOC-PostAward")
    setIsTrackNowAccess(hasTrackNowAccess)
    setIsPostAwardAccess(hasPostAwaradAccess)
  }, [clientId]);

  useEffect(() => {
    // Check conditions and set the initial radio value
    if (selectedCustomer?.trackNowLicenseId && selectedCustomer?.trackNowLicenseId > 0 && selectedCustomer?.postAwardLicenseId === null) {
      setRadioVal1('tracknow');
    } else if (selectedCustomer?.postAwardLicenseId && selectedCustomer?.postAwardLicenseId > 0 && selectedCustomer?.trackNowLicenseId === null) {
      setRadioVal1('postaward');
    }
  }, [selectedCustomer]);

  // useEffect(() => {
  //   if (selectedCustomer) {
  //     setSelectedClientId(() => selectedCustomer.customerId);
  //   }
  //   //eslint-disable-next-line
  // }, [selectedCustomer]);
  // console.log("Props-check", "selectedClientId", selectedClientId, "selectedCustomer", selectedCustomer, "tablValue", tablValue, "selectedYear", selectedYear);
  const handleChildCompanyChange = (e, affectedCompanyListsDropdown) => {
    console.log("handleChildCompanyChange", "e", e,);
    const selectedCompanyNames = e.value.map(selectedId => {
      const selectedCompany = affectedCompanyListsDropdown.find(company => company.customerId === selectedId);
      return selectedCompany ? { customerId: selectedCompany.customerId, customerName: selectedCompany.customerName } : []; // Return customerName if found, otherwise an empty string
    });
    setCustomerNames(selectedCompanyNames)
    setSelectedAffectedCompanies(e.value)
  }
  useEffect(() => {
    if (radioVal1 === "tracknow") {
      console.log("radioVal1", radioVal1, "affectedCompanies", affectedCompanyLists);
      const filtAffectedComp = affectedCompanyLists.filter(id => id.trackNowLicenseId)
      setAffectedCompanyListsDropdown(filtAffectedComp)
      console.log("radioVal1 filtAffectedComp", filtAffectedComp);
    } else {
      console.log("radioVal1", radioVal1, "affectedCompanies", affectedCompanyLists);
      const filtAffectedComp = affectedCompanyLists.filter(id => id.postAwardLicenseId)
      setAffectedCompanyListsDropdown(filtAffectedComp)
      console.log("radioVal1 filtAffectedComp", filtAffectedComp);
    }

  }, [radioVal1, affectedCompanyLists])
  console.log("affectedCompaniesDropDown", affectedCompanyListsDropdown);
  const handleChildCompanyChangeEdit = (e, action) => {
    console.log("handleChildCompanyChange", "e", e,);
    const selectedCompanyNames = e.value.map(selectedId => {
      const selectedCompany = affectedCompanyLists.find(company => company.customerId === selectedId);
      return selectedCompany ? { customerId: selectedCompany.customerId, customerName: selectedCompany.customerName } : []; // Return customerName if found, otherwise an empty string
    });
    console.log("handleChildCompanyChange", "Names", "selectedAffectedCompaniesEditInitialNames", selectedAffectedCompaniesEditInitialNames, "E", e.value, "selectedCompanyNames", selectedCompanyNames, "customerNames", customerNames);

    const filteredValues = e.value.filter(value => !selectedAffectedCompaniesEditInitial.includes(value));
    const filteredNames = selectedCompanyNames.filter(value =>
      !selectedAffectedCompaniesEditInitialNames.some(initialValue => initialValue.customerId === value.customerId)
    );
    const selAffectedCompIds = [...selectedAffectedCompaniesEditInitial, ...filteredValues]
    const selAffectedCompNames = [...selectedAffectedCompaniesEditInitialNames, ...filteredNames]
    console.log("handleChildCompanyChange", "selectedCompanyNames", selectedCompanyNames, "selectedAffectedCompaniesEditInitial", selectedAffectedCompaniesEditInitial, "selAffectedCompIds", selAffectedCompIds, "filteredValues", filteredValues, "filteredNames", filteredNames, "selAffectedCompNames", selAffectedCompNames);
    setCustomerNames(selAffectedCompNames)
    setSelectedAffectedCompaniesEdit(selAffectedCompIds)
  }
  console.log("check customerNames", customerNames, "selectedAffectedCompaniesEdit", selectedAffectedCompaniesEdit);
  let trackerTable = useRef();
  return (
    <Fragment>
      <ClientFilingBulkStatusUpdate
        show={showBulkStatusDrawer}
        dockAt='right'
        statusRecords={selectedFiling}
        childList={bulkUpdateRecords}
        commonStaffs={commonStaffs}
        handleClose={closeStatusBulkUpdateDrawer}
        handleRefreshTable={refreshSelectedRecord}
      // updateSelectedWorkflow={updateSelectedWorkflow}
      />
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Dialog
        header='Confirmation'
        visible={showRadio}
        style={{ width: "30vw" }}
        onHide={() => radioCloseAlert()}
        footer={radioFooterContent}
      >
        <p className='m-0'>{radioMessage}</p>
        <FormControl>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={radioVal}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="tracknow" control={<Radio />} label="Track Now" />
            <FormControlLabel value="postaward" control={<Radio />} label="Post Award" />

          </RadioGroup>




        </FormControl>
      </Dialog>
      <Dialog
        header='Confirmation'
        visible={showRadio1}
        style={{ width: "42vw", zIndex: 9999 }}
        onHide={() => radioCloseAlert1()}
        footer={radioFooterContent1}
      >
        <p className='m-0'>{radioMessage1}</p>

        <FormControl>
          <Stack direction="column" spacing={2}>
            {(isTrackNowAccess && isPostAwardAccess) && (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={radioVal1}
                onChange={handleRadioChange1}
              >

                <FormControlLabel disabled={!selectedCustomer?.trackNowLicenseId > 0} value="tracknow" control={<Radio />} label="Track Now" />
                <FormControlLabel disabled={!selectedCustomer?.postAwardLicenseId > 0} value="postaward" control={<Radio />} label="Post Award" />

              </RadioGroup>
            )}
            {(!selectedCustomer?.parentCustomer || selectedCustomer?.parentCustomer === null) && !selectedCustomer?.isOnlyParentFile && (
              <div>
                <FormLabel>

                  <Typography>
                    Please select parent/child Company(s)
                  </Typography>
                </FormLabel>

                <MultiSelect value={selectedAffectedCompanies} onChange={(e) => handleChildCompanyChange(e, affectedCompanyListsDropdown)} options={affectedCompanyListsDropdown} optionLabel="customerName" optionValue="customerId"
                  placeholder="Select company" maxSelectedLabels={3} className="w-full md:w-20rem" />
              </div>
            )}
          </Stack>
        </FormControl>
      </Dialog>
      <Dialog
        header='Confirmation'
        visible={showRadioEdit}
        style={{ width: "42vw" }}
        onHide={() => radioCloseAlertEdit()}
        footer={radioFooterContentEdit}
      >
        {/* <p className='m-0'>{radioMessage1}</p> */}
        {(!selectedCustomer?.parentCustomer || selectedCustomer?.parentCustomer === null) && (
          <FormControl>
            <Stack direction="column" spacing={2}>
              <FormLabel>
                <Typography>
                  If you want to add the filing to the Child company as well, please select child Company(s)
                </Typography>
              </FormLabel>

              <MultiSelect value={selectedAffectedCompaniesEdit} onChange={(e) => handleChildCompanyChangeEdit(e, "edit")} options={affectedCompanyListsDropdown} optionLabel="customerName" optionValue="customerId" optionDisabled={(option) => option.isDisabled}
                placeholder="Select company" maxSelectedLabels={3} className="w-full md:w-20rem" />
            </Stack>
          </FormControl>
        )}
      </Dialog>


      <Grid sx={{ height: "100%", width: "92.5%" }}>
        <Grid sx={{ height: "100%" }}>
          <div className="card" style={{ height: "100%" }}>
            <ClientLookup
              setSelectedClientId={setSelectedClientId}
              parentRoute="client-filing-tracker-list"
            />
          </div>
        </Grid>
        <Grid sx={{ height: "100%" }}>
          <div
            className="card"
            style={{
              maxWidth: "90vw",
              padding: "1rem",
              margin: "0 1rem",
              background: "#FFF",
              // borderRadius: "2%",
            }}
          >
            <ClientFilingTrackerTable
              ref={trackerTable}
              selectedClientId={selectedClientId}
              setSelectedFiling={setSelectedFiling}
              setDetailedViewVal={setDetailedViewVal}
              setSortRecord={setSortRecord}
              setTablValue={setTablValue}
              setSelectedYear={setSelectedYear}
              setClientFilingData={setClientFilingData}
              refreshList={refreshList}
              setStaffList={setStaffList}
              resetRefreshList={resetRefreshList}
              refreshAttachments={refreshAttachments}
              displayAttachments={displayAttachments}
              displayDocuments={displayDocuments}
              displayComments={addComment}
              setUnderDetailedViewProps={setUnderDetailedViewProps}
              // downloadData={setDownloadData}
              // downloadDataHistory={setDownloadDataHistory}
              handleRefreshTableReturn={handleRefreshTableReturn}
              refreshTable={refreshTable}
              keepSelectedRecords={keepSelectedRecords}
              resetKeepSelectedRecords={(e) => setKeepSelectedRecords(e)}
              keepPageDetails={keepPageDetails}
              resetKeepPageDetails={(e) => setKeepPageDetails(e)}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          height: "100%",
          width: "5%",
        }}
      >
        <div
          style={{
            height: "100%",
            background: "#F1F2F6",
            marginTop: "1rem",
            borderRadius: "2%",
          }}
        >
          <Item sx={{ height: "100%" }}>
            <Toolbox
              displayFilter={displayFilter}
              displayClientFiling={displayClientFiling}
              displayAddClientFiling={displayAddClientFiling}
              displayComments={addComment}
              displayAttachments={displayAttachments}
              displayHistory={displayHistory}
              updateBulkStatus={updateBulkStatus}
              displayDocuments={displayDocuments}
              displayConsolidatedView={displayConsolidatedView}
              // displayClientSpecificConsolidatedView={displayClientSpecificConsolidatedView}
              downloadData={exportExcel}
              downloadDataHistory={exportExcelHistory}
              editMasterFilling={editMasterFilling}
              addMasterFilling={addMasterFilling}
              // deleteMasterRecord={handleDelete}
              deleteClientFiling={handleDelete}
              deleteRecord={handleDeleteRecord}
              hasTrackNowAccess={isTrackNowAccess}
              hasPostAwaradAccess={isPostAwardAccess}
              isTabValue={tablValue === "2" ? true : false}
              isMasterFiling={
                selectedFiling ? selectedFiling[0]?.isMasterFiling : true
              }
              selectedClientId={selectedClientId}
            />
          </Item>
        </div>
      </Grid>
      <ConfirmDialog />
      {
        <ClientFilingTrackerComments
          show={showComments}
          dockAt="right"
          tracker={selectedFiling[0]}
          comments={comments}
          handleClose={closeComments}
          refreshList={refreshList}
          resetRefreshList={resetRefreshList}
          handleRefreshTable={handleRefreshTable}
        />
      }
      {
        <ClientFilingTrackerAttachment
          show={showAttachments}
          dockAt="right"
          tracker={selectedFiling[0]}
          attachments={attachments}
          handleClose={closeAttachments}
          refreshAttachments={refreshAttachments}
          handleRefreshTable={handleRefreshTable}
        />
      }
      {
        <ClientFilingTrackerHistory
          show={showHistory}
          dockAt="right"
          tracker={selectedFiling[0]}
          activityHistory={activityHistory}
          isCommentsTabLoading={isCommentsHistoryLoading}
          isTrackNowTabLoading={isTnistoryLoading}
          isClientFilingTabLoading={isCfHistoryLoading}
          clientActivityHistory={clientActivityHistory}
          commentActivityHistory={commentActivityHistory}
          handleClose={closeHistory}
          setActivityHistory={setActivityHistory}
          setClientActivityHistory={setClientActivityHistory}
          setCommentActivityHistory={setCommentActivityHistory}
          setIsCfHistoryLoading={setIsCfHistoryLoading}
          setIsCommentsHistoryLoading={setIsCommentsHistoryLoading}
          setIsTnHistoryLoading={setIsTnHistoryLoading}
        />
      }
      {
        <ClientFilingTrackerDocuments
          show={showDocuments}
          dockAt="right"
          tracker={selectedFiling[0]}
          attachments={documents}
          handleClose={closeDocuments}
        />
      }
      {
        <ClientFilingConsolidatedUpdate
          show={showConsolidated}
          dockAt='right'
          cust={selectedFiling[0]}
          consolidatedRecords={consolidatedRecords}
          consolidatedStatus={consolidatedStatus}
          staffList={staffList}
          handleClose={closeConsolidated}
          selectedClientId={selectedClientId}
          handleRefreshTable={handleRefreshTable}
          refreshConsList={() => fetchConsolidatedRecords(selectedFiling[0])}

        />
      }
      {/* {
        <ClientSpecificFilingConsolidateView
          show={showClientSpecificConsolidated}
          dockAt='right'
          cust={selectedFiling[0]}
          affectedCompanyLists={affectedCompanyLists}
          consolidatedClientFilingList={consolidatedClientFilingList}
          consolidatedStatus={consolidatedStatus}
          // staffList={staffList}
          handleClose={closeClentSpecificConsolidated}
          selectedClientId={selectedClientId}
        // handleRefreshTable={handleRefreshTable}
        // refreshConsList={() => fetchConsolidatedRecords(selectedFiling[0])}

        />
      } */}
    </Fragment>
  );
}

export default ClientFilingTrackerList;
