import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
//import ClientLookup from "../../customer/ClientLookup";
import ClientLookup from "../customer/ClientLookup";
//import AddFilingTable from "./AddFilingtable";
//import AddFilingTable from "../dev/addfiling/AddFilingtable";
import AddFilingPostAwardtable from "./AddFilingPostAwardtable";
import ReactLoading from "react-loading";
import DeferredToolbox from "../dev/addfiling/DeferredToolbox";
import DeferredHistory from "../dev/addfiling/DeferredHistory";
import { Dialog } from "primereact/dialog";
import { Button as PrimeButton } from "primereact/button";
import axios from "axios";
import DeferedCommentsPA from "./DeferedCommentsPA";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function AddFilingPostAwardlist(props) {
  let { clientId } = useParams();
  const navigate = useNavigate();
  const [customerId, setCustomerId] = React.useState(null);
  const [SelectedClientId, setSelectedClientId] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [selectedMasters, setSelectedMasters] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [activityHistory, setActivityHistory] = React.useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [comments, setComments] = useState()
  const [showComments, setShowComments] = useState(false)
  // let { clientId } = useParams();

  const getCustomerId = (id) => {
    setCustomerId(id);
    setSelectedMasters(null);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const fetchClientTrackingHistory = async (filingId) => {
    try {
      const history = await axios.get(
        `${API_BASE_URL}/Customer/DeferredFilingAudit?CustomerId=${customerId}&FilingId=${filingId}`
      );
      setActivityHistory(() => history.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const displayClientTracker = () => {
    if (clientId) {
      navigate(`/client-filing-tracker-list/${clientId}`);
    } else {
      navigate("/client-filing-tracker-list");
    }
  };

  const displayHistory = async () => {
    console.log("displayHistory", selectedMasters);
    // if (selectedMasters && selectedMasters.length > 1) {
    //   setAlertMessage("Please Select single Filing Tracker Record");
    //   setShowAlert(true);
    // } else
    if (selectedMasters?.filingId) {
      fetchClientTrackingHistory(selectedMasters.filingId);
      setShowHistory(() => true);
    } else {
      setAlertMessage("Please Select a Filing Tracker Record");
      setShowAlert(true);
    }
  };


  const closeHistory = () => {
    setShowHistory(() => false);
  };
  const closeComments = () => {
    // console.log("Toggling Show Filter", showComments);
    setShowComments(() => false);
  };

  React.useEffect(() => {
    console.log("props Test", props);
    setIsLoading(false);
  }, [props]);


  const fetchFilingMasterComments = async () => {
    console.log("@@Fetch FilingMasters1:", selectedMasters);
    try {
      let resList
      if (
        selectedMasters.filetrackingID &&
        selectedMasters.workflowId
      ) {
        const resByFileTrackingId = await axios.get(
          `${API_BASE_URL}/Customer/ViewChildCustomerFilingTrackingComments?fileTrackingIds=${selectedMasters.filetrackingID}`
        );
        const resByFileTrackingId_Of_CommentsAttachments = await axios.get(
          `${API_BASE_URL}/Customer/ViewParentandchildCustomerCommentsandAttachments?customerIds=${selectedMasters.customerId}&fileTrackingIds=${selectedMasters.filetrackingID}`
        );
        const resByWorkflowId = await axios.get(
          `${API_BASE_URL}/Customer/CustomerFilingWorkflowComments${selectedMasters.workflowId.workflowId}`
        );
        const combainedResponse = [
          ...(Array.isArray(resByFileTrackingId.data) ? resByFileTrackingId.data : []),
          ...(Array.isArray(resByWorkflowId.data) ? resByWorkflowId.data : []),
          ...(Array.isArray(resByFileTrackingId_Of_CommentsAttachments.data.data) ? resByFileTrackingId_Of_CommentsAttachments.data.data : []),
        ];
        const uniqueResponse = combainedResponse.filter((item, index, self) =>
          index === self.findIndex((t) => t.commentsId === item.commentsId)
        );

        // Sort by date
        uniqueResponse.sort((a, b) => {
          const dateA = new Date(a.updateDate || a.createDate);
          const dateB = new Date(b.updateDate || b.createDate);
          return dateB.getTime() - dateA.getTime(); // Descending order
        });
        console.log("###COMB uniqueResponse", uniqueResponse);

        resList = uniqueResponse

      } else if (selectedMasters.workflowId) {
        const resByWorkflowId = await axios.get(
          `${API_BASE_URL}/Customer/CustomerFilingWorkflowComments${selectedMasters.workflowId.workflowId}`
        );
        resList = Array.isArray(resByWorkflowId.data) ? resByWorkflowId.data : [];
      } else if (
        selectedMasters.filetrackingID
      ) {
        const resByFileTrackingId = await axios.get(
          `${API_BASE_URL}/Customer/ViewChildCustomerFilingTrackingComments?fileTrackingIds=${selectedMasters.filetrackingID}`
        );
        const resByFileTrackingId_Of_CommentsAttachments = await axios.get(
          `${API_BASE_URL}/Customer/ViewParentandchildCustomerCommentsandAttachments?customerIds=${selectedMasters.customerId}&fileTrackingIds=${selectedMasters.filetrackingID}`
        );
        const combainedResponse = [
          ...(Array.isArray(resByFileTrackingId.data) ? resByFileTrackingId.data : []),
          ...(Array.isArray(resByFileTrackingId_Of_CommentsAttachments.data.data) ? resByFileTrackingId_Of_CommentsAttachments.data.data : []),
        ];

        const uniqueResponse = combainedResponse.filter((item, index, self) =>
          index === self.findIndex((t) => t.commentsId === item.commentsId)
        );

        // Sort by date
        uniqueResponse.sort((a, b) => {
          const dateA = new Date(a.updateDate || a.createDate);
          const dateB = new Date(b.updateDate || b.createDate);
          return dateB.getTime() - dateA.getTime(); // Descending order
        });

        resList = uniqueResponse
      } else {
        console.log("No valid fileTrackingId or workflowId found");
      }

      if (Array.isArray(resList)) {
        resList.sort((a, b) => {
          const dateA = new Date(a.updateDate || a.createDate);
          const dateB = new Date(b.updateDate || b.createDate);
          return dateB.getTime() - dateA.getTime();
        });
      } else {
        console.log("resList is not an array");
      }
      setComments(() => resList);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };


  const addComment = () => {
    if (selectedMasters) {
      fetchFilingMasterComments();
      setShowComments(() => true);
    } else {
      setAlertMessage("Please Select a Filing Tracker Record");
      setShowAlert(true);
    }
  };
  return (
    <Fragment>
      {isLoading ? (
        <ReactLoading
          style={{
            position: "absolute",
            width: "3rem",
            marginLeft: "36rem",
            marginTop: "10rem",
            color: "#0000FF",
          }}
          type="spin"
          color="blue"
          height={80}
        />
      ) : (
        <Fragment>
          <Grid style={{ height: "100%", width: "93%" }}>
            <Grid sm={24}>
              {/* <div className='card' style={{ height: "10vh", maxWidth: "100vw" }}> */}
              {!loadingFlag && (
                // <ClientLookup
                //   setSelectedClientId={getCustomerId}
                //   parentRoute="add-filing-postaward"
                //   checkForPostAward={true}
                // />
                <ClientLookup
                  setSelectedClientId={getCustomerId}
                  parentRoute="add-filing-postaward"
                  checkForPostAward={true}
                />
              )}
              {/* </div> */}
            </Grid>
            <Grid sm={24}>
              <div
                className="card"
                style={{
                  width: "89vw",
                  padding: "1rem",
                  margin: "0 1rem",
                  background: "#FFF",
                  // borderRadius: "2%",
                }}
              >
                <AddFilingPostAwardtable
                  selectedClientId={customerId}
                  setLoadingFlag={setLoadingFlag}
                  changeSelectedMasters={(val) => setSelectedMasters(val)}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            sx={{
              height: "100%",
              width: "5%",
            }}
          >
            <div
              style={{
                height: "100%",
                background: "#F1F2F6",
                marginTop: "1rem",
                borderRadius: "2%",
              }}
            >
              <Item
                sx={{
                  height: "100%",
                }}
              >
                <DeferredToolbox
                  isLoading={isLoading}
                  // draft={selectedmasters?.customer[0]?.customerId}
                  displayHistory={displayHistory}
                  addComment={addComment}
                  displayClientTracker={displayClientTracker}
                />
              </Item>
            </div>
          </Grid>

          <Dialog
            header="Info"
            visible={showAlert}
            style={{ width: "30vw" }}
            onHide={() => closeAlert()}
            footer={footerContent}
          >
            <p className="m-0">{alertMessage}</p>
          </Dialog>
        </Fragment>
      )}
      {
        <DeferredHistory
          show={showHistory}
          dockAt="right"
          tracker={selectedMasters?.filingId && selectedMasters}
          activityHistory={activityHistory}
          handleClose={closeHistory}
        />
      }
      {
        <DeferedCommentsPA
          show={showComments}
          dockAt='right'
          draft={selectedMasters}
          comments={comments}
          handleClose={closeComments}
        />
      }
    </Fragment>
  );
}

export default AddFilingPostAwardlist;
