import React, { useReducer } from "react";
import axios from "axios";
import FilingMasterContext from "./FilingMasterContext";
import FilingMasterReducer from "./FilingMasterReducer";
import {
  FILING_MASTER_LOADING,
  FILING_MASTER_LOADED,
  FILING_MASTER_ERROR,
  FILING_MASTER_RESET,
  SET_CURRENT_FILING_MASTER,
  UPDATE_FILING_MASTER,
  BUSINES_CATEGORY_LOADED,
  POST_PROGRAM_LOADING,
  POST_PROGRAM_LOADED,
  POST_PROGRAM_ERROR,
  PROGRAM_NAME_LOADED,
  FILING_MASTER_APPROVAL_LOADED,
  FILING_MASTER_APPROVAL_COMPLETED_LOADED,
  UPDATE_DRAFT_FILING_MASTER,
  UPDATE_FILING_MASTER_WF,
  MASTER_COMMENTS_LOADED,
  FILING_MASTER_ADD_COMMENT,
  MASTER_WORKFLOW_COMMENTS_LOADED,
  APPROVER_LIST_LOADED,
  MASTER_COMMENTS_ATTACHMENTS_LOADED,
  POSTAWARD_FILING_MASTER_LOADED,
} from "../../data/constants";
// import { env } from "process";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
// const API_HOST = "";
const FilingMasterState = (props) => {
  const initialState = {
    error: null,
    filingMasters: [],
    postAwardFilingMasters: null,
    filingMasterApprovals: [],
    changedFilingMasters: [],
    filingMasterLoading: false,
    filingMasterLoaded: false,
    businessCategories: [],
    postProgram: [],
    draftFilingMaster: null,
    filingMasterWorkflow: null,
    filingMasterComments: [],
    filingMasterWorkflowComments: [],
    filingMasterWorkflowCommentsAttachments: [],
    selectedFilingMaster: localStorage.getItem("selectedFilingMaster")
      ? JSON.parse(localStorage.getItem("selectedFilingMaster"))
      : null,
    savedCriteria: null,
    approverList: null,
  };
  const [state, dispatch] = useReducer(FilingMasterReducer, initialState);

  //Reset Context State
  const resetFilingMasterState = () => {
    dispatch({
      type: FILING_MASTER_RESET,
      payload: { ...initialState },
    });
  };

  //select a filingMaster for processing
  const setCurrentFilingMaster = (filingMaster) => {
    dispatch({
      type: SET_CURRENT_FILING_MASTER,
      payload: filingMaster,
    });
  };

  const setFilingMasters = (filingMaster) => {
    dispatch({
      type: FILING_MASTER_LOADED,
      payload: filingMaster,
    });
  };

  //Search All filingMasters
  const getFilingMasters = async (formData) => {
    // // console.log("FETCHING FilingMasters ");
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        let res;
        if (formData.recordType === "TrackNow") {
          if (formData?.year) {
            res = await axios.get(
              `${API_BASE_URL}/FilingMaster/FilingMasterList?RecordType=${formData.recordType}&Year=${formData.year}`
            )
          } else {
            res = await axios.get(
              `${API_BASE_URL}/FilingMaster/FilingMasterList?RecordType=${formData.recordType}`
            )
          };
        } else {
          if (formData?.year) {
            res = await axios.get(
              `${API_BASE_URL}/FilingMaster/PostAwardFilingMasterList?RecordType=${formData.recordType}&Year=${formData.year}`
            )
          } else {
            res = await axios.get(
              `${API_BASE_URL}/FilingMaster/PostAwardFilingMasterList?RecordType=${formData.recordType}`
            )
          };
        }
        // console.log("FilingMasters ", res);
        if (formData.recordType === "TrackNow") {
          dispatch({
            type: FILING_MASTER_LOADED,
            payload: res.data,
          });
        } else {
          dispatch({
            type: POSTAWARD_FILING_MASTER_LOADED,
            payload: res.data,
          });
        }
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Creat a filingMaster
  const createFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/FilingMaster/CreateFilingMaster`,
          formData
        );
        // console.log("FilingMasters ", res);
        dispatch({
          type: UPDATE_FILING_MASTER,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Creat a filingMaster Draft
  const createDraftFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.put(
          `${API_BASE_URL}/FilingMaster/DraftFilingMasterUpdate${formData.draftId}`,
          formData
        );
        // console.log("FilingMasters ", res);
        if (res && res.data) {
          dispatch({
            type: UPDATE_DRAFT_FILING_MASTER,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  //Create client Filing master 
  const createClientDraftFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.put(
          `${API_BASE_URL}/FilingMaster/ClientDraftFilingMasterUpdate`,
          formData
        );
        // console.log("FilingMasters ", res);
        if (res && res.data) {
          dispatch({
            type: UPDATE_DRAFT_FILING_MASTER,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Creat a filingMaster Draft
  const deleteDraftFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        formData = { ...formData, businessOperation: "delete" };
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/FilingMaster/CreateDraftFilingMaster`,
          formData
        );
        // console.log("FilingMasters ", res);
        // if (res) {
        //   getFilingMasters();
        // }
        if (res && res.data && res.data.status === "Success") {
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Creat a filingMaster Workflow
  const createFilingMasterWorkflow = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/FilingMaster/CreateFilingMasterWorkflow`,
          formData
        );
        // console.log("FilingMasters ", res);
        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: UPDATE_FILING_MASTER_WF,
            payload: res.data.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  //Workflow generator
  const createClientFilingMasterWorkflow = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/FilingMaster/CreateFilingMasterWorkflowForClient`,
          formData
        );
        // console.log("FilingMasters ", res);
        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: UPDATE_FILING_MASTER_WF,
            payload: res.data.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  const GeneratePostAwardMasterWorkflowForClient = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/FilingMaster/GeneratePostAwardMasterWorkflowForClient`,
          formData
        );
        // console.log("FilingMasters ", res);
        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: UPDATE_FILING_MASTER_WF,
            payload: res.data.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const createPostAwardWorkflow = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/FilingMaster/GeneratePostAwardMasterWorkflow`,
          formData
        );
        // console.log("FilingMasters ", res);
        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: UPDATE_FILING_MASTER_WF,
            payload: res.data.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Creat a filingMaster Workflow
  const approveFilingMasterWorkflow = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        let res = null;
        // const payload = formData.draft;
        // delete payload.workflowId;
        // delete payload.approverName;
        let approvalStatus = formData?.approvalStatus;
        if (approvalStatus === true) {
          delete formData.approvalStatus;
          // delete formData.draft.businessCategory;
          // delete formData.draft.WorkflowId;
          // delete formData.draft.approvalComment;
          res = await axios.put(
            `${API_BASE_URL}/FilingMaster/FilingMasterApprove`,
            formData
          );

          // console.log("FilingMastersApproval ", res);
          if (res && res.data && res.data.status === "Success") {
            resolve(`Filing Master Draft has been approved`);
          } else {
            reject(`Error while approving Filing Master`);
          }
        } else {
          delete formData.approvalStatus;
          // delete formData.draft.businessCategories;
          // delete formData.draft.WorkflowId;
          res = await axios.put(
            `${API_BASE_URL}/FilingMaster/FilingMasterReject`,
            formData
          );
          // console.log("FilingMastersApproval ", res);
          if (res && res.data && res.data.status === "Success") {
            resolve(`Filing Master Draft has been rejected`);
          } else {
            reject(`Error while rejecting Filing Master`);
          }
        }
        await getFilingMasterApprovals({ recordType: "TrackNow" });
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //FilingMaster/FilingMasterBulkApprove
  //Creat a filingMaster Workflow
  const approveBulkFilingMasterWorkflow = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        let role = formData[0].role;
        if (role) {
          delete formData[0].role;
        }
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        let res = null;
        res = await axios.put(
          `${API_BASE_URL}/FilingMaster/FilingMasterBulkApprove`,
          formData
        );

        // console.log("FilingMastersApproval ", res);
        if (res && res.data && res.data.status === "Success") {
          resolve(res.data);
        } else {
          reject(`Error while approving Filing Master`);
        }
        // if (role === "Admin") {
        //   await getFilingMasterApprovals({ recordType: "TrackNow" });
        // } else {
        //   await getFilingMasterApprovals({
        //     approverId: formData[0].userid,
        //     recordType: "TrackNow",
        //   });
        // }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  const rejectBulkFilingMasterWorkflow = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        let role = formData[0].role;
        if (role) {
          delete formData[0].role;
        }
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        let res = null;
        res = await axios.put(
          `${API_BASE_URL}/FilingMaster/FilingMasterBulkReject`,
          formData
        );

        // console.log("FilingMastersApproval Reject ", res);
        if (res && res.data && res.data.status === "Success") {
          resolve(res.data);
        } else {
          reject(`Error while rejecting Filing Master`);
        }
        // if (role === "Admin") {
        //   await getFilingMasterApprovals({ recordType: "TrackNow" });
        // } else {
        //   await getFilingMasterApprovals({
        //     approverId: formData[0].userid,
        //     recordType: "TrackNow",
        //   });
        // }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //PostAward FilingMaster BulkApprove
  const approveBulkPostAwardWorkflow = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        let role = formData[0].role;
        // console.log("Payload", JSON.stringify(formData));
        if (role) {
          delete formData[0].role;
        }
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        let res = null;
        res = await axios.put(
          `${API_BASE_URL}/FilingMaster/PostAwardFilingMasterBulkApprove`,
          formData
        );

        // console.log("FilingMastersApproval ", res);
        if (res && res.data && res.data.status === "Success") {
          resolve(`Filing Master Draft has been approved`);
        } else {
          reject(`Error while approving Filing Master`);
        }
        // if (role && role.startsWith("Admin-PostAward")) {
        //   await getFilingMasterApprovals({ recordType: "PostAward" });
        // } else {
        //   await getFilingMasterApprovals({
        //     approverId: formData[0].userid,
        //     recordType: "PostAward",
        //   });
        // }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  const rejectBulkPostAwardWorkflow = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        let role = formData[0].role;
        if (role) {
          delete formData[0].role;
        }
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        let res = null;
        res = await axios.put(
          `${API_BASE_URL}/FilingMaster/PostAwardFilingMasterBulkReject`,
          formData
        );
        // console.log("FilingMastersApproval Reject ", res);
        if (res && res.data && res.data.status === "Success") {
          resolve(`Filing Master Draft has been rejected`);
        } else {
          reject(`Error while rejecting Filing Master`);
        }
        // if (role === "Admin-PostAward") {
        //   await getFilingMasterApprovals({ recordType: "PostAward" });
        // } else {
        //   await getFilingMasterApprovals({
        //     approverId: formData[0].userid,
        //     recordType: "PostAward",
        //   });
        // }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  //Add comment to  Filing Master
  const addFilingMasterComment = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        let res = null;
        // const payload = {
        //   filingId: formData.draft.filingId,
        //   commentsText: formData.approvalComment,
        // };
        res = await axios.post(
          `${API_BASE_URL}/FilingMaster/CreateFilingMasterComments`,
          formData
        );

        // console.log("FilingMastersComment ", res);
        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        await getFilingMasterComments(formData.filingId);
        resolve(res.data.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Add comment to  Filing Master Approval
  const addFilingMasterWorkflowComment = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        // dispatch({
        //   type: FILING_MASTER_LOADING,
        // });
        let res = null;
        // const payload = {
        //   filingId: formData.draft.filingId,
        //   commentsText: formData.approvalComment,
        // };
        res = await axios.post(
          `${API_BASE_URL}/FilingMaster/CreateFilingMasterWorkflowComments`,
          formData
        );

        // console.log("FilingMastersWorkflowComment ", res);
        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Fetch Programe name list
  const getProgramName = async (formData) => {
    // console.log("FETCHING PROGRAM NAMES ");
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.get(
          `${API_BASE_URL}/Application/ListTracknowProgram`
        );
        // console.log("Program Names ", res);
        dispatch({
          type: PROGRAM_NAME_LOADED,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //add a program name

  const addProgramName = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });

        let res = null;

        res = await axios.post(
          `${API_BASE_URL}/Application/BusinessCategoryMaster`,
          formData
        );

        // console.log("ProgramName add ", res);
        await getBusinessCategories();
        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Fetch Business Category List
  const getBusinessCategories = async (formData) => {
    // console.log("FETCHING Business Categories ");
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.get(
          `${API_BASE_URL}/Application/BusinessCategoryMasterList`
        );
        // console.log("Business Catgeogries ", res);
        dispatch({
          type: BUSINES_CATEGORY_LOADED,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Add a Business Cagegory
  const addBusinessCategory = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });

        let res = null;

        res = await axios.post(
          `${API_BASE_URL}/Application/BusinessCategoryMaster`,
          formData
        );

        // console.log("BusinessCategory add ", res);
        await getBusinessCategories();
        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Update a Business Cagegory
  const updateBusinessCategory = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });

        let res = null;

        res = await axios.put(
          `${API_BASE_URL}/Application/BusinessCategoryMasterUpdate${formData.businessCategoryId}`,
          formData
        );

        // console.log("BusinessCategory Modify ", res);
        await getBusinessCategories();
        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Delete a Business Cagegory
  const deleteBusinessCategory = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: FILING_MASTER_LOADING,
        });

        const res = await axios.delete(
          `${API_BASE_URL}/Application/BusinessCategoryMaster${formData.businessCategoryId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        await getBusinessCategories();

        // Return the response regardless of status
        if (res && res.data) {
          resolve(res.data);
        } else {
          reject({ message: "Unexpected response format", response: res });
        }
      } catch (err) {
        dispatch({
          type: POST_PROGRAM_ERROR,
          payload: err,
        });

        // Return the error for rejection
        reject(err.response ? err.response.data : { message: "An error occurred", error: err });
      }
    });
  };



  const getFilingMasterComments = async (formData) => {
    // console.log("FETCHING Business Categories ");
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.get(
          `${API_BASE_URL}/FilingMaster/FilingMasterComments${formData}`
        );
        // console.log("Comments ", res);
        dispatch({
          type: MASTER_COMMENTS_LOADED,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const getFilingMasterWorkflowComments = async (formData) => {
    // console.log("FETCHING Approval Comments ");
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        // dispatch({
        //   type: FILING_MASTER_LOADING,
        // });
        let url =
          formData.type === "filing"
            ? `${API_BASE_URL}/FilingMaster/FilingMasterWorkflowCommentsById/${formData.id}`
            : `${API_BASE_URL}/FilingMaster/FilingMasterWorkflowComments${formData.id}`;
        const res = await axios.get(url);
        // console.log("Comments ", res);
        dispatch({
          type: MASTER_COMMENTS_LOADED,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const getFilingMasterWorkflowCommentsAttachments = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("FETCHING Approval Comments Attached");
        const res = await axios.get(
          `${API_BASE_URL}/FilingMaster/ListFilingMasterWorkflowCommentsAttachments`
        );
        // console.log("Comments ", res);

        if (res && res.status === 200 && res.data) {
          dispatch({
            type: MASTER_COMMENTS_ATTACHMENTS_LOADED,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const setFilingMasterApprovalsPending = (filingMaster) => {
    dispatch({
      type: FILING_MASTER_APPROVAL_LOADED,
      payload: filingMaster,
    });
  };

  const setFilingMasterApprovalsCompleted = (filingMaster) => {
    dispatch({
      type: FILING_MASTER_APPROVAL_COMPLETED_LOADED,
      payload: filingMaster,
    });
  };

  //Create Filing master Businesss Categories
  const getFilingMasterApprovals = async (formData) => {
    // console.log("FETCHING FilingMasterApprovals ", formData);
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        let API_URL = "";
        if (formData?.approverId) {
          API_URL = `${API_BASE_URL}/FilingMaster/FilingMasterWorkflowList?ApproverId=${formData.approverId}&RecordType=${formData.recordType}`;
        } else {
          API_URL = `${API_BASE_URL}/FilingMaster/FilingMasterWorkflowList?RecordType=${formData.recordType}`;
        }
        const res = await axios.get(API_URL);
        // console.log("Filing Master Approvals ", res);
        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: FILING_MASTER_APPROVAL_LOADED,
            payload: res.data.data,
          });
          // console.log("Filing Master Approvals res.data.data");
          resolve(res.data.data);
        } else if (res && res.data) {
          dispatch({
            type: FILING_MASTER_APPROVAL_LOADED,
            payload: res.data,
          });
          // console.log("Filing Master Approvals res.data");
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Create Filing master Businesss Categories
  const createFilingMasterCategories = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/FilingMaster/CreateFilingBusinessCategory`,
          formData
        );
        // console.log("FilingMasters Categories", res);
        // dispatch({
        //   type: UPDATE_FILING_MASTER_CATEGORY,
        //   payload: res.data,
        // });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Create Filing master Draft Businesss Categories
  const createFilingMasterDraftCategories = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/FilingMaster/CreateFilingDraftBusinessCategory`,
          formData
        );
        // console.log("FilingMasters Draft Categories", res);
        // dispatch({
        //   type: UPDATE_FILING_MASTER_CATEGORY,
        //   payload: res.data,
        // });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const getApproverList = async () => {
    // console.log("FETCHING Approver List");
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(
          `${API_BASE_URL}/Application/ListApprovers`
        );
        // console.log("Approvers List ", res);
        dispatch({
          type: APPROVER_LIST_LOADED,
          payload: res.data.data,
        });
        resolve(res.data.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const updateFilingMasterWorkflow = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("FETCHING", data);
        const res = await axios.put(
          `${API_BASE_URL}/FilingMaster/FilingMasterWorkflowUpdate${data.workflowId}`,
          data
        );
        // console.log("Workflow Updated", res);
        resolve(true);
      } catch (err) {
        reject(false);
      }
    });
  };

  const changeFilingMasterApprover = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("PAYLOAD", data);
        const res = await axios.put(
          `${API_BASE_URL}/FilingMaster/FilingMasterChangeApprover`,
          data
        );
        // console.log("Workflow Updated", res);
        if (res && res.data && res.data.status === "Success") {
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        reject(false);
      }
    });
  };

  const getFilingMasterWorkflow = async (workflowId) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("FETCHING", workflowId);
        const res = await axios.get(
          `${API_BASE_URL}/FilingMaster/FilingMasterWorkflowList/${workflowId}`
        );
        // console.log("Workflow Fetched", res);
        resolve(res.data);
      } catch (err) {
        reject(false);
      }
    });
  };
  //Fetch Program Names List
  const getPostProgram = async (formData) => {
    // console.log("FETCHING Program Names");
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: POST_PROGRAM_LOADING,
        });
        const res = await axios.get(
          `${API_BASE_URL}/Application/ListTracknowProgram`
        );
        // console.log("Program Names ", res);
        dispatch({
          type: POST_PROGRAM_LOADED,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: POST_PROGRAM_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  //Add a Program Name
  const addPostProgram = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });

        let res = null;

        res = await axios.post(
          `${API_BASE_URL}/Application/CreateTracknowProgram`,
          formData
        );

        // console.log("BusinessCategory add ", res);
        await getPostProgram();
        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: POST_PROGRAM_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  //Update a Program Names
  const updatePostProgram = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });

        let res = null;

        res = await axios.put(
          `${API_BASE_URL}/Application/UpdateTracknowProgram/${formData.programId}`,
          formData
        );

        // console.log("Program Name Modify ", res);
        await getPostProgram();

        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: POST_PROGRAM_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  //Delete a Program Names
  const deletePostProgram = async (formData, userId) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: FILING_MASTER_LOADING,
        });

        let res = await axios.delete(
          `${API_BASE_URL}/Application/DeleteTracknowProgram/${formData.programId}/${userId}`
        );

        // console.log("ProgramName delete ", res);
        await getPostProgram();
        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        if (res && res.data) {
          resolve(res.data);
        } else {
          reject({ message: "Unexpected response format", response: res });
        }
      } catch (err) {
        dispatch({
          type: POST_PROGRAM_ERROR,
          payload: err,
        });

        // Return the error for rejection
        reject(err.response ? err.response.data : { message: "An error occurred", error: err });
      }
    });
  };

  //Fetch Awarding Agencies List
  const getAwardingAgencies = async () => {
    // console.log("FETCHING Awarding Agencies ");
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch({
        //   type: FILING_MASTER_LOADING,
        // });
        const res = await axios.get(
          `${API_BASE_URL}/Application/ListAwardingAgencies`
        );
        // console.log("Awarding Agencies ", res);
        // dispatch({
        //   type: BUSINES_CATEGORY_LOADED,
        //   payload: res.data,
        // });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  return (
    <FilingMasterContext.Provider
      value={{
        ...state,
        resetFilingMasterState,
        setCurrentFilingMaster,
        // clearCurrentFilingMaster,
        getFilingMasters,
        createFilingMaster,
        getBusinessCategories,
        getPostProgram,
        getProgramName,
        createFilingMasterCategories,
        createFilingMasterDraftCategories,
        getFilingMasterApprovals,
        createPostAwardWorkflow,
        createDraftFilingMaster,
        createClientDraftFilingMaster,
        deleteDraftFilingMaster,
        createFilingMasterWorkflow,
        createClientFilingMasterWorkflow,
        GeneratePostAwardMasterWorkflowForClient,
        approveFilingMasterWorkflow,
        approveBulkFilingMasterWorkflow,
        rejectBulkFilingMasterWorkflow,
        approveBulkPostAwardWorkflow,
        rejectBulkPostAwardWorkflow,
        getFilingMasterComments,
        addFilingMasterComment,
        addBusinessCategory,
        updateBusinessCategory,
        deleteBusinessCategory,
        addFilingMasterWorkflowComment,
        getFilingMasterWorkflowComments,
        // updateFilingMaster,
        // loadFilingMaster,
        // filingMasterDispatch: dispatch,
        // removeFilingMasters,
        getApproverList,
        updateFilingMasterWorkflow,
        getFilingMasterWorkflow,
        getFilingMasterWorkflowCommentsAttachments,
        changeFilingMasterApprover,
        addPostProgram,
        updatePostProgram,
        deletePostProgram,
        getAwardingAgencies,
        setFilingMasters,
        setFilingMasterApprovalsPending,
        setFilingMasterApprovalsCompleted,
      }}
    >
      {props.children}
    </FilingMasterContext.Provider>
  );
};

export default FilingMasterState;
