import React, { Fragment, useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ShortTextIcon from "@mui/icons-material/ShortText";
import EditIcon from '@mui/icons-material/Edit';
import ListItemIcon from "@mui/material/ListItemIcon";
import CategoryIcon from "@mui/icons-material/Category";
import DeleteIcon from "@mui/icons-material/Delete";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import Tooltip from '@mui/material/Tooltip';
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import { Checkbox } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Anchor } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import CustomerContext from "../../context/customer/CustomerContext";
import AuthContext from "../../context/auth/AuthContext";
import states from "../../data/StateLookup.json";
import MultiSelect from "../common/MultiSelect";
import Alert from "@mui/material/Alert";
import useDialog from "../common/UseDialog";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import _ from "lodash";
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CardHeader,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {
    ClientFilingTracker,
    ClientFilingStaffList,
    clientFilingConsolidatedStatus,
    ClientFilingStaffListNew,
} from "../../types/ClientFilingMaster";
import {
    Customer,
    BusinessCategory,
    CustomerCategory,
} from "../../types/customer";
import { resolve } from "path";
import usePermission from "../../usePermission";
import axios from "axios";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from "dayjs";
type Anchor = "top" | "left" | "bottom" | "right";

export default function ClientFilingConsolidatedUpdate(props: {
    dockAt?: "top" | "left" | "bottom" | "right";
    show?: boolean;
    handleClose: () => void;
    cust: ClientFilingTracker;
    consolidatedRecords?: ClientFilingTracker[];
    consolidatedStatus?: clientFilingConsolidatedStatus[];
    selectedClientId?: any;
    staffList?: ClientFilingStaffList[];
    handleRefreshTable: () => void;
    refreshConsList: () => void;
}) {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const authContext = useContext(AuthContext);
    const { authUser, userRoles } = authContext;
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [affectedCompaniesError, setAffectedCompaniesError] = React.useState(false)
    const [showSuccess, setShowSuccess] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [isLoadingList, setIsLoadingList] = useState(true);
    const [data, setData] = React.useState<ClientFilingTracker[]>([]);
    const [consolidatedStatusRec, setConsolidatedStatusRec] = useState<clientFilingConsolidatedStatus>({})
    const [childCompanyList, setChildCompanyList] = useState<any[]>([])
    const [staffListOptions, setStaffListOptions] = useState<any[]>([])
    const [staffAssignedList, setStaffAssignedList] = useState<any[]>([])
    const [selectedAffectedCompanies, setSelectedAffectedCompanies] = useState<any[]>([])
    const [selectedStaffAssigned, setSelectedStaffAssigned] = useState<any[]>([])
    const [selectedStatus, setSelectedStatus] = useState<string>("")
    const [selectedGrantTermCom, setSelectedGrantTermCom] = useState<string>("")
    const [selectedInternalDueDate, setSelectedInternalDueDate] = useState<Date | null>(null);
    const [selectedFilingDueDate, setSelectedFilingDueDate] = useState<Date | null>(null);
    const [isTableEdit, setIsTableEdit] = React.useState(false);
    const [isDisableGrantTerm, setIsDisableGrantTerm] = React.useState(true)
    const [sameFilingList, setSameFilingList] = useState<any[]>([])
    const [checkedItems, setCheckedItems] = useState<number[]>([]);
    const [isFilingDelete, setIsFilingDelete] = React.useState(false)
    const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

    const statusDropdown = [
        { name: 'Pending', code: 'Pending' },
        { name: 'In Progress', code: 'In Progress' },
        { name: 'Approved', code: 'Approved' },
        { name: 'Completed', code: 'Completed' },
    ];
    const GrantTermcompleteDropdown = [
        { name: "Yes", code: "Yes" },
        { name: "No", code: "No" },
    ];



    const closeAlert = () => {
        setShowAlert(false);
        props.handleClose();
        // navigate("/");
    };





    const footerContent = (
        <div>
            <Button
                label='OK'
                icon='pi pi-check'
                onClick={() => closeAlert()}
                autoFocus
            />
        </div>
    );

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                console.log(`Drawer Mouse Key Event...`);
                // if (
                // event.type === "keydown" &&
                // ((event as React.KeyboardEvent).key === "Tab" ||
                // (event as React.KeyboardEvent).key === "Shift")
                // ) {
                // return;
                // }
                // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
                // setState({ ...state, [anchor]: open });
                // if (!open) props.handleClose();
            };

    const handleSubmit = () => {
        closeDialog();
        setState({
            ...state,
            [props.dockAt ? props.dockAt : "right"]: true,
        });
        setSelectedGrantTermCom("Yes")

    }

    const closeGrantTermConfirm = () => {

        closeDialog();
        setState({ ...state, [props.dockAt ? props.dockAt : "right"]: true });
        setSelectedGrantTermCom("No")
    }
    const closeDeleteConfirm = () => {
        closeDialog();
        setState({ ...state, [props.dockAt ? props.dockAt : "right"]: true });
        setIsFilingDelete(false)
    }


    const { AlertDialog, showDialog, closeDialog } = useDialog({
        onSubmit: () => {

            if (isFilingDelete) {
                handleDeferSubmit();
            } else {
                handleSubmit();
            }
        },
        onClose: () => {
            if (isFilingDelete) {
                closeDeleteConfirm();
            } else {
                closeGrantTermConfirm();
            }
        }
    });
    useEffect(() => {
        if (
            typeof props.dockAt !== "undefined" &&
            typeof props.show !== "undefined"
        ) {
            console.log(
                `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
            );
            // toggleDrawer(props.dockAt, props.show);
            if (props.show) setState({ ...state, [props.dockAt]: props.show });
        }
        // setApprovalComment((old) => "");
        // if (!props.show) {
        //     setInitCategories(() => []);
        // }
        // if (getCustomerComments && props.cust && props.cust.customerId) {
        // console.log(`Fetching Commnet for :${props.cust.customerId}`);
        // fetchCustomerComments(props.cust.customerId);
        // }
        //eslint-disable-next-line
    }, [props.show]);


    useEffect(() => {
        let _data: ClientFilingTracker[] = props.consolidatedRecords!;
        let staffListMap: { name: string, value: string, loginId: string, }[] = [];
        if (props.consolidatedRecords && props.show) {
            setIsLoadingList(true)
            //setSourceData(() => _data);
            _data.forEach((item) => {
                item.internalDueDate = item.dueDate ? new Date(item.dueDate) : null;
            })
            setData(() => _data);
            const childList: { name: string, code: number }[] = props.consolidatedRecords.map((cust) => ({
                name: cust.customerName || '',
                code: cust.customerId !== undefined ? cust.customerId : 0,
                company: cust.isParentCompany ? "Parent" : "Child",
                isDisabled: cust.grantTermComplete && cust.status === "Completed" ? true : false

            }))
            // if (props.staffList) {
            //     props.staffList.forEach((staff) => {
            //         // Push objects with 'name' and 'code' properties to staffListMap
            //         staffListMap.push({
            //             name: staff.name || '',
            //             value: staff.code !== undefined ? staff.code.toString() : "",
            //             loginId: staff.loginId || "",
            //         });
            //     });
            // }
            setChildCompanyList(childList)

            // Set the first object from props.consolidatedStatus to setConsolidatedStatusRec
            if (props.consolidatedStatus && props.consolidatedStatus.length > 0) {
                setConsolidatedStatusRec(props.consolidatedStatus[0]);
            }
            setIsLoadingList(false)

            console.log("consolidatedRecords", props.consolidatedRecords, "staffList", props.staffList, "consolidatedStatus", props.consolidatedStatus, "consolidatedStatusRec", consolidatedStatusRec, "childList", childList, "statusDropdown", statusDropdown, "staffListMap", staffListMap, "data", _data);

        }
        //eslint-disable-next-line
    }, [props.consolidatedRecords, props.show]);
    useEffect(() => {
        if (selectedAffectedCompanies.length > 0 || selectedStatus !== "" || selectedStaffAssigned.length > 0 || selectedFilingDueDate !== null || selectedGrantTermCom !== "") {
            console.log("Trigger 1");

            setAlertMessage("");
            setShowError(false);
            setShowSuccess(false);
            setCheckedItems([]);
        }
    }, [selectedAffectedCompanies, selectedStatus, selectedStaffAssigned, selectedGrantTermCom, selectedFilingDueDate])

    useEffect(() => {
        if (userRoles) {
            //Display staff assigned options based on selected customer ID
            const selectedCompaniesString = selectedAffectedCompanies.map(company => String(company));

            const staffListUpd = userRoles.filter((ele) => {
                if (ele.roleName === "Admin" || ele.roleName === "Admin-PostAward") {
                    return ele;
                }
                else if (selectedCompaniesString.includes(String(ele.customerId))) {
                    return ele;
                }
            }).map((ele) => ({
                name: ele.userName ? ele.userName.charAt(0).toUpperCase() + ele.userName.slice(1) : "",
                value: ele.userId !== undefined ? ele.userId.toString() : "",
                loginId: ele.loginId,
                customerId: ele.customerId ? ele.customerId : null,
                roleName: ele.roleName,

            }))
            // .filter(
            //     (value, index, self) =>
            //         index === self.findIndex((t) => t.name === value.name)
            // );
            staffListUpd.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            // console.log("useEffect staffListUpd", staffListUpd);

            setStaffAssignedList(staffListUpd)
            const staffListOpt = staffListUpd.filter(
                (value, index, self) =>
                    index === self.findIndex((t) => t.name === value.name)
            );
            setStaffListOptions(staffListOpt)
            // console.log("useEffect - Filter Staff assigned ", "staffListOpt", staffListOpt, "selectedAffectedCompanies", selectedAffectedCompanies, "userRoles", userRoles);

        }

    }, [selectedAffectedCompanies])
    //   useEffect(() => {
    //     if (sourceData) {
    //       setIsLoadingList(true);S
    //       try {
    //         const _data2 = sourceData.filter((item) => item.state !== null);
    //         const _data3 = _.sortBy(_data2, [
    //           (item) => item.state,
    //           (item) => item.businessCategoryName,
    //         ]);
    //         console.log("@@Ggroup Cust Bus Cats:", _data3);

    //         setData(() => _data3);
    //       } finally {
    //         setIsLoadingList(false);
    //       }
    //     }

    //     //eslint-disable-next-line
    //   }, [sourceData]);

    useEffect(() => {
        console.log("props.cust", props.cust)
        // if(props.cust && props.cust.createDate!==null)
        //eslint-disable-next-line
    }, [props.cust]);

    useEffect(() => {
        if (isTableEdit) {

            if (selectedStatus === "Completed") {
                setIsDisableGrantTerm(false)
            } else {
                console.log("grant check", "useEffect 1.1", selectedGrantTermCom);

                setIsDisableGrantTerm(true)
                setSelectedGrantTermCom("No")
            }
        }
    }, [selectedStatus, isTableEdit])


    const handleMultiChangeAffectedCompanies = (
        values: string[],
        keys: any,
        action: string

    ) => {
        if (values) {
            console.log("@@Changed handleMultiChangeAffectedCompanies:", values, keys, action);
            // setSelectedCategories((old) => values);
            // setDataChanged(() => hasDataChanged(values));
            // setBussinessCatError(false);
            // formik.setFieldValue("businessCategoryId", selectedCategories, false);
            setSelectedAffectedCompanies(values)

        }
    };
    const handleSingleChange = (
        event: React.ChangeEvent<{ value: unknown }>, // Adjust the event type
        //child: React.ReactNode // Adjust the child type
    ) => {
        console.log("@@Changed handleSingleChange: e", event);

        const values = event.target.value as string[]; // Extract values from event
        if (values) {
            console.log("@@Changed handleSingleChange:", values);
            setSelectedAffectedCompanies(values);
        }
    };

    const handleChangeStaffAssigned = (
        values: string[],
        keys: any,
        action: string

    ) => {
        if (values) {
            console.log("@@Changed handleMultiChangeAffectedCompanies:", values, keys, action);
            // setSelectedCategories((old) => values);
            // setDataChanged(() => hasDataChanged(values));
            // setBussinessCatError(false);
            // formik.setFieldValue("businessCategoryId", selectedCategories, false);
            setSelectedStaffAssigned(values)

        }
    };

    // const handleCheckboxChange = (fileTrackingId: number | undefined) => {
    //     if (fileTrackingId) {
    //         if (checkedItems.includes(fileTrackingId)) {
    //             setCheckedItems(prevState => prevState.filter(id => id !== fileTrackingId));
    //         } else {
    //             setCheckedItems(prevState => [...prevState, fileTrackingId]);
    //         }
    //         // setIsTableEdit(false)
    //         // setSelectedStaffAssigned([])
    //         // setSelectedStatus("")
    //         // setSelectedInternalDueDate(null)
    //         // setSelectedAffectedCompanies([]);
    //         // setSelectedGrantTermCom("")
    //         // setIsDisableGrantTerm(true)
    //         // setAffectedCompaniesError(false)
    //         // setShowError(false);
    //         // setShowSuccess(false)
    //         // setShowInfo(false)
    //         // setAlertMessage("")
    //     }
    // };

    const handleCheckboxChange = (fileTrackingId: number | undefined) => {
        if (fileTrackingId === undefined) return;
        const currentIndex = checkedItems.indexOf(fileTrackingId);
        const newChecked = [...checkedItems];

        if (currentIndex === -1) {
            newChecked.push(fileTrackingId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedItems(newChecked);
    };
    const handleSelectAll = () => {
        const enabledItems = data.filter(item => item.status !== "Completed" && item.status !== "Approved");
        const enabledFileTrackingIds = enabledItems.map(item => Number(item.fileTrackingId));

        if (checkedItems.length === enabledFileTrackingIds.length) {
            setCheckedItems([]);
        } else {
            setCheckedItems(enabledFileTrackingIds);
        }
    };

    console.log("sel check", checkedItems);

    const handleChangeStatus = (
        event: React.ChangeEvent<{ value: unknown }>, // Adjust the event type
        //child: React.ReactNode // Adjust the child type
    ) => {
        console.log("@@Changed handleChangeStatus: e", event);

        const values = event.target.value as string; // Extract values from event
        if (values) {
            console.log("@@Changed handleChangeStatus:", values);
            setSelectedStatus(values);
        }
    };





    const handleGrantTermComChange = (
        event: React.ChangeEvent<{ value: unknown }>,
        //child: React.ReactNode // Adjust the child type
    ) => {
        // console.log("@@Changed handleGrantTermComChange: e", event);

        const values = event.target.value as string; // Extract values from event
        // console.log("@@Changed handleGrantTermComChange: ", "values", values);
        if (values === "Yes") {
            // const filteredList = sameFilingList.filter(item => item.startDate > consolidatedStatusRec && consolidatedStatusRec.startDate);
            // console.log("@@Changed handleGrantTermComChange: ", "sameFilingList", sameFilingList, "consolidatedStatusRec", consolidatedStatusRec);

            const lowestStartDateRecord = sameFilingList.filter(
                (ele) =>
                    new Date(ele.startdate) < new Date(consolidatedStatusRec.startdate ? consolidatedStatusRec.startdate : "")
            );
            let isPending = lowestStartDateRecord.find(
                (ele) => ele.status === "Pending"
            );

            // console.log("@@Changed handleGrantTermComChange: ", "lowestStartDateRecord", lowestStartDateRecord, "isPending", isPending);
            if (isPending) {
                setAlertMessage("The record is in pending status for one or more previous periods. Please complete them before marking Grant Term Completed for the record")
                setShowInfo(true)
                setSelectedGrantTermCom("No")
            } else {

                setState({ ...state, [props.dockAt ? props.dockAt : "right"]: false });
                showDialog(
                    "Confirmation",
                    "Marking Grant Term Completed is not reversible and all upcoming records will be removed. Are you sure you want to continue?",
                    "confirm"
                );
            }
            // console.log("@@Changed handleGrantTermComChange:", values);
        } else {
            setSelectedGrantTermCom(values)
        }


    };
    // const handleInternalDueDateChange = (
    //     date: any
    // ) => {
    //     // console.log("handleInternalDueDateChange", "date", date, "d", date.$d);
    //     setSelectedInternalDueDate(date.$d)

    // };

    const handleFilingDueDateChange = (date: any) => {
        // console.log("handleFilingDueDateChange", "date", date, "d", date.$d);
        setSelectedFilingDueDate(date.$d)
    };

    const renderStaffList = (staffList: any[]) => {

        return (
            <ul style={{ fontSize: "1rem", width: "100%", padding: "0 1rem" }}>
                {staffList.map((staff: { userName: string; loginId: string }, staffIndex: React.Key | null | undefined) => (
                    <li key={staffIndex}>{`${staff.userName ? staff.userName.charAt(0).toUpperCase() + staff.userName.slice(1) : ""} - ${staff.loginId ? staff.loginId : ""}`}</li>))}
            </ul>
        );
    };
    const renderCustomerName = (item: any) => {
        console.log("renderCustomerName item", item);

        return item.isParentCompany ? `${item.customerName} - (Parent)` : item.customerName
    };

    const IsDisabled = () => {
        return !usePermission(
            "ClientFilingList",
            "editConsolidated",
            props.selectedClientId

        )
    }

    const hasChangeStatusPermission = usePermission(
        "ClientFilingList",
        "changeStatus",
    );
    const hasStaffAssignedPermission = usePermission(
        "ClientFilingList",
        "changeStaff",
    );
    // const hasDeferClientSpecificFiling = usePermission(
    //     "ClientFilingList",
    //     "deleteClientSpecificFiling",
    //     props?.cust?.isMasterFiling
    // );
    // console.log("Permission check", "status", hasChangeStatusPermission, "Staff ", hasStaffAssignedPermission, "Defer", hasDeferClientSpecificFiling);


    const formatDate = (value: any) => {
        if (value !== null) {
            const date = new Date(value);
            return date.toLocaleDateString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
        }
        return null;
    };
    const formatDayJs = (value: any) => {
        const formtDueDate = value !== null ? dayjs(value) : null
        return formtDueDate;
    };

    const list = (anchor: Anchor) => (
        <Fragment>
            <Dialog
                header='Info'
                visible={showAlert}
                style={{ width: "35vw" }}
                onHide={() => closeAlert()}
                footer={footerContent}
            >
                <p className='m-0'>{alertMessage}</p>
            </Dialog>
            <Grid container>
                <Stack
                    sx={{
                        width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
                        minWidth: consolidatedStatusRec?.recordType === "TrackNow" ? "50vw" : "60vw",
                        display: "flex",
                        alignContent: "center",
                    }}
                    role='presentation'
                    onClick={toggleDrawer(anchor, false)}
                    onKeyDown={toggleDrawer(anchor, false)}
                >
                    <Card>
                        <CardHeader>
                            <Typography gutterBottom variant='h5' component='div' sx={{ "padding": 0 }}>
                                Consolidated update
                            </Typography>
                        </CardHeader>
                        <CardContent>
                            <Stack direction='row' justifyContent='space-between'>
                                <Typography
                                    variant='h6'
                                    color='text.secondary'
                                    style={{ fontWeight: "600" }}
                                >
                                    Consolidated view
                                </Typography>
                                <IconButton>
                                    <CloseIcon onClick={() => handleCancel(anchor)} />
                                </IconButton>
                            </Stack>
                            <Stack direction="column" spacing={0.5}>
                                <Stack direction="row">
                                    <Typography style={{ fontWeight: "600", width: "maxContent", whiteSpace: "nowrap" }} >Filing Name:</Typography>
                                    <Typography style={{ marginLeft: "1rem" }}> {consolidatedStatusRec && consolidatedStatusRec?.filingName}</Typography>
                                </Stack>
                                <Stack direction="row">
                                    <Typography style={{ fontWeight: "600", width: "maxContent", whiteSpace: "nowrap" }} >
                                        Consolidated Status:
                                    </Typography>
                                    <Typography style={{ marginLeft: "1rem" }}> {consolidatedStatusRec && consolidatedStatusRec?.consolidateStatus}</Typography>
                                </Stack>
                            </Stack>
                        </CardContent>
                        <CardContent>
                            <FormControl fullWidth variant="standard" sx={{ width: "100%" }} className="affectedCompanyForm" >
                                <Typography
                                    sx={{ minHeight: "0.5rem" }}
                                // color={stateError ? "error" : "inherit"}
                                >
                                    Affected Companies
                                </Typography>
                                {isTableEdit ? (
                                    <Select
                                        id='affectedCompany'
                                        name='Affected Company'
                                        value={selectedAffectedCompanies.length > 0 ? selectedAffectedCompanies : []}
                                        onChange={(event: any) => handleSingleChange(event)}
                                        label='Affected Company'
                                        // disabled={IsDisabled()}
                                        disabled={isTableEdit}
                                    >
                                        {childCompanyList.map((item) => (
                                            <MenuItem key={item.code} value={item.code}>
                                                {item.company === "Parent" ? `${item.name} - (${item.company})` : item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                ) : (
                                    <MultiSelect
                                        inputs={
                                            // props.categoriesLookup &&
                                            // props.categoriesLookup.map((item) => ({
                                            //     name: item.businessCategoryName
                                            //         ? item.businessCategoryName
                                            //         : "",
                                            //     value: item.businessCategoryId
                                            //         ? item.businessCategoryId + ""
                                            //         : "",
                                            // }))
                                            childCompanyList && childCompanyList.map((child) => ({
                                                //  name: child.name ? child.name : "",
                                                name: child.name ? (child.company === "Parent" ? `${child.name} - (${child.company})` : child.name) : "",
                                                value: child.code ? child.code : 0,
                                                isDisabled: child.isDisabled
                                            }))

                                        }

                                        initValues={selectedAffectedCompanies}
                                        //  values={selectedAffectedCompanies}
                                        // initValues={initCategories}
                                        updateSelection={handleMultiChangeAffectedCompanies}

                                    />
                                )}
                                {affectedCompaniesError && (
                                    <Typography variant='caption' color='error'>
                                        Please select the child company
                                    </Typography>
                                )}
                            </FormControl>
                            <FormControl fullWidth variant="standard" sx={{ mt: "0.5rem", width: "100%" }} className="staffAssignedForm"
                            >
                                {/* <InputLabel id='customer-category-label'>
    Customer Category
    </InputLabel> */}
                                <Typography
                                //sx={fontStyle}
                                >
                                    Staff Assigned
                                </Typography>


                                <MultiSelect

                                    inputs={staffListOptions.map(item => ({
                                        // name: item.name || "",
                                        name: `${item.name} - ${item.loginId}`,
                                        value: item.value ? item.value : ""

                                    }))}
                                    values={selectedStaffAssigned}
                                    initValues={selectedStaffAssigned}
                                    updateSelection={handleChangeStaffAssigned}
                                    isdisabled={selectedAffectedCompanies.length === 0 || !hasStaffAssignedPermission}
                                />


                                {/* {bussinessCatError && (
                                    <Typography variant='caption' color='error'>
                                        Business Category is required
                                    </Typography>
                                )} */}
                            </FormControl>
                            <FormControl fullWidth variant="standard" sx={{ mt: "0.5rem", width: "100%" }} className="statusForm"
                            >
                                {/* <InputLabel id='customer-category-label'>
    Customer Category
    </InputLabel> */}
                                <Typography
                                //sx={fontStyle}
                                >
                                    Status</Typography>
                                <Select
                                    id='status'
                                    name='status'
                                    value={selectedStatus}
                                    onChange={(event: any) => handleChangeStatus(event)}
                                    label='Status'

                                    disabled={selectedAffectedCompanies.length === 0 || !hasChangeStatusPermission}
                                >
                                    {statusDropdown.map((item) => (
                                        <MenuItem key={item.code} value={item.code}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {/* {bussinessCatError && (
                                    <Typography variant='caption' color='error'>
                                        Business Category is required
                                    </Typography>
                                )} */}
                            </FormControl>

                            {consolidatedStatusRec?.recordType === "PostAward" && (
                                <FormControl fullWidth variant="standard" sx={{ mt: "0.5rem", width: "100%" }} className="grantTermComplete"
                                >
                                    {/* <InputLabel id='customer-category-label'>
Customer Category
</InputLabel> */}
                                    <Typography
                                    //sx={fontStyle}
                                    >
                                        Grant Term Complete</Typography>
                                    <Select
                                        id='grantTermComplete'
                                        name='grantTermComplete'
                                        value={selectedGrantTermCom}
                                        onChange={(event: any) => handleGrantTermComChange(event)}
                                        label=' Grant Term Complete'
                                        disabled={!isTableEdit || isDisableGrantTerm}
                                    >
                                        {GrantTermcompleteDropdown.map((item) => (
                                            <MenuItem key={item.code} value={item.code}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {/* {bussinessCatError && (
          <Typography variant='caption' color='error'>
              Business Category is required
          </Typography>
      )} */}
                                </FormControl>
                            )}
                            <div style={{ display: "flex" }}>
                                {/* {consolidatedStatusRec?.recordType === "PostAward" && (
                                < FormControl fullWidth variant="standard" sx={{ mt: "0.5rem", width: "50%" }} className="grantTermComplete"
                                >
                                    <Typography
                                    //sx={fontStyle}
                                    >
                                        Internal Due Date</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer components={[
                                            'DatePicker',
                                        ]}>
                                            <DemoItem  >
                                                <DatePicker disabled={selectedAffectedCompanies.length === 0} onChange={handleInternalDueDateChange} value={formatDayJs(selectedInternalDueDate)} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                            )} */}
                                {consolidatedStatusRec?.recordType === "PostAward" && (
                                    <FormControl fullWidth variant="standard" sx={{ mt: "0.5rem", width: "50%" }} className="grantTermComplete">
                                        <Typography>Filing Due Date</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DemoContainer components={['DatePicker',]}>
                                                <DemoItem>
                                                    <DatePicker
                                                        disabled={selectedAffectedCompanies.length === 0}
                                                        onChange={handleFilingDueDateChange}
                                                        value={formatDayJs(selectedFilingDueDate)}
                                                    />
                                                </DemoItem>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </FormControl>
                                )}
                            </div>
                        </CardContent>
                        {/* <CardActions>
                            <ConfirmPopup
                                // target={buttonEl.current}
                                visible={visible}
                                onHide={() => setVisible(false)}
                                message=" Marking Grant Term Completed is not reversible and all upcoming
                                records will be removed. Are you sure you want to continue?"
                                icon="pi pi-exclamation-triangle"
                                accept={accept}
                                reject={reject}
                                style={{ zIndex: 1500 }}
                            />
                        </CardActions> */}
                        <CardActions
                            sx={{
                                mt: "1.5rem",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography sx={{ ml: "20px", fontSize: "1.3rem", fontWeight: "500" }}>
                                Deferred
                            </Typography>
                            <CardActions>
                                <MuiButton
                                    variant='contained'
                                    // color='secondary'
                                    sx={{ margin: 1, padding: 1, backgroundColor: '#0063cc', }}
                                    onClick={() => handleDefer(anchor)}
                                    disabled={checkedItems.length === 0 }
                                >
                                    DEFER
                                </MuiButton>
                                <MuiButton
                                    variant='contained'
                                    // color='secondary'
                                    sx={{ margin: 1, padding: 1, backgroundColor: '#0063cc', }}
                                    onClick={() => handleClearForm(anchor)}
                                >
                                    CLEAR
                                </MuiButton>
                                <MuiButton
                                    variant='contained'
                                    color='secondary'
                                    sx={{ margin: 1, padding: 1 }}
                                    onClick={() => handleCancel(anchor)}
                                >
                                    CANCEL
                                </MuiButton>
                                <MuiButton
                                    variant='contained'
                                    type='submit'
                                    // sx={{ margin: 1, padding: 1 }}
                                    sx={{
                                        margin: 1,
                                        padding: 1,
                                        backgroundColor: "#0b3edf",
                                    }}
                                    disabled={IsDisabled()}
                                    onClick={() => handleOk(anchor)}
                                >
                                    SAVE
                                </MuiButton>
                            </CardActions>
                        </CardActions>
                        <CardContent
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {showError && <Alert severity='error'>{alertMessage}</Alert>}
                            {showSuccess && <Alert severity='success'>{alertMessage}</Alert>}
                            {showInfo && <Alert severity="warning">{alertMessage}</Alert>}
                        </CardContent>
                        <CardContent>
                            <Grid container spacing={1} textAlign="center">
                                <Grid item xs={consolidatedStatusRec?.recordType === "TrackNow" ? 0.7 : 0.6}>
                                    <ListItemIcon >
                                        <Checkbox

                                            checked={(checkedItems.length === data.filter(item => item.status !== "Completed" && item.status !== "Approved").length) && data.filter(item => item.status !== "Completed" && item.status !== "Approved").length !== 0}
                                            onChange={handleSelectAll}
                                           // disabled={!hasDeferClientSpecificFiling}
                                        />
                                    </ListItemIcon>
                                </Grid>
                                <Grid item xs={consolidatedStatusRec?.recordType === "TrackNow" ? 4 : 3}>
                                    <Typography sx={{ fontWeight: "600" }} >
                                        Affected Companies for Deferrals
                                    </Typography>
                                </Grid>
                                <Grid item xs={consolidatedStatusRec?.recordType === "TrackNow" ? 4.3 : 2.4}>
                                    <Typography sx={{ fontWeight: "600" }} >
                                        Staff Assigned
                                    </Typography>
                                </Grid>
                                <Grid item xs={consolidatedStatusRec?.recordType === "TrackNow" ? 2.5 : 1.8}>
                                    <Typography sx={{ fontWeight: "600" }} >
                                        Status
                                    </Typography>
                                </Grid>
                                {/* {consolidatedStatusRec?.recordType === "PostAward" && (<Grid item xs={1.6}>
                                    <Typography sx={{ fontWeight: "600" }} >
                                        Internal Due Date
                                    </Typography>
                                </Grid>
                                )} */}
                                {consolidatedStatusRec?.recordType === "PostAward" && (<Grid item xs={2.3}>
                                    <Typography sx={{ fontWeight: "600" }} >
                                        Filing Due Date
                                    </Typography>
                                </Grid>
                                )}
                                {consolidatedStatusRec?.recordType === "PostAward" && (
                                    <Grid item xs={1.4}>
                                        <Typography sx={{ fontWeight: "600" }} >
                                            Grant Term Complete
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={0.5}>
                                    <Typography sx={{ fontWeight: "600" }} >
                                        Edit
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <List>
                                {isLoadingList ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            minHeight: "100px",
                                        }}
                                    >
                                        <CircularProgress size={40} />
                                    </div>
                                ) : (
                                    data &&
                                    data.map((item, index: number) => (
                                        <Fragment key={index} >
                                            <ListItem disablePadding >
                                                <Grid container spacing={1} textAlign="center" >
                                                    <Grid item xs={consolidatedStatusRec?.recordType === "TrackNow" ? 0.7 : 0.6}>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                checked={checkedItems.includes(Number(item.fileTrackingId))}
                                                                onChange={() => handleCheckboxChange(item.fileTrackingId)}
                                                                disabled={item.status === "Completed" || item.status === "Approved"}
                                                            />
                                                        </ListItemIcon>
                                                    </Grid>

                                                    <Grid item xs={consolidatedStatusRec?.recordType === "TrackNow" ? 4 : 3}>
                                                        {/* <Tooltip target=".custom-tooltip-btn">
                                                        <div>test</div>            
                                                    </Tooltip> */}
                                                        <ListItemText
                                                            primary={
                                                                item && item.customerName && item.customerName !== null
                                                                    ? <Tooltip title={renderCustomerName(item)} enterDelay={500} style={{ padding: "0 1rem" }}>
                                                                        <span style={{ padding: 0, width: "auto", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" }}>
                                                                            {item.isParentCompany ? `${item.customerName} - (Parent)` : item.customerName}
                                                                        </span>
                                                                    </Tooltip>
                                                                    : ""
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={consolidatedStatusRec?.recordType === "TrackNow" ? 4.3 : 2.4}>
                                                        <ListItemText
                                                            primary={
                                                                item &&
                                                                item.staffAssigned &&
                                                                item.staffAssigned.length > 0 && (
                                                                    <Tooltip title={renderStaffList(item.staffAssigned)} enterDelay={500} style={{ padding: "0 1rem" }}>
                                                                        <span style={{ padding: 0, width: "auto", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" }}>
                                                                            {item.staffAssigned
                                                                                .map((staff) => staff.userName && staff.userName.charAt(0).toUpperCase() + staff.userName.slice(1))
                                                                                .join(" , ")}
                                                                        </span>
                                                                    </Tooltip>
                                                                )
                                                            }

                                                        />
                                                    </Grid>
                                                    <Grid item xs={consolidatedStatusRec?.recordType === "TrackNow" ? 2.5 : 1.8}>
                                                        <ListItemText
                                                            primary={
                                                                item &&
                                                                    item.status &&
                                                                    item.status !== null
                                                                    ? item.status
                                                                    : ""
                                                            }

                                                        />
                                                    </Grid>
                                                    {/* {consolidatedStatusRec?.recordType === "PostAward" && (<Grid item xs={1.6}>
                                                        <ListItemText
                                                            primary={
                                                                item && item.internalDueDate && item.internalDueDate !== null
                                                                    ? formatDate(item.internalDueDate)
                                                                    : ""
                                                            }
                                                        />
                                                    </Grid>)} */}
                                                    {consolidatedStatusRec?.recordType === "PostAward" && (<Grid item xs={2.3}>
                                                        <ListItemText
                                                            primary={
                                                                item && item.filingDueDate && item.filingDueDate !== null
                                                                    ? formatDate(item.filingDueDate)
                                                                    : ""
                                                            }
                                                        />
                                                    </Grid>)}
                                                    {consolidatedStatusRec?.recordType === "PostAward" && (<Grid item xs={1.4}>
                                                        <ListItemText
                                                            primary={
                                                                item &&
                                                                    item.grantTermComplete &&
                                                                    item.grantTermComplete
                                                                    ? "Yes"
                                                                    : "No"
                                                            }
                                                        />
                                                    </Grid>)}
                                                    <Grid item xs={0.5}>
                                                        <ListItemIcon>
                                                            <ListItemButton
                                                                sx={{ display: "flex", justifyContent: "flex-end" }}
                                                                onClick={() => handleEdit(item)}
                                                                disabled={IsDisabled() || (item.grantTermComplete && item.status === "Completed")}
                                                            >

                                                                <EditIcon />
                                                            </ListItemButton>
                                                        </ListItemIcon>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </Fragment>
                                    ))
                                )}
                            </List>
                        </CardContent>
                    </Card>
                </Stack>
            </Grid>
        </Fragment >
    );
    interface User {
        name: string;
        value: string;
        loginId: string;
        customerId: number | null;
        roleName: string;
    }
    const removeDuplicates = (arr: any[]) => {
        const uniqueMap = new Map();
        const adminSet = new Set();

        arr.forEach(obj => {
            const key = `${obj.name}-${obj.value}-${obj.loginId}`;
            if (obj.roleName === "Admin" || obj.roleName === "Admin-PostAward") {
                adminSet.add(key);
                uniqueMap.set(key, obj);
            } else {
                if (!adminSet.has(key)) {
                    uniqueMap.set(key, obj);
                }
            }
        });

        return Array.from(uniqueMap.values());
    };


    const handleOk = async (anchor: Anchor) => {
        try {

            setIsLoadingList(true)
            let payload;



            // const formFilingDueDate = selectedFilingDueDate && selectedFilingDueDate !== null
            //     ? selectedFilingDueDate.getFullYear
            //         ? new Date(
            //             Date.UTC(
            //                 selectedFilingDueDate.getFullYear(),
            //                 selectedFilingDueDate.getMonth(),
            //                 selectedFilingDueDate.getDate()
            //             )
            //         )
            //         : new Date(selectedFilingDueDate)
            //     : null

            const utcFilingDate =
                selectedFilingDueDate !== null && selectedFilingDueDate.getFullYear
                    ? new Date(
                        Date.UTC(
                            selectedFilingDueDate.getFullYear(),
                            selectedFilingDueDate.getMonth(),
                            selectedFilingDueDate.getDate()
                        )
                    )
                    : selectedFilingDueDate;

            if (selectedAffectedCompanies.length === 0) {
                const isAffectedCompaniesEmpty = selectedAffectedCompanies.length === 0;
                setAffectedCompaniesError(isAffectedCompaniesEmpty);
                console.log("ErrComp", selectedAffectedCompanies, isAffectedCompaniesEmpty);

                setIsLoadingList(false)
                return;
            }
            const filteredChildCompany = data.filter(company => selectedAffectedCompanies.includes(company.customerId))

            if (selectedAffectedCompanies && (selectedStatus || selectedStaffAssigned)) {
                // filtering the selected staff from StaffList 
                const filteredStaffList = staffAssignedList
                    .filter(option => selectedStaffAssigned
                        .includes(option.value)).map(option => ({
                            ...option,
                            isDeleted: false
                        }));

                //Removed staff assigned assig
                const exsistingStaffAssignedIds = filteredChildCompany.flatMap(company =>
                    (company.staffAssigned || [])
                        .filter(staff => !selectedStaffAssigned.includes(String(staff?.userId)))
                        .map(staff => String(staff?.userId))
                );

                const filteredStaffAssignedList = staffAssignedList
                    .filter(item => exsistingStaffAssignedIds.includes(String(item.value)))
                    .map(item => ({
                        ...item,
                        isDeleted: true
                    }));

                const combainedStaff = [...filteredStaffList, ...filteredStaffAssignedList]

                //const extractRemovedStaffId = filteredChildCompany.staffAssigned.filter()
                //Removing the duplicate staff and keeping one staff If staff has both Admin and Admin-postAward 
                const filteredStaffListOptions = combainedStaff.filter((option, index, self) =>
                    index === self.findIndex((t) => (
                        t.name === option.name &&
                        t.value === option.value &&
                        t.loginId === option.loginId &&
                        t.customerId === option.customerId
                    ))
                );
                //Removing and keeping only one staff,If Staff has (Admin or Admin-PostAward) and any customerId
                const filt1 = filteredStaffListOptions.filter(option => option.roleName === "Admin" || option.roleName === "Admin-PostAward").map(val => val.value)

                const updStaff: any[] = []
                filteredStaffListOptions.forEach((staff) => {
                    if (filt1.includes(staff.value)) {
                        if (staff.roleName === "Admin" || staff.roleName === "Admin-PostAward") {
                            updStaff.push(staff)
                        }
                    } else {
                        updStaff.push(staff)
                    }
                })

                // console.log(" check filteredStaffList", filteredStaffList, "filteredStaffListOptions", filteredStaffListOptions, "filt1", filt1, "updStaff", updStaff);




                // console.log("handleOk filteredChildCompany", filteredChildCompany, "filteredStaffList", filteredStaffList, "filteredStaffListOptions", filteredStaffListOptions);
                if (isTableEdit) {
                    // console.log("handleOk isTableEdit ", isTableEdit, "utcFilingDate", utcFilingDate, "selectedFilingDueDate", selectedFilingDueDate, "filteredChildCompany", filteredChildCompany, "updStaff", updStaff);

                    payload = filteredChildCompany.map((item) => ({
                        ...item,
                        updateDate: new Date(Date.now()).toISOString(),
                        updateUser: authUser?.userId + "",
                        status: selectedStatus ? selectedStatus : item.status,
                        grantTermComplete: selectedGrantTermCom === "Yes" ? true : false,
                        filingDueDate: utcFilingDate ? utcFilingDate : item.filingDueDate,
                        staffAssigned: updStaff
                            .filter(staff => staff.customerId === null || staff.customerId == item.customerId)
                            .map(staff => ({
                                staffAssignedId: item.staffAssigned?.find(s => s.userId == staff.value)?.staffAssignedId || 0,
                                userName: staff.name,
                                userId: staff.value,
                                loginId: staff.loginId,
                                isDeleted: staff.isDeleted,
                                filingId: item.filingId,
                                fileTrackingID: item.fileTrackingId,
                                customerId: item.customerId,
                                company: item.isParentCompany ? "Parent" : "Child"
                            }))
                    }));
                } else {
                    // console.log("handleOk isTableEdit ", isTableEdit, "utcFilingDate", utcFilingDate, "selectedFilingDueDate", selectedFilingDueDate);

                    payload = filteredChildCompany.map((item) => ({
                        ...item,
                        updateDate: new Date(Date.now()).toISOString(),
                        updateUser: authUser?.userId + "",
                        status: selectedStatus ? selectedStatus : item.status,
                        filingDueDate: utcFilingDate !== null ? utcFilingDate : item.filingDueDate,
                        staffAssigned: [
                            ...(item.staffAssigned || []),
                            ...updStaff
                                .filter(staff => staff.customerId === null || staff.customerId == item.customerId)
                                .filter(staff => !item.staffAssigned?.some(s => s.userId == staff.value))
                                .map(staff => ({
                                    staffAssignedId: 0,
                                    userName: staff.name,
                                    userId: staff.value,
                                    loginId: staff.loginId,
                                    filingId: item.filingId,
                                    fileTrackingID: item.fileTrackingId,
                                    customerId: item.customerId,
                                    company: item.isParentCompany ? "Parent" : "Child"
                                }))
                        ]
                    }));

                }
            }
            if (payload) {
                // console.log("handleOk payload", payload, "JSON - ", JSON.stringify(payload));
                const res = await axios.put(
                    `${API_BASE_URL}/Customer/CustomerFileTrackingUpdate`,
                    payload
                );
                console.log("handleOk payload res", res);
                if (res.data.status === "Success") {
                    console.log("handleOk res Success ", res.data.status);

                    setAlertMessage("Successfully Saved")
                    setShowSuccess(true)
                    setShowError(false)
                    setShowInfo(false)
                    props.handleRefreshTable();
                    props.refreshConsList();

                } else {
                    console.log("handleOk res Failure ", res.data.status);
                    setAlertMessage(" Error while saving")
                    setShowError(true)
                    setShowSuccess(false)
                    setShowInfo(false)
                    props.handleRefreshTable();
                    props.refreshConsList();


                }

            }

        }
        catch (error) {
            console.log("handleOk error", error);

        } finally {
            setIsTableEdit(false)
            setSelectedGrantTermCom("")
            setSelectedStaffAssigned([])
            setSelectedStatus("")
            setCheckedItems([])
            setSelectedFilingDueDate(null)
            setSelectedAffectedCompanies([]);
        }

    };
    const handleDefer = async (anchor: Anchor) => {
        setState({ ...state, [props.dockAt ? props.dockAt : "right"]: false });
        setIsFilingDelete(true)
        const currentYear = await axios.get(
            `${API_BASE_URL}/Customer/GetConfigValue?ConfigItem=CurrentYear`
        );
        const filingPeriod = data.filter(item => item.fileTrackingId === checkedItems[0]).map(ele => ele.enddate)
        const checkPrevYear = filingPeriod
            ? new Date(filingPeriod[0]).getFullYear()
            : 0;
        const isPreviousYear = checkPrevYear === currentYear.data - 1
        if (isPreviousYear) {
            showDialog(
                "Confirmation",
                "If you defer a previous year's due date filing, it will be deleted from the tracker list and not moved to the deferred list." +
                "Are you certain you want to proceed?",
                "confirm"
            );
        } else {
            showDialog(
                "Confirmation",
                " Are you sure you want to move these filings to deferred list ?",
                "confirm"
            );
        }
    }
    const handleDeferSubmit = async () => {
        setIsLoadingList(true)
        setState({
            ...state,
            [props.dockAt ? props.dockAt : "right"]: true,
        });

        try {
            //  const filteredData = data.filter(obj => checkedItems.includes(obj.fileTrackingId));
            const deferPayload = data.filter(obj => obj.fileTrackingId && checkedItems.includes(obj.fileTrackingId)).map(obj => ({
                fileTrackingId: obj.fileTrackingId,
                UserId: authUser?.userId + "",
                customerId: obj.customerId,
                filingId: obj.filingId,
                year: new Date(obj.startdate).getFullYear() + "",

            }));
            // console.log(" handleDeferSubmit deferPayload", deferPayload, "data", data);
            const res = await axios.post(
                `${API_BASE_URL}/Customer/ClientSpecificFilingDelete`,
                deferPayload

            );
            console.log("handleDeferSubmit res", res.data.status);
            if (res.data.status === "Success") {
                setAlertMessage("Successfully deferred")
                setShowSuccess(true)
            }
            else {
                setAlertMessage("Error while deferring")
                setShowError(true)
            }
        }
        catch (err) {
            console.error("handleDeferSubmit", "err", err)
        }
        finally {
            setIsTableEdit(false)
            setIsFilingDelete(false)
            setSelectedGrantTermCom("")
            setSelectedStaffAssigned([])
            setSelectedStatus("")
            setCheckedItems([])
            setSelectedFilingDueDate(null)
            setSelectedAffectedCompanies([]);
            props.handleRefreshTable();
            props.refreshConsList();
        }

    }

    const fetchSameFilings = async (cust: number, filingId: number) => {
        console.log("fetchSameFilings", "cust", cust, "filingId", filingId);

        const res = await axios.get(
            `${API_BASE_URL}/Customer/TrackerFilingsBasedOnFrequency/${cust}/${filingId}`,

        );
        // console.log("fetchSameFilings", "res", res);

        setSameFilingList(res.data.data)

    }

    const handleCancel = async (anchor: Anchor) => {
        setIsTableEdit(false)
        setState({ ...state, [anchor]: false });
        setShowSuccess(false);
        setShowInfo(false)
        setChildCompanyList([])
        setStaffListOptions([])
        setSelectedAffectedCompanies([])
        setSelectedStaffAssigned([])
        setSelectedGrantTermCom("")
        setSelectedStatus("")
        setCheckedItems([])
        setSelectedFilingDueDate(null)
        setAffectedCompaniesError(false)
        setShowError(false);
        setAlertMessage("")
        setConsolidatedStatusRec({})

        props.handleClose();
    };
    const handleClearForm = async (anchor: Anchor) => {
        try {
            setIsTableEdit(false)
            setSelectedStaffAssigned([])
            setSelectedStatus("")
            setCheckedItems([])
            setSelectedFilingDueDate(null)
            setSelectedAffectedCompanies([]);
            setSelectedGrantTermCom("")
            setIsDisableGrantTerm(true)
            setAffectedCompaniesError(false)
            setShowError(false);
            setShowSuccess(false)
            setShowInfo(false)
            setAlertMessage("")
        } catch (error) {
            console.log("handleClearForm error", error);

        }
    };
    const handleEdit = async (item: any) => {
        // console.log(" handleEdit itemCheck", item, "status", hasChangeStatusPermission, "Staff Assigned", hasStaffAssignedPermission);
        fetchSameFilings(item.customerId, item.filingId)
        const custId = [item.customerId];
        const staffId = item.staffAssigned.map((staff: { userId: any; }) => staff.userId.toString())
        const status = item.status
        const initGrantTermComplete = item.grantTermComplete ? "Yes" : "No";
        setSelectedStaffAssigned(staffId)
        setSelectedStatus(status)
        setSelectedAffectedCompanies(custId)
        setSelectedFilingDueDate(item.filingDueDate)
        setSelectedGrantTermCom(initGrantTermComplete)
        setIsTableEdit(true);
        setShowError(false);
        setShowSuccess(false);
        setAlertMessage("");
        // props.handleClose();

    };
    // console.log("selectedStaffAssigned", selectedStaffAssigned);

    return (
        <div>
            {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
    // <React.Fragment key={anchor}>
    <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
            {typeof props.dockAt !== "undefined" && (
                <React.Fragment>
                    <Drawer
                        anchor={props.dockAt}
                        open={state[props.dockAt]}
                        onClose={toggleDrawer(props.dockAt, false)}
                    >
                        {list(props.dockAt)}
                    </Drawer>
                    <AlertDialog />
                </React.Fragment>
            )}
        </div>
    );
}
